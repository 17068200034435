import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MannedForecastModel, CityListModel, DeleteReportModel, GenerateReportPdfModel, ListReportModel, UpdateMannedForecastModel, GenerateAutomaticWeatherReportPdf, GetAutomaticWeatherReportsListingModel } from 'src/app/models/manned-forecast/manned-forecast.model';
import { environmentBaseUrl } from 'src/environments/environmentBaseUrl';

@Injectable({
  providedIn: 'root'
})
export class MannedForecast {

  previewdata:any;
  locationslist:number[];
  start_date:string;
  end_date:string;
  report_title: string;
  report_title_ar: string;
  reportDisclaimerEn: string;
  reportDisclaimerAr: string;
  additionalNoteEn: string;
  additionalNoteAr: string;
  report_type_id: number;
  report_id: number;
  is_governate: boolean;
  show_public_description: boolean;
  private baseUrl = `${environmentBaseUrl.url}/api/ncm/report/`;
  constructor(private http: HttpClient) { }

  getCityList(cityListModel: CityListModel){
    const url = `${this.baseUrl}get-city-list`;
    return this.http.post(url, cityListModel).toPromise();
  }

  generatePDFReport(generateReportPdfModel: GenerateReportPdfModel){
    const url = `${this.baseUrl}generate-report-pdf`;
    return this.http.post(url, generateReportPdfModel).toPromise();
  }
  generateAutomaticPDFReport(generateAutomaticWeatherReportPdf: GenerateAutomaticWeatherReportPdf){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders()
    .set('Authorization', `Bearer ${token}`);
    const url = `${this.baseUrl}generate-automatic-report-pdf`;
    return this.http.post(url, generateAutomaticWeatherReportPdf, {headers}).toPromise();
  }

  getListReport(listReportModel: ListReportModel){
    const url = `${this.baseUrl}list-report`;
    return this.http.post(url, listReportModel).toPromise();
  }
  getTodayReport(listReportModel: ListReportModel){
    const url = `${this.baseUrl}get-today-weather-report`;
    return this.http.post(url, listReportModel).toPromise();
  }
  getAutomaticListReport(getAutomaticWeatherReportsListingModel: GetAutomaticWeatherReportsListingModel){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders()
    .set('Authorization', `Bearer ${token}`);
    const url = `${this.baseUrl}get-automatic-reports`;
    return this.http.post(url, getAutomaticWeatherReportsListingModel, {headers}).toPromise();
  }

  createMannedForecastReport(mannedForecastModel: MannedForecastModel){
    const url = `${this.baseUrl}generate-report`;
    return this.http.post(url,mannedForecastModel).toPromise();
  }
  publishMannedForecastReport(updateMannedForecastModel: UpdateMannedForecastModel){
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
      .set('Accept', 'image/png');
    const options = { headers: headers};
    const url = `${this.baseUrl}update-report`;
    return this.http.post(url,updateMannedForecastModel, options).toPromise();
  }

  getWeatherReportSymbols(){
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    const options = { headers: headers};
    const url = `${environmentBaseUrl.url}/api/ncm/weather/get-report-weather-symbols`;
    return this.http.get(url, options).toPromise();
  }
  deleteWeatherReport(deleteReportModel: DeleteReportModel){
    const url = `${this.baseUrl}archive-weather-report`;
    return this.http.post(url, deleteReportModel).toPromise();
  }


}
