import { LanguageService } from './../services/language/language.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environmentBaseUrl } from 'src/environments/environmentBaseUrl';
import { AuthenticationService } from '../auth/service/authentication.service';
import { GlobalService } from '../shared/utilities/global';

@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.css']
})
export class FullComponent implements OnInit {
  full: any;
  language: string;

  constructor(
    private languageService: LanguageService,
    private translate: TranslateService,
    private authService: AuthenticationService,
    private globalService: GlobalService
  ) {
    this.languageService.currentLanguage.subscribe((language: string) => {
      this.language = language;
      this.translate.use(language);
      this.translate.get("Dashboard").subscribe((res) => {
        this.full = res;
      });
    });
  }

  ngOnInit() {
    this.setMapToken();
  }

  setMapToken() {
    const mapToken = localStorage.getItem('mapToken');
    if (mapToken) {
      environmentBaseUrl.esriKey = mapToken;
      environmentBaseUrl.environmentBaseUrl.esriKey = mapToken;
    }
    else {
      this.authService.getMomraMapToken()
      .then(async (res: { token: string }) => {
        localStorage.setItem('mapToken', res.token);
        environmentBaseUrl.esriKey = res.token;
        environmentBaseUrl.environmentBaseUrl.esriKey = res.token;
      })
      .catch((error) => {
        this.globalService.handleError(error);
      })
    }
  }
}
