import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EWSNotificationModel } from 'src/app/models/EWSnotification/EWSnotification.model';
import { EWSNotificationListModel } from 'src/app/models/alert-history-filter/alert-history.filter.model';
import { SearchEwsNotifcationFilterModel } from 'src/app/models/ews-analytics/ews-analytics.model';
import { EwsNotifcationModel } from 'src/app/models/notification/notification.model';
import { environmentBaseUrl } from 'src/environments/environmentBaseUrl';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private baseUrl = `${environmentBaseUrl.url}/api/ncm/`;
  constructor(private http: HttpClient) { }

  getRegions(){
    const url = `${this.baseUrl}notification/regions`;
    return this.http.post(url, {}).toPromise();
  }
  getAllGovernates(){
    const url = `${this.baseUrl}notification/governorates`;
    return this.http.post(url, {}).toPromise();
  }

  getGovernatesByRegionId(region_id?:number){
    const url = `${this.baseUrl}ews/get-governates-by-region`;
    return this.http.post(url, {region_id}).toPromise();
  }

  getGovernatesByRegionIds(region_ids :number[]){
    const url = `${this.baseUrl}ews/get-governates-by-region`;
    return this.http.post(url, {region_ids}).toPromise();
  }

  getOtherMunicipalitiesByRegionId(region_id:string){
    const url = `${this.baseUrl}ews/get-other-governates-by-region`;
    return this.http.post(url, {region_id}).toPromise();
  }

  getMunicipalityByGovernatesId(governorate_id:number[]){
    const url = `${this.baseUrl}ews/municipality`;
    return this.http.post(url, {governorate_id}).toPromise();
  }

  getAlertActions(){
    const url = `${this.baseUrl}ews/alert-action`;
    return this.http.post(url, {}).toPromise();
  }
  getAlertTypes(){
    const url = `${this.baseUrl}ews/alert-type`;
    return this.http.post(url, {}).toPromise();
  }

  getAlertStatusByAlertType(alert_id:number[]){
    const url = `${this.baseUrl}ews/get-phenomena-list-by-alerts`;
    return this.http.post(url, {alert_id}).toPromise();
  }

  getAlertHazards(){
    const url = `${this.baseUrl}ews/alert-hazard`;
    return this.http.post(url, {}).toPromise();
  }

  getAlertHazardsByAlertStatus(status_id: number){
    const url = `${this.baseUrl}ews/get-hazard-list-by-alert-status`;
    return this.http.post(url, {status_id}).toPromise();
  }

  createEwsNotification(ewsNotificationModel:EWSNotificationModel){
    const url = `${this.baseUrl}ews/create-ewsnotification`;
    return this.http.post(url,ewsNotificationModel).toPromise();
  }
  updateEwsNotification(ewsNotificationModel:EWSNotificationModel){
    const url = `${this.baseUrl}ews/update-ewsnotification`;
    return this.http.post(url,ewsNotificationModel).toPromise();
  }

  getEwsNotifications(ewsNotificationListModel: EWSNotificationListModel){
    const url = `${this.baseUrl}ews/list-ewsnotification`;
    return this.http.post(url, ewsNotificationListModel).toPromise();
  }
  getSearchEwsNotifications(searchEwsNotifcationFilterModel:SearchEwsNotifcationFilterModel){
    const url = `${this.baseUrl}ews/search-ews-notification`;
    return this.http.post(url, searchEwsNotifcationFilterModel).toPromise();
  }
  getSearchEwsNotificationsTEST(ewsNotifcationModel:EwsNotifcationModel){
    const url = `${this.baseUrl}ews/search-ews-notification`;
    return this.http.post(url, ewsNotifcationModel).toPromise();
  }

  archiveEwsNotifcation(archiveModel:{id:string,status:boolean}){
    const url = `${this.baseUrl}ews/archive-ewsnotification`;
    return this.http.post(url, archiveModel).toPromise();
  }

  publishEwsNotification(publish:{id:number; published:boolean}){
    const url = `${this.baseUrl}ews/publish-ewsnotification`;
    return this.http.post(url, publish).toPromise();
  }

  getDetailEwsNotifications(id:number){
    const url = `${this.baseUrl}ews/detail-ewsnotification`;
    return this.http.post(url, {id}).toPromise();
  }

  getWeeklyReports(){
   const url = `${this.baseUrl}ews/report-ewsnotification`;
   return this.http.post(url, {report_type: 'published_in_week'}).toPromise();
  }

  getRegionReport(regionId:number){
    const url = `${this.baseUrl}ews/report-ewsnotification`;
    return this.http.post(url, {region_id: regionId ,report_type: 'published_in_region'}).toPromise();
  }

  getEwsNotificationEmailsById(Id:number, page:number = 1, pageSize:number = 10){
    const url = `${this.baseUrl}ews/ews-notification-emails`;
    return this.http.post(url, {id: Id, page, pageSize}).toPromise();
  }

  getEwsAnalytics(){
    const url = `${this.baseUrl}ews/ews-analytics`
    return this.http.post(url, {isManned: false}).toPromise();
  }

  getEsriKey(){
    const url = `${this.baseUrl}admin/momra-generate-token`;
    return this.http.post(url, {}).toPromise()
    .then((res:{token:string})=>{
      environmentBaseUrl.esriKey = res.token;
      return true;
    })
  }

  generateHistoryReport(alert_id:number){
    const url = `${this.baseUrl}report/generate-history-report-pdf`;
    return this.http.post(url, {id: alert_id}).toPromise();
  }
}
