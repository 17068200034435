import { NgxSpinnerService } from 'ngx-spinner';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert-service/alert-service.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { MannedForecast } from 'src/app/services/manned-forecast/manned-forecast.service';
import * as echarts from 'echarts';
import { GenerateReportDataModel, GenerateReportModelAPI, PublishReportModelAPI } from 'src/app/models/automatic-weather-report/automatic-weather-report.model';
import { AutomaticWeatherReport } from 'src/app/services/automatic-weather-report/automatic-weather-report.service';
import { GlobalService } from 'src/app/shared/utilities/global';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PreviewModalComponent } from '../preview-modal/preview-modal.component';
import { UsersService } from 'src/app/services/users/users.service';
import { NCMClientModelAPI } from 'src/app/models/users/users.model';
import { PaginationVariables } from 'src/app/models/pagination/pagination.model';
import * as moment from 'moment';
import 'moment-timezone';
import { ParametersMap, ArabianParametersMap, RedSeaParametersMap, CustomReportParametersMap, MashaerReportParametersMap } from 'src/app/models/manned-forecast/manned-forecast.model';

export class listOrganizationResponseModel {
  success: boolean;
  data: listOrganization[];
  paginationVariables: PaginationVariables;
  message: string;
  constructor(){
    this.data = [];
  }
}
export class listOrganization {
  id: number
  code: string
  organizationType: string
  nameEn: string
  nameAr: string
  email: string
  city: any
  state: any
  zip: any
  country: any
}
export interface PreviewData {
  city: string;
  cityEn: string;
  cityAr: string;
  lat: number;
  lon: number;
  parameters: Parameter[] | any;
}

export interface Parameter {
  parameter: string
  dates: Date[]
}

export interface Date {
  date: string
  value: number;
  nameEn?:string;
  nameAr:string;
}

@Component({
  selector: 'app-automatic-preview',
  templateUrl: './automaticpreview.component.html',
  styleUrls: ['./automaticpreview.component.scss']
})
export class AutomaticPreviewComponent {
  private subscriptions: Subscription = new Subscription()
  language:string;
  automaticpreviewPage: any;

  allPreviewData: any;

  lineChart: echarts.ECharts;
  chart: echarts.ECharts;

  parameters: string[]
  parameters12h: string[]
  parameters24h: string[]
  locations: number[]

  today: string
  tomorrow: string
  ReportData: any = [];
  ReportData12h: GenerateReportDataModel[];
  Report_Title: string;
  Report_Title_Ar: string;
  Surface_Wind: string;
  Wave_Height: string;
  State_of_the_Sea: string;
  Surface_Wind_ar: string;
  Wave_Height_ar: string;
  State_of_the_Sea_ar: string;
  publishOnPdf: boolean;
  Dataparams: any[] = [];
  ReportData24hrs: any[] = [];
  ReportData12hrstemp: any[] = [];
  ReportData12hrs: any[] = [];
  ENDReport: any[] = [];
  ENData: any | any[]=[];
  updatePublishReport:any[]=[];
  reporttypeid: number;
  reporttypekey: string;
  descriptionEn: string;
  descriptionAr: string;
  titleEn: string;
  titleAr: string;
  reportId: number;

  currentPageForOrganizationUsers: number = 1;
  organizationUsers: listOrganizationResponseModel =  new listOrganizationResponseModel();

  organizationarray: number[] = [];
  organizationdropdown:boolean = false;
  organizationcheck: boolean = false;
  emailGroup: any;
  ArabianParametersMap = ArabianParametersMap;
  RedSeaParametersMap = RedSeaParametersMap;
  CustomReportParametersMap = CustomReportParametersMap;
  MashaerReportParametersMap = MashaerReportParametersMap;

  reportTypeMapping = {
    'custom-weather-report': CustomReportParametersMap,
    'mashaer-weather-report': MashaerReportParametersMap,
    'arabian-gulf-report': ArabianParametersMap,
    'red-sea-report': RedSeaParametersMap
  }

  constructor(
    private router: Router,
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private datePipe: DatePipe,
    private http: HttpClient,
    private languageService: LanguageService,
    private translate: TranslateService,
    private activated: ActivatedRoute,
    private automaticWeatherReportservice: AutomaticWeatherReport,
    private globalService: GlobalService,
    private modalService: NgbModal,
    private userService: UsersService,
  ) {
    this.getParams()
     this.subscriptions.add(this.languageService.currentLanguage.subscribe((language: string) => {
      this.language = language;
      this.translate.use(language);
      this.translate.get("AutomaticWeatherReport").subscribe((res) => {
        this.automaticpreviewPage = res;
      });
    }));
  }

  ngOnInit(): void {

    window.addEventListener('resize', ()=>{
      // this.initializeChart();
    })
    this.getOrganizationUsersForDropdown();
  }

  ngAfterViewInit(){
      // this.initializeChart();
  }
  oncheckchange(event:any){
    console.log('event', this.organizationarray)
  }
  getParams(){
    this.subscriptions.add(
      this.activated.queryParams.subscribe((params)=>{
        params = JSON.parse(JSON.stringify(params))
        if(params.parameters && params.locations){
          this.parameters = params.parameters.split(',')
          this.locations = params.locations.split(',')
          this.reporttypeid = params.reporttype
          this.reporttypekey = params.reportKey
          this.descriptionEn = params.descriptionEn
          this.descriptionAr = params.descriptionAr
          this.titleEn = params.titleEn
          this.titleAr = params.titleAr
          if(this.automaticWeatherReportservice.allChannelData?.addEmailGroup){
            this.emailGroup = this.automaticWeatherReportservice.emailGroups
          }
          this.parameters24h = this.parameters.filter((param)=> param.includes('24h') || param.includes('sql'))
          this.parameters12h = this.parameters.filter((param)=> !param.includes('24h') && !param.includes('sql'))
          this.starting()
        }
        else if(params.reportdata){
          this.reporttypeid = params.reporttype;
          this.reporttypekey = params.reportKey;
          this.descriptionEn = params.descriptionEn;
          this.descriptionAr = params.descriptionAr;
          this.titleEn = params.titleEn;
          this.titleAr = params.titleAr;
          let maindata = JSON.parse(params.reportdata);
          let previewData = maindata;
          previewData.forEach((city:PreviewData)=>{
            let parameterObject = {};
            city.parameters.forEach((parameter:Parameter)=>{
              const parameterName = parameter.parameter;
              parameterObject[parameterName] = parameter.dates;
            })
            city.parameters = parameterObject;
          })
          this.ENData = previewData;
          this.reportId = params.reportId;
          this.Report_Title = params.reportTypeEn;
          this.Report_Title_Ar = params.reportTypeAr;
          this.Surface_Wind = params.surfaceWind;
          this.Wave_Height = params.waveHeight;
          this.State_of_the_Sea = params.stateOfTheSea;
          this.Surface_Wind_ar = params.surfaceWindAr;
          this.Wave_Height_ar = params.waveHeightAr;
          this.State_of_the_Sea_ar = params.stateOfTheSeaAr;
        }
        else{
          this.router.navigateByUrl('/home/automatic-weather-report')
        }
      })
    )
  }
  getOrganizationUsersForDropdown(clienttype?:any, page?:any){
    const ncmClientModelAPI: NCMClientModelAPI = new NCMClientModelAPI();
    if(page){
      ncmClientModelAPI.page = page;
    }
    else{
      if(this.organizationUsers?.paginationVariables?.pageCount > (this.currentPageForOrganizationUsers + 1 )){
        this.currentPageForOrganizationUsers++;
        ncmClientModelAPI.page = this.currentPageForOrganizationUsers;
      }
      else if(this.currentPageForOrganizationUsers == 1){
        ncmClientModelAPI.page = this.currentPageForOrganizationUsers;
      }
      else{
        return;
      }
    }
    // this.spinner.show();
    this.userService.listOrganization(ncmClientModelAPI).then(
      (res:listOrganizationResponseModel) => {
        if(res.success){
          if(this.organizationUsers.data.length > 0){
            this.organizationUsers['data'] = [ ...this.organizationUsers['data'], ...res.data];
          }
          else{
            this.organizationUsers['data'] = res.data;
          }
          this.organizationUsers.paginationVariables = res.paginationVariables;
        }
        else{
          this.toast.error(res.message);
        }
      }
    ).catch((err) => {
      this.globalService.handleError(err);
    })
    .finally(()=>{
      // this.spinner.hide();
    })
  }

  starting(){
    this.today = localStorage.getItem("current_date")
    this.tomorrow = localStorage.getItem("next_date")
    this.Report_Title = localStorage.getItem("Report_Title")
    this.Report_Title_Ar = localStorage.getItem("Report_Title_Ar")
    this.Surface_Wind = localStorage.getItem("Surface_Wind")
    this.Wave_Height = localStorage.getItem("Wave_Height")
    this.State_of_the_Sea = localStorage.getItem("State_of_the_Sea")
    this.Surface_Wind_ar = localStorage.getItem("Surface_Wind_ar")
    this.Wave_Height_ar = localStorage.getItem("Wave_Height_ar")
    this.State_of_the_Sea_ar = localStorage.getItem("State_of_the_Sea_ar")
    this.publishOnPdf = localStorage.getItem("publishOnPdf") == 'true' ? true : false;

    this.generateReport()
  }

  generateReport(){
    const now = moment();
    const saudiTime = moment.tz(now, 'Asia/Riyadh').startOf('day').set('hour', 0);
    const todayDate = saudiTime.utc().format();
    const fromDate = saudiTime.utc().format();
    const nextDayDate = moment(fromDate).add(1, 'days').utc().format();
    const todaysDate = this.datePipe.transform(todayDate, "yyyy-MM-ddTHH:mm:ss\'Z\'", 'UTC');
    const nextDate = this.datePipe.transform(nextDayDate, "yyyy-MM-ddTHH:mm:ss\'Z\'", 'UTC');
    this.spinner.show();
    const generateReportModelAPI: GenerateReportModelAPI = new GenerateReportModelAPI()
    generateReportModelAPI.parameters_24h = this.parameters24h;
    generateReportModelAPI.parameters_12h = this.parameters12h;
    generateReportModelAPI.locations = this.locations;
    if(this.reporttypekey == 'custom-weather-report'){
      generateReportModelAPI.is_governate = true;
    }
    else{
      generateReportModelAPI.is_governate = false;
    }
    generateReportModelAPI.from_date = todaysDate;
    generateReportModelAPI.to_date = nextDate;
    generateReportModelAPI.model = "mix";
    this.automaticWeatherReportservice.generateReport(generateReportModelAPI)
    .then((res:any)=>{
      this.spinner.hide();
      let maindata = res.data;
      if(this.reporttypekey == 'custom-weather-report'){
        let [previewData] = maindata;
        previewData.forEach((city:PreviewData)=>{
          let parameterObject = {};
          city.parameters.forEach((parameter:Parameter)=>{
            const parameterName = parameter.parameter;
            parameterObject[parameterName] = parameter.dates;
          })
          city.parameters = parameterObject;
        })
        this.ENData = previewData;
        console.log('previewData', this.ENData);
      }
      else if(this.reporttypekey == 'mashaer-weather-report'){
        let [previewData] = maindata;
        previewData.forEach((city:PreviewData)=>{
          let parameterObject = {};
          city.parameters.forEach((parameter:Parameter)=>{
            const parameterName = parameter.parameter;
            parameterObject[parameterName] = parameter.dates;
          })
          city.parameters = parameterObject;
        })
        this.ENData = previewData;
        console.log('previewData', this.ENData);
      }
      else{
        let [previewData] = maindata;
        previewData.forEach((city:PreviewData)=>{
          let parameterObject = {};
          city.parameters.forEach((parameter:Parameter)=>{
            const parameterName = parameter.parameter;
            parameterObject[parameterName] = parameter.dates;
          })
          city.parameters = parameterObject;
        })
        this.ENData = previewData;
        console.log('previewData', this.ENData);
      }
    })
    .catch((error: HttpErrorResponse)=>{
      this.globalService.handleError(error)
    })
  }

  formatTimeInto12HourFormat(dateStr:string){
    const [strHour, minutesAndPeriod] = dateStr.split(":");
    let hour = parseInt(strHour);
      if(hour > 12){
        hour = hour - 12;
      }
      if(hour  == 0){
        hour = 12;
      }
      return `${hour}:${minutesAndPeriod}`
  }
  formatTime(timeStr:string){
    const [time] = timeStr.split(" ");
    const seconds = `00`;
    return `${time}:${seconds}`;
  }
  getFullDateAndTimeString(date:string,time:string){
    return `${date} ${time}`;
  }

  convertTimeTo24HourFormat(timeString: string) {
    const [time, period] = timeString?.split(" ");
    const [hours, minutes] = time?.split(":");

    let convertedHours = parseInt(hours, 10);

    if (period.toLowerCase() === 'pm' && convertedHours !== 12) {
      convertedHours += 12;
    } else if (period.toLowerCase() === 'am' && convertedHours === 12) {
      convertedHours = 0;
    }

    return `${String(convertedHours).padStart(2, '0')}:${minutes}:00`;
  }
  validate() {

    const areatextarea = document.querySelector("#summary") as HTMLTextAreaElement | null;
    const areatext = areatextarea?.value.trim().split(/\s+/).length || 0; // Counting words instead of characters
    const textcount = document.querySelector("#textcount") as HTMLElement | null;
    const wordcount = document.querySelector("#words_count") as HTMLElement | null;

    if (textcount) {
        textcount.innerHTML = areatext.toString();
    }

    if (areatext > 200 && textcount) {
        textcount.classList.add("text-danger");
    } else if (textcount) {
        textcount.classList.remove("text-danger");
    }

    if (areatextarea) {
        if (areatext > 200) {
            areatextarea.classList.add("textarea_danger");
        } else {
            areatextarea.classList.remove("textarea_danger");
        }
    }
}
  publishReport() {
    const now = moment();
    const saudiTime = moment.tz(now, 'Asia/Riyadh').startOf('day').set('hour', 0);
    const todayDate = saudiTime.utc().format();
    const fromDate = saudiTime.utc().format();
    const nextDayDate = moment(fromDate).add(1, 'days').utc().format();
    const todaysDate = this.datePipe.transform(todayDate, "yyyy-MM-dd", 'UTC');
    const nextDate = this.datePipe.transform(nextDayDate, "yyyy-MM-dd", 'UTC');
    this.spinner.show();
    let organizationsArray: number[] = [];
    let emails: string[] = [];
    const mychanneldata = this.automaticWeatherReportservice.allChannelData;
    const channels = [];
    if(this.reporttypekey == 'custom-weather-report' || this.reporttypekey == 'mashaer-weather-report'){
      if(mychanneldata != undefined){
        if(mychanneldata.addEmails){
          channels.push("email");
        }
        if(mychanneldata.ajwaaPortal){
          channels.push("ajwaa_portal");
        }
        if(mychanneldata.publicPortal){
          channels.push("public_portal");
        }
        if(mychanneldata.twitter){
          channels.push("twitter");
        }
        if(mychanneldata.selectOrganizations){
          mychanneldata.selectOrganizationsArray.forEach(element => {
            organizationsArray.push(element);
          })
        }
        else{
          organizationsArray = [0];
        }
        mychanneldata.count.forEach(element => {
          emails.push(element.email)
        });
      }
    }
    const publishReportModelAPI: PublishReportModelAPI = new PublishReportModelAPI();
    const publishedData = JSON.parse(JSON.stringify(this.ENData));
    publishedData.forEach((data)=>{
      const parameters = [];
      Object.entries(data.parameters).forEach(([key,value])=>{
        parameters.push({parameter:key,dates:value})
      });
      data.parameters = parameters;
    });
    let publishdata = publishedData;
    publishReportModelAPI.data = publishdata;
    publishReportModelAPI.report_title = this.Report_Title;
    publishReportModelAPI.report_title_ar = this.Report_Title_Ar;
    publishReportModelAPI.surface_wind = this.Surface_Wind;
    publishReportModelAPI.wave_height = this.Wave_Height;
    publishReportModelAPI.state_of_the_sea = this.State_of_the_Sea;
    publishReportModelAPI.surface_wind_ar = this.Surface_Wind_ar;
    publishReportModelAPI.wave_height_ar = this.Wave_Height_ar;
    publishReportModelAPI.state_of_the_sea_ar = this.State_of_the_Sea_ar;
    publishReportModelAPI.publishOnPdf = this.publishOnPdf;
    publishReportModelAPI.reportDisclaimerEn = this.automaticWeatherReportservice.reportDisclaimerEn;
    publishReportModelAPI.reportDisclaimerAr = this.automaticWeatherReportservice.reportDisclaimerAr;
    publishReportModelAPI.additionalNoteEn = this.automaticWeatherReportservice.additionalNoteEn;
    publishReportModelAPI.additionalNoteAr = this.automaticWeatherReportservice.additionalNoteAr;
    publishReportModelAPI.report_type_id = this.reporttypeid;
    publishReportModelAPI.lang = localStorage.getItem('language');
    publishReportModelAPI.locations = this.locations;
    publishReportModelAPI.start_date = todaysDate;
    publishReportModelAPI.end_date = nextDate;
    if(this.reportId){
      publishReportModelAPI.report_id = this.reportId;
    }
    if(this.reporttypekey == 'custom-weather-report'){
      publishReportModelAPI.is_governate = true;
    }
    else{
      publishReportModelAPI.is_governate = false;
    }
    if(this.reporttypekey == 'custom-weather-report' || this.reporttypekey == 'mashaer-weather-report'){
      if(mychanneldata != undefined){
        publishReportModelAPI.emails = emails;
        publishReportModelAPI.organizations = organizationsArray;
        if(this.automaticWeatherReportservice.allChannelData?.addEmailGroup){
          publishReportModelAPI.user_group_ids = this.emailGroup
        } else {
          publishReportModelAPI.channels = channels;
        }
        if(mychanneldata.sendEmail){
          publishReportModelAPI.send_email = 1;
        }
      }
    }
    this.automaticWeatherReportservice.publishReport(publishReportModelAPI)
    .then((res:{success:boolean, message:string})=>{
      if(res.success){
        this.spinner.hide();
        this.toast.success(res.message);
        this.router.navigateByUrl('/home/automatic-weather-report')
      }
      else{
        this.spinner.hide();
        this.toast.error('Report Preview Not Available. Please try later.');
      }
    })
    .catch((error)=>{
      this.globalService.handleError(error)
    })
  }

  openPreviewModal(endData:any | any[] = [],firstIndex:number){
    console.log('endData', endData);
    const modal = this.modalService.open(PreviewModalComponent,{
      //  backdrop:'static',
       centered:true,
       modalDialogClass:'modal-lg'
     });
     modal.componentInstance.previewData = JSON.parse(JSON.stringify(endData));
     modal.componentInstance.firstIndex = firstIndex;
     modal.componentInstance.reporttypekey = this.reporttypekey;
    //  modal.componentInstance.secondIndex = secondIndex;
     modal.result.then((result:any)=>{
       if(result){
         this.ENData[firstIndex] = result;
         console.log('result', this.ENData);
       }
     })
   }
   openOrganizationModal(content: TemplateRef<any>) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered:true }).result.then(
			(result) => {
				// this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				// this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}
   goToPreview() {
    if(this.reportId){
      this.router.navigateByUrl(`/home/automatic-weather-report`);
    }
    else{
      this.router.navigateByUrl(`home/automatic-weather-report/automatic-weather-report-details?reporttype=${this.reporttypeid}&reportKey=${this.reporttypekey}&descriptionEn=${this.descriptionEn}&descriptionAr=${this.descriptionAr}&titleEn=${this.titleEn}&titleAr=${this.titleAr}`);
    }
  }
  Numberify(value: string | number) {
    return Number(`${value}`);
  }
}
