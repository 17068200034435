export class EWSNotificationModel {
  isUpdate?: boolean;
  is_published?: boolean;
  user_group_ids?: number[]
  id: number;
  regionId: number | string;
  governateIds: number[] | string;
  alertActionId: number | string;
  alertStatusId: number | string;
  alertHazardId: number | string;
  alertTypeId: number | string;
  weatherPhenomenonId: number | string;
  weatherPhenomenonAffectId: number | string;
  filename: string;
  file: string;
  x_img: string;
  x_post: string;
  base64?:string
  coordinates: string[];
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  message: {
    en?: string,
    ar?: string,
    messageEn?: string,
    messageEnAr?: string,
  };
  enableEmailNotification: boolean;
  enableSMSNotification: boolean;
  enableTwitterNotification: boolean
  municipalityIds?: number[] | string;
  ewsOtherLocationIds?: number[] | string;
  address_components: {
    long_name: string,
    short_name: string,
    types: string[]
  }[];
  alertActions?: {
    id: number,
    descriptionAr: string,
    descriptionEn: string
  }[];
  alertHazard?: {
    id: number,
    nameAr: string,
    nameEn: string
  }[];
  regions?: {
    id: number,
    name_en: string,
    name_ar: string
  }[];
  governorates?: {
    id: number,
    nameAr: string,
    nameEn: string
  }[];
  municipalities?: {
    id: number,
    governate: number,
    nameAr: string,
    nameEn: string
  }[];
  ewsOtherLocations?: {
    id: number,
    latitude: string,
    longitude: string
    municipalities: [],
    nameAr: string,
    nameEn: string
  }[];
  alertType: number;
  alertTypeAr: string;
  alertTypeEn: string;
  alertStatusID:number;
  regionID:number;
  fromDate:string;
  toDate:string;
}

export class GovernateAndMuncipalModel {
  id?: number;
  name_en?: string;
  latitude?: string;
  longitude?: string;
  municipalities?: any[];
  nameAr?: string;
  nameEn?: string;
}
export class ListEwsNotificationModel {
  alertActions: { id: number, descriptionAr: string, descriptionEn: string }[];
  alertHazard?: { id: number, nameEn: string, nameAr: string }[];
  alertStatusAr: string;
  alertStatusCategory: string;
  alertStatusEn: string | string[];
  alertStatusID: number;
  alertType: number;
  alertTypeAr: string;
  alertTypeEn: string;
  coordinates: string;
  criteria: string;
  enableEmailNotification: boolean;
  enableSMSNotification: boolean;
  enableTwitterNotification: boolean;
  governateIds: string;
  municipalityIds: string;
  ewsOtherLocationIds?: number[] | string;
  regionId: string
  filename: string;
  file: string;
  fromDate: string;
  regions: GovernateAndMuncipalModel;
  governorates: GovernateAndMuncipalModel[];
  id: number;
  lastModified: string;
  message: {
    messageEn: string;
    messageAr: string;
  };
  municipalities: GovernateAndMuncipalModel[];
  ewsOtherLocations?: {
    id: number,
    latitude: string,
    longitude: string
    municipalities: [],
    nameAr: string,
    nameEn: string
  }[];
  otherLocationsAr: string;
  otherLocationsEn: string | null;
  regionAR: string;
  regionEn: string;
  regionID: number;
  title: number;
  toDate: string;
  tweetID: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
}
export class ListEwsNotificationPaginationModel {
  last: number
  current: number
  numItemsPerPage: number
  first: number
  pageCount: number
  totalCount: number
  pageParameterName: string
  sortFieldParameterName: string
  sortDirectionParameterName: string
  filterFieldParameterName: string
  filterValueParameterName: string
  distinct: boolean
  pageOutOfRange: string
  defaultLimit: number
  next: number
  pagesInRange: number[]
  firstPageInRange: number
  lastPageInRange: number
  currentItemCount: number
  firstItemNumber: number
  lastItemNumber: number
}

export class NEWListEwsNotificationModel {
  data: ListEwsNotificationDataModel[]
  paginationVariables: ListEwsNotificationPaginationVariables
}

export class ListEwsNotificationDataModel {
  id: number
  title: number
  alertType: number
  alertTypeAr: string
  alertTypeEn: string
  fromDate: string
  toDate: string
  alertStatusID: number
  alertStatusAr: string
  alertStatusEn: string
  alertStatusCategory: string
  alertHazard: AlertHazard[]
  regionID: number
  regionAR: string
  regionEn: string
  governorates: Governorate[]
  otherLocationsAr: string
  otherLocationsEn: any
  tweetID: string
  enableTwitterNotification: boolean
  enableSMSNotification: boolean
  enableEmailNotification: boolean
  alertActions: AlertAction[]
  municipalities: Municipality[]
  lastModified: string
  coordinates: string
  message: any
  file: string
  criteria: string
  created_at: string
  created_by: string
  status_en: string
  status_ar: string
  searchEwsIdEn: string
  searchEwsIdAr: string
}

export class AlertHazard {
  pim_id: number
  id: number
  nameEn: string
  nameAr: string
}

export class Governorate {
  id: number
  nameEn: string
  nameAr: string
  longitude: string
  latitude: string
  municipalities: any[]
}

export class AlertAction {
  pim_id: number
  id: number
  descriptionEn: string
  descriptionAr: string
}

export class Municipality {
  id: number
  nameEn: string
  nameAr: string
  governate: number
}

export class ListEwsNotificationPaginationVariables {
  last: number
  current: number
  numItemsPerPage: number
  first: number
  pageCount: number
  totalCount: number
  pageParameterName: string
  sortFieldParameterName: string
  sortDirectionParameterName: string
  filterFieldParameterName: string
  filterValueParameterName: string
  distinct: boolean
  pageOutOfRange: string
  defaultLimit: number
  next: number
  pagesInRange: number[]
  firstPageInRange: number
  lastPageInRange: number
  currentItemCount: number
  firstItemNumber: number
  lastItemNumber: number
}
