export class ClientModel {
  id?:number;
  email: string;
  phone?: string;
  role: string;
  entity_status?: string;
  logo?: string;
  name:string;
  client_type: string;
  company_name_en:string;
  company_name_ar?:string;
  location:number[];
  allowForecast?:boolean;
  allowAddLocation?:boolean;
  allowCustomNotification?:boolean;
  allowAlertHistoryForCustomAlerts?:boolean;
  allowOrganizationAdminToInviteUsers?:boolean;
  // limit: number;
  organization_id:number;
  org_id?:number;
  package_id?:number;
  api_id:number;
  group_id?:number;
  password?: string;
  confirmPassword?: string;
  constructor(){
    this.phone = '';
  }
}
export class UpdateAdminPackageModel {
  user_id: number;
  organization_id: number;
  package_id: number;
  Authorization: string;
}
export class CreateClientExcelModel {
  organization_id: number
  Authorization: string
  lang: string
  data: CreateClientExcelDataModel[]
}

export interface CreateClientExcelDataModel {
  name: string
  email: string
  role: string
}
