import { Component, ComponentRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GetListPackageModel, ListApiGroup, ListPackages, ListPackagesModel, getListWSOModel } from 'src/app/models/package/package.model';
import { PaginationVariables } from 'src/app/models/pagination/pagination.model';
import { LanguageService } from 'src/app/services/language/language.service';
import { PackageService } from 'src/app/services/package/package.service';
import { GlobalService } from 'src/app/shared/utilities/global';
import { ThrottlingData, UpsertPackagesComponent } from './upsert-packages/upsert-packages.component';
import { FormControl } from '@angular/forms';
import { DEBOUNCE_TIME_FOR_INPUT_IN_MS } from 'src/app/shared/constants';

export interface ListAPIData {
  id: number
  type: string
  name?: string
  name_ar?: string
  status: string
  price: string
  tenure: number
  throttling: string
}

function normalizeArabic(text: string): string {
  return text.replace(/[\u064b-\u0652\u0640\u0640\u0621-\u063A]/g, function (match) {
      return String.fromCharCode(match.charCodeAt(0) - 0x063A + 0x0060);
  });
}
@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements OnInit {
  @ViewChild('UpsertPackagesComponent', { static: false }) UpsertPackagesComponent: UpsertPackagesComponent;
  private emailInputChange = new Subject<string>();
  private searchInputChange = new Subject<string>();
  private subscriptions: Subscription = new Subscription();
  language: string;
  packagesPage: any;

  isExpanded: boolean[] = [];
  isExpanded2: boolean[] = [];

  isUpsertPackageWindowOpen:boolean = false;
  listPackages:ListPackagesModel;
  apiGroups:ListApiGroup[] = [];
  editPackage:ListPackagesModel;

  pageSize: number = 10;
  page: number = 1;
  pageAPI: number = 1;
  clientsPage: any;

  isDivVisible: boolean = false;
  deleteData: number;
  filterEmail: string;
  filterSearch: string;
  api_group: number;

  filterpackagename: string
  tableAPIGroup: ListApiGroup[];
  getListPackageModel: GetListPackageModel = new GetListPackageModel();
  getListAPIModel: getListWSOModel = new getListWSOModel();
  sortarray: string = '';
  sortarrayAPI: string = '';
  sortBytenure: boolean;
  sortByprice: boolean;
  sortByMaxUsers: boolean;
  sortByMaxLocations: boolean;

  sortBypriceAPI: boolean;
  sortBytenureAPI: boolean;
  sortByThrottlingAPI: boolean;
  sortByStatusAPI: boolean;

  navItems: string[] = ['Packages', 'APIs'];
  activeNav: string = this.navItems[0];

  isWSO: boolean;
  listWSOPackages: { success: boolean; data: ListAPIData[]; paginationVariables: PaginationVariables; };
  activeSelection: string;
  editAPI: ListApiGroup;
  throttlingData: ThrottlingData[];
  upsertdisplay: boolean = false;

  isLoadingListAPI = false;
  isLoadinglistApiGroup = false;

  searchInputControl = new FormControl();

  constructor(
    private packageService:PackageService,
    private globalService:GlobalService,
    private toast: ToastrService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.getCurrentLanguage()

    this.getPackagesList();
    this.getAPIList();
    this.listThrottlingPolicies();
    this.getListApiGroup();

    this.emailInputChange.pipe(debounceTime(400))
    .subscribe((filterValue: string) => {
      this.filterEmail = filterValue;
      this.page = 1
      this.getPackagesList('', this.filterEmail, this.api_group);
    });

    this.searchInputChange.pipe(debounceTime(400))
    .subscribe((filterValue: string) => {
      this.filterSearch = filterValue;
      this.pageAPI = 1
      this.getAPIList('', this.filterSearch);
    });

    this.searchInputControl.valueChanges.pipe(
      debounceTime(DEBOUNCE_TIME_FOR_INPUT_IN_MS)
    ).subscribe((value: string) => {
      this.getListApiGroup(value)
    })

    // setTimeout(() => {
    //   const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    //   if (isSafari) {
    //     // Array of table header selectors
    //     const selectors = [
    //       '.table-container table thead',
    //       '.p-datatable .p-datatable-thead',
    //       '.AddUserTable table thead',
    //       '.cssTable table thead',
    //       '.customTable thead',
    //       '.autoTable thead',
    //       '.maintable thead'
    //     ];

    //     // Loop through each selector and apply the background style if the element is found
    //     selectors.forEach(selector => {
    //       const thead = document.querySelector(selector);
    //       if (thead instanceof HTMLElement) {
    //         thead.style.background = '#0051AC';
    //       }
    //     });
    //   }
    // }, 500);
  }

  getCurrentLanguage() {
    this.subscriptions.add(this.languageService.currentLanguage.subscribe((language: string) => {
      this.language = language;
      this.translate.use(language);
      this.translate.get("Packages").subscribe((res) => {
        this.packagesPage = res;
      });
      this.translate.get("Clients").subscribe((res) => {
        this.clientsPage = res;
      });
    }));
  }

  onAPIfilter(api_group: number) {
    this.api_group = api_group;
    this.page = 1;
    this.getPackagesList('', this.filterEmail, this.api_group);
  }

  APIfilter() {
    this.getListApiGroup(this.filterpackagename)
  }

  getListApiGroup(search?: string) {
    this.isLoadinglistApiGroup = true;
    this.tableAPIGroup = [];
    this.packageService.listApiGroup(search)
    .then((res:{success:boolean,data:ListApiGroup[]})=>{
      if(res?.success){
        this.tableAPIGroup = res.data
        if(!search){
          this.apiGroups = res.data;
        }
      }
    })
    .catch((err)=>{
      this.globalService.handleError(err);
    })
    .finally(()=>{
      this.isLoadinglistApiGroup = false;
    })
  }

  getPackagesList(sortfilter?: string, search?: string, api_group?: number) {
    this.isLoadingListAPI = true;

    if (this.listPackages) {
      this.listPackages.data = [];
    }

    this.getListPackageModel = new GetListPackageModel()

    if(sortfilter){
      this.sortarray = sortfilter
      this.page = 1
    }

    this.getListPackageModel.page = this.page

    if(search){
      this.getListPackageModel.search = search
    }
    if(api_group){
      this.getListPackageModel.api_group = api_group
    }

    if (this.sortarray === 'sortBytenure'){
      if(sortfilter === 'sortBytenure'){
        this.sortBytenure = !this.sortBytenure
      }
      this.getListPackageModel.sortBytenure = this.sortBytenure ? 'asc' : 'desc'
    } else if (this.sortarray === 'sortByprice'){
      if(sortfilter === 'sortByprice'){
        this.sortByprice = !this.sortByprice
      }
      this.getListPackageModel.sortByprice = this.sortByprice ? 'asc' : 'desc'
    } else if (this.sortarray === 'sortByMaxUsers'){
      if(sortfilter === 'sortByMaxUsers'){
        this.sortByMaxUsers = !this.sortByMaxUsers
      }
      this.getListPackageModel.sortByMaxUsers = this.sortByMaxUsers ? 'asc' : 'desc'
    } else if (this.sortarray === 'sortByMaxLocations'){
      if(sortfilter === 'sortByMaxLocations'){
        this.sortByMaxLocations = !this.sortByMaxLocations
      }
      this.getListPackageModel.sortByMaxLocations = this.sortByMaxLocations ? 'asc' : 'desc'
    }

    this.packageService.listPackages(this.getListPackageModel)
    .then((res:ListPackagesModel)=>{
      if(res.success){
        // this.toast.success(res.message);
        this.listPackages = res
      }
      else{
        this.toast.error(res.message);
        this.listPackages.data = []
        this.listPackages.paginationVariables.pageCount = 0;
      }
      this.isLoadingListAPI = false;
    })
    .catch((err)=>{
      this.globalService.handleError(err);
      this.isLoadingListAPI = false;
    })
  }

  getAPIList(sortfilter?: string, search?: string) {
    this.isLoadingListAPI = true;

    if (this.listWSOPackages) {
      this.listWSOPackages.data = [];
    }

    this.getListAPIModel = new getListWSOModel()

    if(sortfilter){
      this.sortarrayAPI = sortfilter
      this.pageAPI = 1
    }

    if(search){
      this.getListAPIModel.search = search
    }

    this.getListAPIModel.page = this.pageAPI

    if (this.sortarrayAPI === 'sortBytenure'){
      if(sortfilter === 'sortBytenure'){
        this.sortBytenureAPI = !this.sortBytenureAPI
      }
      this.getListAPIModel.sortBytenure = this.sortBytenureAPI ? 'asc' : 'desc'
    } else if (this.sortarrayAPI === 'sortByprice'){
      if(sortfilter === 'sortByprice'){
        this.sortBypriceAPI = !this.sortBypriceAPI
      }
      this.getListAPIModel.sortByprice = this.sortBypriceAPI ? 'asc' : 'desc'
    } else if (this.sortarrayAPI === 'sortByThrottling'){
      if(sortfilter === 'sortByThrottling'){
        this.sortByThrottlingAPI = !this.sortByThrottlingAPI
      }
      this.getListAPIModel.sortByThrottling = this.sortByThrottlingAPI ? 'asc' : 'desc'
    } else if (this.sortarrayAPI === 'sortByStatus'){
      if(sortfilter === 'sortByStatus'){
        this.sortByStatusAPI = !this.sortByStatusAPI
      }
      this.getListAPIModel.sortByStatus = this.sortByStatusAPI ? 'asc' : 'desc'
    }

    this.packageService.listWSOpackage(this.getListAPIModel)
    .then((res: {success: boolean, data: ListAPIData[], paginationVariables: PaginationVariables})=>{
      if(res.success){
        this.listWSOPackages = res
      }
      this.isLoadingListAPI = false;
    })
    .catch((err)=>{
      this.globalService.handleError(err);
      this.isLoadingListAPI = false;
    })
  }

  listThrottlingPolicies(){
    this.spinner.show()
    this.packageService.listThrottlingPolicies()
      .then((res: { success: boolean, data: ThrottlingData[], paginationVariables: PaginationVariables }) => {
        if (res?.success) {
          this.throttlingData = res.data;
        }
      })
      .catch((err) => {
        this.globalService.handleError(err);
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  setEditPackageForm(editPackage:any, activeSelection:string) {
    this.toggleUpsertPackageWindow(true);
    setTimeout(() => {
      if(this.UpsertPackagesComponent){
        this.UpsertPackagesComponent.activeNav = activeSelection
        if(activeSelection === 'Packages'){
          this.UpsertPackagesComponent.editPackage = editPackage;
          this.UpsertPackagesComponent.setEditPackageForm()
          this.UpsertPackagesComponent.disableAPIgroups = true
        } else {
          this.UpsertPackagesComponent.editAPI = editPackage;
          this.UpsertPackagesComponent.setEditAPIForm()
          this.UpsertPackagesComponent.disablePackages = true
        }
      }
    }, 100);
  }

  deletePackage(id:number) {
    this.packageService.deletePackage(id)
    .then((res:{success:boolean,message:string})=>{
      if(res.success){
        this.toast.success(res.message);
        this.isUpsertPackageWindowOpen = false;
        this.getPackagesList();
      }
      else{
        this.toast.error(res.message);
      }
    })
    .catch((err)=>{
      this.globalService.handleError(err);
    })
  }

  deleteWSOPackage(id:number) {
    this.packageService.deleteWSOPackage(id)
    .then((res:{success:boolean,message:string})=>{
      if(res.success){
        this.toast.success(res.message);
        this.isUpsertPackageWindowOpen = false;
        this.getAPIList();
      }
      else{
        this.toast.error(res.message);
      }
    })
    .catch((err)=>{
      this.globalService.handleError(err);
    })
  }

  toggleUpsertPackageWindow(isOpen:boolean = false, reset:boolean = false) {
    this.spinner.show()
    if(!isOpen){
      this.editPackage = null;
      this.editAPI = null;
    }
    this.isUpsertPackageWindowOpen = isOpen;
    if(isOpen && this.UpsertPackagesComponent && reset){
      this.UpsertPackagesComponent.disableAPIgroups = false
      this.UpsertPackagesComponent.disablePackages = false

      this.UpsertPackagesComponent.packageFormGroup.reset();
      this.UpsertPackagesComponent.APIFormGroup.reset();
    }
    setTimeout(() => {
      this.upsertdisplay = true
      this.spinner.hide()
    }, 500);
  }

  toggleExpand(index: number) {
    this.isExpanded[index] = !this.isExpanded[index];
  }

  toggleExpand2(index: number) {
    this.isExpanded2[index] = !this.isExpanded2[index];
  }

  onPageChange(pageNumber:number) {
    this.page = pageNumber;
    this.getPackagesList('', this.filterEmail, this.api_group);
  }

  onWSOPageChange(pageNumber:number) {
    this.pageAPI = pageNumber;
    this.getAPIList('', this.filterSearch);
  }

  show(id:number, isWSO:boolean) {
    this.isDivVisible = true;
    this.deleteData = id;
    this.isWSO = isWSO;
  }

  cancel() {
    this.isDivVisible = false;
  }

  deletePackageBasedOnCondition(deleteData): void {
    if (!this.isWSO) {
      this.deletePackage(deleteData);
      this.getPackagesList();
      this.getAPIList();
      this.listThrottlingPolicies();
      this.getListApiGroup();
    } else {
      this.deleteWSOPackage(deleteData);
      this.getPackagesList();
      this.getAPIList();
      this.listThrottlingPolicies();
      this.getListApiGroup();
    }
    this.cancel();
  }

  onTypeEmail(event: Event) {
    const input = event.target as HTMLInputElement;
    this.emailInputChange.next(input.value);
  }

  onTypeSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    this.searchInputChange.next(input.value);
  }

  onNavChange(nav:string) {
    this.activeNav = nav;
  }
}
