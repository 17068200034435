import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreatePackageModel, GetListPackageModel, UpdatePackageModel, SuspendPackageModel, ResumePackageModel, RenewPackageModel, CreateWSOModel, getListWSOModel, UpdateWSOModel, CreateWSOUser, getListWSOModelUser } from 'src/app/models/package/package.model';
import { environmentBaseUrl } from 'src/environments/environmentBaseUrl';

@Injectable()
export class PackageService {
  baseUrl:string = `${environmentBaseUrl.url}/api/ncm/package/`;
constructor(private http: HttpClient) { }

  listApiGroup(searchTEXT?: string){
    const url = `${this.baseUrl}list-api-group`;
    if(searchTEXT){
      return this.http.post(url, {search: searchTEXT}).toPromise();
    } else {
      return this.http.post(url, {}).toPromise();
    }
  }

  listPrivateApiGroup(){
    const url = `${this.baseUrl}list-private-api-group`;
    return this.http.post(url, {}).toPromise();
  }

  listPackages(getListPackage:GetListPackageModel){
    const url = `${this.baseUrl}list-package`;
    return this.http.post(url,getListPackage).toPromise();
  }
  
  listPackagesByFilter(getListPackage:GetListPackageModel){
    const url = `${this.baseUrl}list-package-by-filters`;
    return this.http.post(url,getListPackage).toPromise();
  }

  createPackage(createPackageModel:CreatePackageModel){
    const url = `${this.baseUrl}create-package`;
    return this.http.post(url,createPackageModel).toPromise();
  }

  createWSOPackage(createWSOModel:CreateWSOModel){
    const url = `${this.baseUrl}create-wso-package`;
    return this.http.post(url,createWSOModel).toPromise();
  }

  editPackage(updatePackageModel:UpdatePackageModel){
    const url = `${this.baseUrl}update-package`;
    return this.http.post(url,updatePackageModel).toPromise();
  }

  editWSOPackage(updateWSOModel:UpdateWSOModel){
    const url = `${this.baseUrl}update-wso-package`;
    return this.http.post(url,updateWSOModel).toPromise();
  }

  deletePackage(id:number){
    const url = `${this.baseUrl}delete-package`;
    return this.http.post(url,{id}).toPromise();
  }

  deleteWSOPackage(id:number){
    const url = `${this.baseUrl}delete-wso-package`;
    return this.http.post(url,{id}).toPromise();
  }
  
  suspendPackage(suspendPackageModel:SuspendPackageModel){
    const url = `${this.baseUrl}suspend-package`;
    return this.http.post(url,suspendPackageModel).toPromise();
  }
  
  resumePackage(resumePackageModel:ResumePackageModel){
    const url = `${this.baseUrl}resume-package`;
    return this.http.post(url,resumePackageModel).toPromise();
  }
  
  renewPackage(renewPackageModel:RenewPackageModel){
    const url = `${this.baseUrl}renew-package`;
    return this.http.post(url,renewPackageModel).toPromise();
  }

  listThrottlingPolicies(){
    const url = `${this.baseUrl}list-throttling-policies`;
    return this.http.post(url,{page_size:999}).toPromise();
  }

  listWSOpackage(getListWSO:getListWSOModel){
    const url = `${this.baseUrl}list-wso-package`;
    return this.http.post(url,getListWSO).toPromise();
  }

  CreateWSOUser(createWSOUser:CreateWSOUser){
    const url = `${this.baseUrl}create-wso-subscription`;
    return this.http.post(url,createWSOUser).toPromise();
  }

  listWSOSubscription(getListWSOUser:getListWSOModelUser){
    const url = `${this.baseUrl}list-wso-subscription`;
    return this.http.post(url,getListWSOUser).toPromise();
  }

  updateEntityStatus(organization_id:number, entity_status: string, trialLimit?: number){
    const url = `${environmentBaseUrl.url}/api/ncm/admin/update-entity-status`;
    return this.http.post(url,{organization_id, entity_status, trialLimit}).toPromise();
  }
}
