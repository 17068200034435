import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ResendInviteUserModel } from 'src/app/models/change-password/change-password.model';
import { CreatePackageModel, ListPrivatePackages, NewPrivatePackage } from 'src/app/models/package/package.model';
import { PaginationVariables } from 'src/app/models/pagination/pagination.model';
import { ListUserTagModelAPI, NCMClientModelAPI, NCMUserModel, UserExcelModelAPI, UsersModel } from 'src/app/models/users/users.model';
import { LanguageService } from 'src/app/services/language/language.service';
import { LocationsService } from 'src/app/services/locations/locations.service';
import { PackageService } from 'src/app/services/package/package.service';
import { UsersService } from 'src/app/services/users/users.service';
import { PASSWORD_PLACEHOLDER } from 'src/app/shared/directives/input-password-placeholder.directive';
import { GlobalService } from 'src/app/shared/utilities/global';

export class UserNCMResponseModel {
  data: UserNCMList[];
  paginationVariables: PaginationVariables;
  success: boolean;
  message: string;
}

export interface UserNCMList {
  id: number
  name: string
  title: any
  email: string
  department: any
  role: { key: string, name_en: string, name_ar: string }
  organization: string
  organizationId: number
  location: any[]
  invite_user: boolean
  edit_user: boolean
  delete_user: boolean
  invite_ncm_user: boolean
  edit_ncm_user: boolean
  delete_ncm_user: boolean
  type: string
  status: string
  apiGroups: ApiGroup[]
  assignedApiGroupIds: { id: number }[]
  token: string
  createdBy: string
  creationDate: string
  iqamaId: string
  tag: { id: number, name: string }
}

export interface ApiGroup {
  id: number
  name: string
}
@Component({
  selector: 'app-alert-editor',
  templateUrl: './add-users.component.html',
  styleUrls: ['./add-users.component.scss']
})
export class AddUsersComponent implements OnInit, OnDestroy {
  private emailInputChange = new Subject<string>();
  private searchTAG = new Subject<number>();

  checked: boolean = false;

  AddUser: boolean = false;
  InviteUser: boolean = false;
  usersAdded: boolean = false;

  selectedLocation: string = "";
  selectedUser: any = {};
  pageSize: number = 10;
  page: number = 1;
  usersForm: FormGroup;
  addUsersForm: FormGroup;
  listPrivateForm: FormGroup;
  locations: any;
  permissions: any;
  users: UserNCMResponseModel = new UserNCMResponseModel();
  userInfo: any;
  usersPage: any;
  language: string;

  private subscription: Subscription = new Subscription();
  deleteLocationData: number;
  isEditUser: boolean;

  packagelist: ListPrivatePackages[]
  newpackageid: NewPrivatePackage;
  isDivVisible: boolean = false;
  isResendInvite: boolean = false;
  resendId: number;
  filterEmail: string;
  filterTag: number;
  clientsPage: any;
  ncmClientModelAPI: NCMClientModelAPI = new NCMClientModelAPI();
  sortarray: string = '';
  sortByCreated: boolean;
  sortByStatus: boolean;
  fullAccessIds: number[] = [];
  allowedAPIgroups: number[] = [];
  allTags: { id: number; name: string; }[];
  allTagsPagination: PaginationVariables;
  Lpage: number = 1;
  LpageSize: number = 10;
  showCreateTag: boolean = false;
  TagName: string = '';
  createTagModal: any;
  userRole: string;
  passwordError: string | null = null;
  confirmPasswordError: string | null = null;
  customNotificationPage:any;

  isLoadingListAPI = false;

  /* whether to show input field for password as text, false means no */
  PASSWORD_VISIBILITY = {
    password: false,
    confirmPassword: false
  }

  readonly PASSWORD_PLACEHOLDER = PASSWORD_PLACEHOLDER;
  passwordStrengthMessage: string = '';
  passwordStrengthClass: string = '';
  isPasswordValid: boolean = false;
  profilePage:any;
  constructor(
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private packageService: PackageService,
    private toast: ToastrService,
    private locationService: LocationsService,
    private globalService: GlobalService,
    private router: Router,
    private translate: TranslateService,
    private spinner: NgxSpinnerService,
    private languageService: LanguageService,
    private modalService: NgbModal
  ) {
    this.subscription.add(this.languageService.currentLanguage.subscribe((language: string) => {
      this.language = language;
      this.translate.use(language);
      this.translate.get("Clients").subscribe((res) => {
        this.clientsPage = res;
      });
      this.translate.get("Users").subscribe((res) => {
        this.usersPage = res;
      });
      this.translate.get("CustomNotifications").subscribe((res) => {
        this.customNotificationPage = res;
      });
      this.translate.get("Profile").subscribe((res) => {
        this.profilePage = res;
      });
    }));
    this.userInfo = this.getUserInfo();
    this.usersForm = this.formBuilder.group({
      id: [null],
      role: [{ value: this.userInfo.role === 'NCM_OPERATOR' ? 'NCM_OPERATOR' : null, disabled: this.userInfo.role === 'NCM_OPERATOR' ? true : false }, Validators.required],
      fullName: ["", [Validators.required, Validators.pattern(/^[A-Za-z0-9\u0600-\u06FF\s]+$/)]],
      email: ["", [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[a-zA-Z]{2,}$/)]],
      tags: [null],
      password: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          // Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/),
        ],
      ],
      confirmpassword: [null],
    });
    this.addUsersForm = this.formBuilder.group({
      id: [null],
      role: [{ value: this.userInfo.role === 'NCM_OPERATOR' ? 'NCM_OPERATOR' : null, disabled: this.userInfo.role === 'NCM_OPERATOR' ? true : false }, Validators.required],
      fullName: ["", [Validators.required, Validators.pattern(/^[A-Za-z0-9\u0600-\u06FF\s]+$/)]],
      email: ["", [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[a-zA-Z]{2,}$/)]],
      tags: [null],
      password: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          // Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/),
        ],
      ],
      confirmpassword: [null],
    });
    this.getLocations();
  }

  ngOnInit() {
    this.listPrivateForm = this.formBuilder.group({})
    this.getUsersList();
    this.getprivateapigroups()
    this.getUserTags()

    this.userRole = this.globalService.getUserRole();

    this.emailInputChange.pipe(
      debounceTime(400)
    ).subscribe((filterValue: string) => {
      this.filterEmail = filterValue;
      this.page = 1
      this.getUsersList('', this.filterEmail, this.filterTag);
    });

    this.searchTAG.pipe(
      debounceTime(400)
    ).subscribe((filterValue: number) => {
      this.filterTag = filterValue;
      this.page = 1
      this.getUsersList('', this.filterEmail, this.filterTag);
    });

    // setTimeout(() => {
    //   const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    //   if (isSafari) {
    //     // Array of table header selectors
    //     const selectors = [
    //       '.table-container table thead',
    //       '.p-datatable .p-datatable-thead',
    //       '.AddUserTable table thead',
    //       '.cssTable table thead',
    //       '.customTable thead',
    //       '.autoTable thead',
    //       '.maintable thead'
    //     ];

    //     // Loop through each selector and apply the background style if the element is found
    //     selectors.forEach(selector => {
    //       const thead = document.querySelector(selector);
    //       if (thead instanceof HTMLElement) {
    //         thead.style.background = '#0051AC';
    //       }
    //     });
    //   }
    // }, 500);
  }
  checkPasswordStrength() {
    const password = this.addUsersForm.controls['password'].value;

    // Reset strength messages.
    this.passwordStrengthMessage = '';
    this.passwordStrengthClass = '';

    if (!password) return;

    // Basic strength rules.
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*]/.test(password);
    const isLongEnough = password.length >= 8;
    const hasNoSequentialChars = !/(.)\1{2,}/.test(password); // No 3+ repeated characters.
    const hasNoSequentialNumbers = !/(012|123|234|345|456|567|678|789|12345|67890|112233|98765|000000|111111|222222|123456789|555555|123123|abcdef|ghijkl|asdfgh|zxcvbn|qwertyuiop|qazwsx|poiuyt|abc123|abcabc|1q2w3e4r|1qaz2wsx|qweasd|asdfghjkl|zxcvbnm|123qwe|123asd|789qwe|987poi|qazwsx|aaaaaa|bbbbbb|cccccc|333333|444444|666666|777777|888888|999999)/.test(password); // No sequential numbers.
    if(!hasUpperCase){
      this.passwordStrengthMessage = this.language === 'en' ? 'Weak' : 'ضعيف';
      this.passwordStrengthClass = 'text-danger';
      return;
    }
    if(!hasLowerCase){
      this.passwordStrengthMessage = this.language === 'en' ? 'Weak' : 'ضعيف';
      this.passwordStrengthClass = 'text-danger';
      return;
    }
    if(!hasNumber){
      this.passwordStrengthMessage = this.language === 'en' ? 'Weak' : 'ضعيف';
      this.passwordStrengthClass = 'text-danger';
      return;
    }
    if(!hasSpecialChar){
      this.passwordStrengthMessage = this.language === 'en' ? 'Weak' : 'ضعيف';
      this.passwordStrengthClass = 'text-danger';
      return;
    }
    if(!isLongEnough){
      this.passwordStrengthMessage = this.language === 'en' ? 'Weak' : 'ضعيف';
      this.passwordStrengthClass = 'text-danger';
      return;
    }
    if (!hasNoSequentialNumbers) {
      this.passwordStrengthMessage = this.language === 'en' ? 'Weak' : 'ضعيف';
      this.passwordStrengthClass = 'text-danger';
      return;
    }
    let strengthScore = 0;

    // Calculate password strength score.
    if (hasUpperCase) strengthScore++;
    if (hasLowerCase) strengthScore++;
    if (hasNumber) strengthScore++;
    if (hasSpecialChar) strengthScore++;
    if (isLongEnough) strengthScore++;
    if (hasNoSequentialChars) strengthScore++;
    if (hasNoSequentialNumbers) strengthScore++;

    // Display password strength.
    if (strengthScore <= 2) {
      this.passwordStrengthMessage = this.language === 'en' ? 'Very Weak' : 'ضعيف جداً';
      this.passwordStrengthClass = 'text-danger';
    } else if (strengthScore <= 4) {
      this.passwordStrengthMessage = this.language === 'en' ? 'Weak' : 'ضعيف';
      this.passwordStrengthClass = 'text-danger';
    } else if (strengthScore <= 5) {
      this.passwordStrengthMessage = this.language === 'en' ? 'Moderate' : 'معتدل';
      this.passwordStrengthClass = 'text-info';
    } else if (strengthScore === 6) {
      this.passwordStrengthMessage = this.language === 'en' ? 'Strong' : 'قوي';
      this.passwordStrengthClass = 'text-success';
    } else if (strengthScore === 7) {
      this.passwordStrengthMessage = this.language === 'en' ? 'Very Strong' : 'قوي جداً';
      this.passwordStrengthClass = 'text-success';
    }
    // debugger
  }

  infiniteUserTagsForDropdown() {
    if (this.allTagsPagination?.pageCount > this.Lpage) {
      this.Lpage++;
      this.getUserTags()
    }
  }

  getUserTags(search?: string) {
    const listUserTagModelAPI: ListUserTagModelAPI = new ListUserTagModelAPI();

    listUserTagModelAPI.page = this.Lpage;
    listUserTagModelAPI.page_size = this.LpageSize;

    if (search) {
      listUserTagModelAPI.search = search
      this.Lpage = 1
      listUserTagModelAPI.page = this.Lpage
    }
    this.spinner.show();

    this.userService.listUserTag(listUserTagModelAPI)
      .then((res: { success: boolean, data: { id: number, name: string }[], paginationVariables: PaginationVariables }) => {
        if (res.success) {
          if (this.Lpage === 1) {
            this.allTags = []
            this.allTags = res.data;
          } else {
            this.allTags = [...this.allTags, ...res.data];
          }
          this.allTagsPagination = res.paginationVariables;
        }
      })
      .catch((err) => {
        this.globalService.handleError(err);
      })
      .finally(() => {
        this.spinner.hide();
      })
  }

  openCreateTagModal(content: any) {
    this.createTagModal = this.modalService.open(content, {
      windowClass: 'TagModal',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      modalDialogClass: 'modal-lg',
      backdrop: 'static'
    });
    this.createTagModal.result.then((result: any) => {
      this.onCreateTag(result)
    });
  }

  onCreateTag(TagName?: string) {
    this.spinner.show();
    this.userService.createUserTag(TagName)
      .then((res: { success: boolean, message: string }) => {
        if (res.success) {
          this.showCreateTag = false;
          this.getUserTags()
          this.toast.success(res.message)
        } else {
          this.toast.error(res.message)
        }
      })
      .catch((err) => {
        this.globalService.handleError(err);
      })
      .finally(() => {
        this.spinner.hide();
      })
  }

  getprivateapigroups() {
    this.spinner.show();
    this.packageService.listPrivateApiGroup().then(
      (res: { success: boolean, data: ListPrivatePackages[] }) => {
        this.spinner.hide();
        this.packagelist = res.data;
        this.packagelist.forEach((item, index) => {
          this.listPrivateForm.addControl(item.id.toString(), this.formBuilder.control(false));

          const groupIndex = Math.floor(index / 5);

          if (groupIndex < 3 && index % 5 === 0) {
            this.fullAccessIds.push(item.id);
          }
          else if (groupIndex >= 3 && (index - 15) % 4 === 0) {
            this.fullAccessIds.push(item.id);
          }
        });
        console.log('fullAccessIds', this.fullAccessIds);
      }
    ).catch((err) => {
      this.globalService.handleError(err);
    });
  }

  closeAddUserWindow() {
    this.AddUser = false;
    this.InviteUser = false;
    this.addUsersForm.reset();
    this.addUsersForm.enable();
    this.usersForm.reset();
    this.listPrivateForm.reset();
    this.usersForm.enable();
    this.allowedAPIgroups = [];
  }

  openAddUserWindow() {
    this.resetPasswordVisibility();
    this.AddUser = true;
    this.InviteUser = false;
    this.addUsersForm.reset();
    this.addUsersForm.enable();
    this.usersForm.reset();
    this.usersForm.enable();
    this.listPrivateForm.reset();
    this.isEditUser = false;
    setTimeout(() => {
      this.spinner.show();
      this.addUsersForm.reset();
      this.confirmPasswordError = null;
      this.spinner.hide();
    }, 1000);
  }
  closeInviteUserWindow() {
    this.InviteUser = false;
    this.AddUser = false;
    this.addUsersForm.reset();
    this.addUsersForm.enable();
    this.usersForm.reset();
    this.listPrivateForm.reset();
    this.usersForm.enable();
    this.allowedAPIgroups = [];
  }
  openInviteUserWindow() {
    this.InviteUser = true;
    this.AddUser = false;
    this.addUsersForm.reset();
    this.addUsersForm.enable();
    this.usersForm.reset();
    this.usersForm.enable();
    this.listPrivateForm.reset();
    this.isEditUser = false;
  }

  openEditUserWindow() {
    this.AddUser = true;
    this.InviteUser = false;
    this.addUsersForm.reset();
    this.addUsersForm.enable();
    this.usersForm.reset();
    this.usersForm.enable();
    this.listPrivateForm.reset();
  }

  onLocationCheck(checked: any) {
    if (!checked) {
      this.usersForm.controls.location.enable();
    }
    else {
      this.usersForm.controls.location.setValue(null);
      this.usersForm.controls.location.disable();
    }
  }

  getUserInfo() {
    return JSON.parse(localStorage.getItem('user'));
  }

  getBtnClick(event) {
    // this.AddUser = true;
    this.AddUser = !this.AddUser;
  }

  getLocations() {
    this.spinner.show();
    this.locationService.getLocations().then(
      (res: any) => {
        this.locations = res;
      })
    this.spinner.hide();

  }

  getUsersList(sortfilter?: string, search?: string, tag?: number) {
    // this.spinner.show();
    this.isLoadingListAPI = true;
    this.users = new UserNCMResponseModel();
    this.ncmClientModelAPI = new NCMClientModelAPI()

    if (sortfilter) {
      this.sortarray = sortfilter
    }

    this.ncmClientModelAPI.page = this.page
    this.ncmClientModelAPI.page_size = this.pageSize

    if (search) {
      this.ncmClientModelAPI.search = search
      this.page = 1
      this.ncmClientModelAPI.page = 1
    }
    if (tag) {
      this.ncmClientModelAPI.searchByTag = tag
      this.page = 1
      this.ncmClientModelAPI.page = 1
    }

    if (this.sortarray === 'sortByCreated') {
      if (sortfilter === 'sortByCreated') {
        this.sortByCreated = !this.sortByCreated
      }
      this.ncmClientModelAPI.sortByCreated = this.sortByCreated ? 'asc' : 'desc'
    } else if (this.sortarray === 'sortByStatus') {
      if (sortfilter === 'sortByStatus') {
        this.sortByStatus = !this.sortByStatus
      }
      this.ncmClientModelAPI.sortByStatus = this.sortByStatus ? 'asc' : 'desc'
    }

    this.userService.getUsersList(this.ncmClientModelAPI).then(
      (res: UserNCMResponseModel) => {
        if(res.success){
          // this.spinner.hide();
          this.users = res;
        }
        else{
          this.toast.error(res?.message);
          // this.spinner.hide();
        }
        this.isLoadingListAPI = false;
      }
    ).catch((err) => {
      // this.spinner.hide();
      this.globalService.handleError(err);
      this.isLoadingListAPI = false;
    })
  }

  onSave() {
    if (this.usersForm.invalid) {
      const isValid = this.showValidationsMessages();

      if (!isValid) return;
      this.usersForm.controls['email']?.setValue(this.usersForm.controls['email']?.value.trim());
      if (this.usersForm.controls['email']?.value && this.usersForm.controls['email']?.errors?.['pattern']) {
        this.toast.error(this.clientsPage?.emailFormatIncorrect);
        return;
      }

      if (this.usersForm.controls['fullName']?.value && this.usersForm.controls['fullName']?.errors?.['pattern']) {
        this.toast.error(this.clientsPage?.nameContainsNumberSpecialChars);
        return;
      }
      return
    }

    const userModel: NCMUserModel = new NCMUserModel();
    userModel.name = this.usersForm.controls.fullName.value;
    userModel.email = this.usersForm.controls.email.value;
    userModel.role = this.usersForm.controls.role.value;
    userModel.allowedApiGrpups = this.allowedAPIgroups;
    if (this.usersForm.controls.tags.value) {
      userModel.tagId = this.usersForm.controls.tags.value
    }

    const selectedIds = Object.keys(this.listPrivateForm.controls)
      .filter(key => this.listPrivateForm.get(key).value)

    if (selectedIds?.length === 0) {
      this.toast.error(this.usersPage?.selectAtLeastOnePackage);
      return;
    }

    if (this.usersForm.controls['id'].value) {
      userModel.id = this.usersForm.controls['id'].value
    }

    if (!this.isEditUser) {
      this.inviteUser(userModel)
    }
    else {
      if (this.usersForm.controls.password.value) {
        if (this.usersForm.controls.password.value.length < 8) {
          this.toast.error(this.usersPage?.passwordLength);
          return;
        }
        if (this.usersForm.controls.confirmpassword.value !== this.usersForm.controls.password.value) {
          this.toast.error(this.usersPage?.passwordMatch);
          return;
        }
        userModel.password = this.usersForm.controls.password.value;
      }
      this.editNcmUser(userModel);
    }
  }
  showAddManualUserValidationsMessages(): boolean {
    const requiredFieldsMessage = this.usersPage;
    const patternsMessages = this.usersPage;

    for (const [controlName, value] of Object.entries(this.addUsersForm.controls)) {
      const formControl = this.addUsersForm.get(controlName);
      if (formControl?.errors?.required) {
        this.toast.error(requiredFieldsMessage[controlName]);
        return false;
      }
    }

    for (const [controlName, value] of Object.entries(this.addUsersForm.controls)) {
      const formControl = this.addUsersForm.get(controlName);
      if (formControl?.hasError('pattern') && controlName !== 'email') {
        this.toast.error(patternsMessages[controlName]);
        return false;
      }
      else {
        return true;
      }
    }
  }
  onAddUserSave() {
    if (this.addUsersForm.invalid) {
      const isValid = this.showAddManualUserValidationsMessages();

      if (!isValid) return;
      this.addUsersForm.controls['email']?.setValue(this.addUsersForm.controls['email']?.value.trim());
      if (this.addUsersForm.controls['email']?.value && this.addUsersForm.controls['email']?.errors?.['pattern']) {
        this.toast.error(this.clientsPage?.emailFormatIncorrect);
        return;
      }

      if (this.addUsersForm.controls['fullName']?.value && this.addUsersForm.controls['fullName']?.errors?.['pattern']) {
        this.toast.error(this.clientsPage?.nameContainsNumberSpecialChars);
        return;
      }
      return
    }

    const userModel: NCMUserModel = new NCMUserModel();
    userModel.name = this.addUsersForm.controls.fullName.value;
    userModel.email = this.addUsersForm.controls.email.value;
    userModel.password = this.addUsersForm.controls.password.value;
    userModel.role = this.addUsersForm.controls.role.value;
    userModel.allowedApiGrpups = this.allowedAPIgroups;
    if (this.addUsersForm.controls.tags.value) {
      userModel.tagId = this.addUsersForm.controls.tags.value
    }

    const selectedIds = Object.keys(this.listPrivateForm.controls)
      .filter(key => this.listPrivateForm.get(key).value)

    if (selectedIds?.length === 0) {
      this.toast.error(this.usersPage?.selectAtLeastOnePackage);
      return;
    }

    if (this.addUsersForm.controls['id'].value) {
      userModel.id = this.addUsersForm.controls['id'].value
    }

    if (!this.isEditUser) {
      this.addNcmUser(userModel)
    }
    else {
      if (this.addUsersForm.controls.password.value) {
        if (this.addUsersForm.controls.password.value.length < 8) {
          this.toast.error(this.usersPage?.passwordLength);
          return;
        }
        if (this.addUsersForm.controls.confirmpassword.value !== this.addUsersForm.controls.password.value) {
          this.toast.error(this.usersPage?.passwordMatch);
          return;
        }
        userModel.password = this.addUsersForm.controls.password.value;
      }
      this.editNcmUser(userModel);
    }
  }

  showValidationsMessages(): boolean {
    const requiredFieldsMessage = this.usersPage;
    const patternsMessages = this.usersPage;

    for (const [controlName, value] of Object.entries(this.usersForm.controls)) {
      const formControl = this.usersForm.get(controlName);
      if (formControl?.errors?.required) {
        this.toast.error(requiredFieldsMessage[controlName]);
        return false;
      }
    }

    for (const [controlName, value] of Object.entries(this.usersForm.controls)) {
      const formControl = this.usersForm.get(controlName);
      if (formControl?.hasError('pattern') && controlName !== 'email') {
        this.toast.error(patternsMessages[controlName]);
        return false;
      }
      else {
        return true;
      }
    }
  }

  setEditUser(user: UserNCMList) {
    this.resetPasswordVisibility();
    this.spinner.show();
    this.isEditUser = true;
    this.openEditUserWindow();
    this.addUsersForm.controls['id'].setValue(user.id);
    this.addUsersForm.controls['fullName'].setValue(user.name);
    this.addUsersForm.controls['email'].setValue(user.email);
    this.addUsersForm.controls['email'].disable();
    this.addUsersForm.controls['role'].setValue(user.role?.key);
    this.onRoleChange({ value: user.role?.key });
    this.addUsersForm.controls['tags'].setValue(user.tag?.id);

    // check if user switched from "add", in that case we need to show placeholder again
    // as they won't get called again from the directive
    const { password, confirmpassword } = this.addUsersForm.value;

    if (!password && !confirmpassword) {
      this.addUsersForm.patchValue({
        password: PASSWORD_PLACEHOLDER,
        confirmpassword: PASSWORD_PLACEHOLDER,
      })
    }

    if (user?.assignedApiGroupIds?.length > 0) {
      Object.entries(this.listPrivateForm.controls)
        .forEach(([controlName, value]) => {
          const formControl = this.listPrivateForm.get(controlName);
          if (formControl && user?.assignedApiGroupIds.some((group) => controlName == group.id.toString())) {
            formControl.setValue(true);
          }
        })
    }

    this.allowedAPIgroups = user?.assignedApiGroupIds.map(group => group.id);

    for (let i = 0; i < user?.assignedApiGroupIds.length; i++) {
      if (this.fullAccessIds.includes(user?.assignedApiGroupIds[i].id)) {
        const groupIndex = this.fullAccessIds.indexOf(user?.assignedApiGroupIds[i].id);
        this.handleFullAccessChange(groupIndex);
      }
    }
    setTimeout(() => {
      this.spinner.hide();
    }, 500)
  }

  checkPasswordValidity() {
    const passwordControl = this.addUsersForm.controls['password'];
    const confirmPasswordControl = this.addUsersForm.controls['confirmpassword'];

    // Check password length
    // if (passwordControl.value.length < 8) {
    //   this.passwordError = this.usersPage?.passwordLength;
    // } else {
    //   this.passwordError = null;
    // }

    // Check if passwords match
    if (confirmPasswordControl.value !== passwordControl.value) {
      this.confirmPasswordError = this.usersPage?.passwordMatch;
    } else {
      this.confirmPasswordError = null;
    }
  }

  getGroupIndices(): number[] {
    const totalItems = this.packagelist.length;
    const groups = [];

    // First three groups with 5 items each
    for (let i = 0; i < 3; i++) {
      groups.push(i);
    }

    // Last two groups with 4 items each
    const remainingItems = totalItems - 15;
    const additionalGroups = Math.ceil(remainingItems / 4);
    for (let i = 0; i < additionalGroups; i++) {
      groups.push(3 + i);
    }

    return groups;
  }

  getGroupItems(groupIndex: number): any[] {
    if (groupIndex < 3) {
      return this.packagelist.slice(groupIndex * 5, (groupIndex + 1) * 5);
    } else {
      const offset = 15 + (groupIndex - 3) * 4;
      return this.packagelist.slice(offset, offset + 4);
    }
  }

  handleFullAccessChange(groupIndex: number, setEdit: boolean = false) {
    let startingIndex: number;
    let endingIndex: number;

    if (groupIndex < 3) {
      startingIndex = groupIndex * 5;
      endingIndex = startingIndex + 5;
    }else if(groupIndex === 3) {
      startingIndex = groupIndex * 5;
      endingIndex = startingIndex + 4;
    } else {
      startingIndex = (groupIndex * 5) - 1;
      endingIndex = startingIndex + 4;
    }

    const fullAccessId = this.packagelist[startingIndex].id;
    const fullAccessCheckbox = this.listPrivateForm.controls[fullAccessId].value;

    if (fullAccessCheckbox) {
      for (let i = startingIndex + 1; i < endingIndex; i++) {
        this.listPrivateForm.controls[this.packagelist[i].id.toString()].setValue(true);
      }

      if (setEdit) {
        this.allowedAPIgroups.push(fullAccessId);
      }

      for (let i = startingIndex + 1; i < endingIndex; i++) {
        const idToRemove = this.packagelist[i].id;
        const indexToRemove = this.allowedAPIgroups.indexOf(idToRemove);
        if (indexToRemove !== -1) {
          this.allowedAPIgroups.splice(indexToRemove, 1);
        }
      }
    } else {
      for (let i = startingIndex + 1; i < endingIndex; i++) {
        this.listPrivateForm.controls[this.packagelist[i].id.toString()].setValue(false);
      }

      const indexToRemove = this.allowedAPIgroups.indexOf(fullAccessId);
      if (indexToRemove !== -1) {
        this.allowedAPIgroups.splice(indexToRemove, 1);
      }
      for (let i = startingIndex + 1; i < endingIndex; i++) {
        const idToRemove = this.packagelist[i].id;
        const indexToRemove = this.allowedAPIgroups.indexOf(idToRemove);
        if (indexToRemove !== -1) {
          this.allowedAPIgroups.splice(indexToRemove, 1);
        }
      }
    }
  }

  handlePrivateApiGroupClick(groupIndex: number) {
    let startingIndex: number;
    let endingIndex: number;

    if (groupIndex < 3) {
      startingIndex = (groupIndex * 5) + 1;
      endingIndex = startingIndex + 4;
    }else if(groupIndex === 3) {
      startingIndex = (groupIndex * 5) + 1;
      endingIndex = startingIndex + 3;
    } else {
      startingIndex = groupIndex * 5;
      endingIndex = startingIndex + 3;
    }

    const allOthersChecked = this.packagelist
      .slice(startingIndex, endingIndex)
      .every(item => this.listPrivateForm.controls[item.id.toString()].value);

    if (allOthersChecked) {
      this.listPrivateForm.controls[this.packagelist[(startingIndex - 1)].id.toString()].setValue(true);
      this.handleFullAccessChange(groupIndex, true);
    } else if (!allOthersChecked) {
      this.listPrivateForm.controls[this.packagelist[(startingIndex - 1)].id.toString()].setValue(false);
      for (let i = (startingIndex - 1); i < endingIndex; i++) {
        if (this.listPrivateForm.controls[this.packagelist[i].id.toString()].value) {
          if (!this.allowedAPIgroups.includes(this.packagelist[i].id)) {
            this.allowedAPIgroups.push(this.packagelist[i].id);
          }
        } else {
          if (this.allowedAPIgroups.includes(this.packagelist[i].id)) {
            this.allowedAPIgroups.splice(this.allowedAPIgroups.indexOf(this.packagelist[i].id), 1);
          }
        }
      }
    }
  }

  editNcmUser(userModel: NCMUserModel) {
    this.spinner.show();

    // dont send invalid string to server
    if (userModel.password === PASSWORD_PLACEHOLDER) {
      delete userModel.password;
    }

    this.userService.editNCMUser(userModel)
      .then((res: { success: boolean, message: string }) => {
        if (res.success) {
          this.toast.success(res.message);
          this.resetState()
          this.getUsersList()
        }
        else {
          this.toast.error(res.message);
        }
      })
      .catch((error) => {
        this.globalService.handleError(error);
      })
      .finally(() => {
        this.spinner.hide();
      })
  }

  inviteUser(userModel: NCMUserModel) {
    this.spinner.show();
    this.userService.inviteUser(userModel)
      .then((res: { success: boolean, message: string }) => {
        if (res.success) {
          this.toast.success(res.message);
          this.resetState();
          this.getUsersList();
        }
        else {
          this.toast.error(res.message);
        }
      })
      .catch((error) => {
        this.globalService.handleError(error);
      })
      .finally(() => {
        this.spinner.hide();
      })
  }
  addNcmUser(userModel: NCMUserModel) {
    this.spinner.show();
    this.userService.addNcmUser(userModel)
      .then((res: { success: boolean, message: string }) => {
        if (res.success) {
          this.toast.success(res.message);
          this.resetState();
          this.getUsersList();
        }
        else {
          this.toast.error(res.message);
        }
      })
      .catch((error) => {
        this.globalService.handleError(error);
      })
      .finally(() => {
        this.spinner.hide();
      })
  }

  resetState() {
    this.closeAddUserWindow();
    this.isEditUser = false;
  }

  resendInviteUser(id: number) {
    this.spinner.show();
    const resendInviteUserModel: ResendInviteUserModel = new ResendInviteUserModel()
    resendInviteUserModel.id = id
    // resendInviteUserModel.Authorization = this.globalService.getToken()
    this.userService.resendInviteUser(resendInviteUserModel).then(
      (res: any) => {
        this.spinner.hide();
        if (res.success) {
          this.toast.success(res.message)
          this.getUsersList();
        }
        else {
          this.toast.error(res.message)
        }
      })
      .catch((err) => {
        this.spinner.hide();
        this.globalService.handleError(err);
      })
  }

  onRoleChange(event: { value: string }) {
    const role = event.value;
    Object.entries(this.listPrivateForm.controls).forEach(([key, control]) => {
      if (control) {
        if (role === 'NCM_IT') {
          control.setValue(true);
          control.disable();
        }
        else {
          control.setValue(false);
          control.enable();
        }
      }
    })
    if (role === 'NCM_IT') {
      this.allowedAPIgroups = []
      this.allowedAPIgroups = this.fullAccessIds
    } else {
      this.allowedAPIgroups = []
    }
  }

  deleteUser(id: number) {
    this.userService.deleteUser(id).then((res: any) => {
      if (res?.success) {
        this.toast.success(res?.message);
        this.getUsersList();
        this.resetState();
      }
      else {
        this.toast.error(res?.message);
      }

    }).catch((err) => {
      this.spinner.hide();
      this.globalService.handleError(err);
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  navigateToRegion() {
    this.router.navigate(['home/addregion']);
  }

  navigateToPolygon() {
    this.router.navigate(['home/addpolygon']);
  }

  show(id: number) {
    this.isDivVisible = true;
    this.deleteLocationData = id;
  }

  cancel() {
    this.isDivVisible = false;
  }

  showresend(id: number) {
    this.isResendInvite = true;
    this.resendId = id;
  }

  cancelresend() {
    this.isResendInvite = false;
  }

  onPageChange(pageNumber: number) {
    this.page = pageNumber;
    this.getUsersList('', this.filterEmail);
  }

  onTypeEmail(event: Event) {
    const input = event.target as HTMLInputElement;
    this.emailInputChange.next(input.value);
  }

  onTagSearch(event: number) {
    this.searchTAG.next(event);
  }

  generateExcel() {
    this.spinner.show()
    const userExcelModelAPI: UserExcelModelAPI = new UserExcelModelAPI()
    userExcelModelAPI.type = 'ncm'
    this.userService.getUserExcel(userExcelModelAPI)
      .then((res: { data: { id: number, path: string }, success: boolean, message: string }) => {
        if (res?.success) {
          const a = document.createElement('a');
          a.href = res?.data?.path;
          a.target = '_blank';
          a.click();
          this.toast.success(res?.message)
        } else {
          this.toast.success(res?.message)
        }
      })
      .catch((err) => {
        this.spinner.hide()
        this.globalService.handleError(err)
      })
      .finally(() => {
        this.spinner.hide()
      })
  }

  resetPasswordVisibility() {
    this.PASSWORD_VISIBILITY = {
      password: false,
      confirmPassword: false
    }
  }
}
