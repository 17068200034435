<div ngbNav #nav="ngbNav" [(activeId)]="activeNav" [destroyOnHide]="false">
  <div [ngbNavItem]="navItems[0]">
    <ng-template ngbNavContent>
      <!-- <app-early-warning-tabular-view (onNavChange)="onNavChange($event)" [activeNav]="activeNav" [navItems]="navItems"></app-early-warning-tabular-view> -->
      <div class="AddUserTable mt-30">
        <div class="d-flex align-items-center justify-content-between title-container flex-wrap">
          <div class="title col-lg-6 col-md-6 col-sm-12 col-12">
            <h3 class="tableheadertext">{{ automaticWeatherPage?.RecentReportsGenerated }}</h3>
          </div>
          <div class="d-flex flex-wrap align-items-center justify-content-end gap-2 col-lg-6 col-md-6 col-sm-12 col-12">
            <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="client-type-select col-lg-5 col-md-6 col-sm-12 col-12" appearance="outline">
              <mat-label>{{automaticWeatherPage?.Search}}</mat-label>
              <input type="text" matInput (keyup)="onTypeUser($event)">
            </mat-form-field>
            <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" appearance="outline" class="client-type-select col-lg-3 col-md-6 col-sm-12 col-12">
              <mat-label>{{automaticWeatherPage?.Show}}</mat-label>
              <mat-select (valueChange)="onChangeEntry($event)" [placeholder]="'10'" floatLabel="always">
                <mat-option [value]="entry.value" *ngFor="let entry of entries; index as i">{{language == 'en' ? entry?.label : entry?.label_ar}}</mat-option>
              </mat-select>
            </mat-form-field>
            <div class="filters-like col-lg-2 col-md-6 col-sm-12 col-12" style="width: max-content;">
              <ng-container *ngFor="let nav of navItems">
                <button container="body" (click)="onNavChange(nav)" [ngClass]="{'active' : activeNav === nav}" class="col-6" [ngbTooltip]="automaticWeatherPage[nav]">
                  <img [src]="'../../../../assets/view-icons/' + nav +'.svg'">
                </button>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="mb-30" style="overflow-x: auto;">
          <table class="customTable">
            <thead>
              <tr>
                <th>{{ automaticWeatherPage?.ReportTitle }}</th>
                <th>{{ automaticWeatherPage?.ReportType }}</th>
                <th>{{ automaticWeatherPage?.Email }}</th>
                <th>{{ automaticWeatherPage?.Createdby }}</th>
                <!-- <th>{{ automaticWeatherPage?.Createdon }}</th> -->
                <th class="cursor-pointer" (click)="getAllReports('sortByCreated')"> <!-- AlertStatus -->
                  <div class="d-flex align-items-center gap-2">
                    {{automaticWeatherPage?.Createdon}}
                    <div class="d-flex flex-column justify-content-center">
                      <i [ngClass]="listReportModel.sortByCreated === 'asc' ? 'sort-active' : ''"
                        class="fas fa-sort-up sort-icon"></i>
                      <i [ngClass]="listReportModel.sortByCreated === 'desc' ? 'sort-active' : ''"
                        class="fas fa-sort-down sort-icon"></i>
                    </div>
                  </div>
                </th>
                <th class="text-center">{{ automaticWeatherPage?.Actions }}</th>
              </tr>
            </thead>
            <tbody *ngIf="!isLoadingListAPI">
              <tr *ngFor="let list of searchdata?.data">
                <td>
                  {{ language == 'en' ?
                  (list.reportTitleEn === 'arabian-gulf-report' || list.reportTitleEn === 'red-sea-report' ? list.reportTypeEn : list.reportTitleEn)
                  :
                  (list.reportTitleEn === 'arabian-gulf-report' || list.reportTitleEn === 'red-sea-report' ? list.reportTypeAr : list.reportTitleAr) }}
                </td>
                <td>{{ language == 'en' ? list.reportTypeEn : list.reportTypeAr }}</td>
                <td>{{list.email }}</td>
                <td>{{list.createdBy }}</td>
                <td *ngIf="language=='en'">{{list.createdOn | date: "dd-MM-yyyy":"":"en" }}</td>
                <td *ngIf="language=='ar'">{{list.createdOn | date: "dd-MM-yyyy":"":"ar" }}</td>
                <td class="justify-content-start">
                  <div class="d-flex align-items-center gap-3 justify-content-center" style="padding-inline-start: 1rem;">
                    <button [ngStyle]="list?.reportTypeEn === '10-Day Forecast' ? {} : {'visibility': 'hidden', 'pointer-events': 'none'}"
                      (click)="titleEdit(title, list)" [ngbTooltip]="automaticWeatherPage?.EditDetail" tooltipClass="custom-tooltip" *ngxPermissionsOnly="['update_report']">
                      <img src="../../../../assets/mannedforecast/editforecast-icon.png" style="scale: 0.8;">
                    </button>
                    <!--  -->
                    <button [ngStyle]="list?.reportTypeKey === 'advance-custom-weather-report' && list?.isMannedAdvanceCustomReport === true ? {'visibility': 'hidden', 'pointer-events': 'none'} : {}"
                      (click)="goTOedit(list)" [ngbTooltip]="automaticWeatherPage?.EditReport" tooltipClass="custom-tooltip" *ngxPermissionsOnly="['update_report']">
                      <img src="../../../../assets/mannedforecast/editmodal-icon.png" style="scale: 0.8;">
                    </button>
                    <button (click)="generatePdfReport(list?.id)" [ngbTooltip]="automaticWeatherPage?.Pdf" tooltipClass="custom-tooltip">
                      <img src="../../../assets/mannedforecast/pdfforecast-icon.png" style="scale: 0.8;">
                    </button>
                    <button (click)="deleteWeatherReport(list?.id)" [ngbTooltip]="automaticWeatherPage?.DeleteReport" tooltipClass="custom-tooltip">
                      <img src="../../../../assets/alerteditor/delete-icon.png" style="scale: 0.8;">
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-center no-data" *ngIf="isLoadingListAPI; else noRecordsTemplate">
          <i class="fa fa-spinner spinner--icon"></i>
        </div>
        <ng-template #noRecordsTemplate>
          <ng-container *ngIf="searchdata?.data?.length === 0 || !searchdata?.data">
            <div class="d-flex justify-content-center no-data">
              {{customNotificationPage?.NoRecordsFound}}
            </div>
          </ng-container>
        </ng-template>
        <div *ngIf="searchdata?.paginationVariables?.pageCount > 1 && !isLoadingListAPI" class="col-12 d-flex justify-content-center pagination dir-left mb-30">
          <ngb-pagination [maxSize]="3" [rotate]="true" [pageSize]="pageSize" [page]="page" (pageChange)="onPageChange($event)"
            [collectionSize]="searchdata?.paginationVariables?.totalCount"></ngb-pagination>
        </div>
      </div>
    </ng-template>
  </div>
  <div [ngbNavItem]="navItems[1]">
    <ng-template ngbNavContent>
      <!-- <app-early-warning-calendar-view (onNavChange)="onNavChange($event)" [activeNav]="activeNav"  [navItems]="navItems"></app-early-warning-calendar-view> -->
      <div class="AddUserTable cssTable mt-30">
        <div class="d-flex align-items-center justify-content-between title-container flex-wrap">
          <div class="title col-lg-7 col-md-6 col-sm-12 col-12">
            <h3 class="tableheadertext">{{ automaticWeatherPage?.KingdomReports }}</h3>
          </div>
          <div class="d-flex flex-wrap align-items-center justify-content-end gap-2 col-lg-5 col-md-6 col-sm-12 col-12">
            <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="client-type-select col-lg-5 col-md-6 col-sm-12 col-12" appearance="outline">
              <mat-label>{{automaticWeatherPage?.Search}}</mat-label>
              <input type="text" matInput (keyup)="onKTypeUser($event)">
            </mat-form-field>
            <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" appearance="outline" class="client-type-select col-lg-3 col-md-6 col-sm-12 col-12">
              <mat-label>{{automaticWeatherPage?.Show}}</mat-label>
              <mat-select (valueChange)="onKChangeEntry($event)" [placeholder]="automaticWeatherPage?.Show+': 10'" floatLabel="always">
                <mat-option [value]="entry.value" *ngFor="let entry of entries; index as i">{{language == 'en' ? entry?.label : entry?.label_ar}}</mat-option>
              </mat-select>
            </mat-form-field>
            <div class="filters-like col-lg-2 col-md-6 col-sm-12 col-12">
              <ng-container *ngFor="let nav of navItems">
                <button container="body" (click)="onNavChange(nav)" [ngClass]="{'active' : activeNav === nav}" class="col-6" [ngbTooltip]="automaticWeatherPage[nav]">
                  <img [src]="'../../../../assets/view-icons/' + nav +'.svg'">
                </button>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="mb-30" style="overflow-x: auto;">
          <table class="customTable">
            <thead>
              <tr>
                <th>{{ automaticWeatherPage?.ReportTitle }}</th>
                <!-- <th>{{ automaticWeatherPage?.Createdon }}</th> -->
                <th class="cursor-pointer" (click)="getKingdomReport('sortByCreated')"> <!-- AlertStatus -->
                  <div class="d-flex align-items-center gap-2">
                    {{automaticWeatherPage?.Createdon}}
                    <div class="d-flex flex-column justify-content-center">
                      <i [ngClass]="listReportModelK.sortByCreated === 'asc' ? 'sort-active' : ''"
                        class="fas fa-sort-up sort-icon"></i>
                      <i [ngClass]="listReportModelK.sortByCreated === 'desc' ? 'sort-active' : ''"
                        class="fas fa-sort-down sort-icon"></i>
                    </div>
                  </div>
                </th>
                <th class="text-center">{{ automaticWeatherPage?.Actions }}</th>
              </tr>
            </thead>
            <tbody *ngIf="!isLoadingListAPI">
              <tr *ngFor="let list of KingdomReport?.data">
                <td>
                  {{ language == 'en' ?
                  (list.reportNameEn === 'arabian-gulf-report' || list.reportNameEn === 'red-sea-report'  ? list.reportTypeEn  : list.reportNameEn)
                  :
                  (list.reportNameEn === 'arabian-gulf-report' || list.reportNameEn === 'red-sea-report' ? list.reportTypeAr : list.reportNameAr) }}
                </td>
                <td *ngIf="language=='en'">{{list.generatedAt | date: "dd-MM-yyyy":"":"en" }}</td>
                <td *ngIf="language=='ar'">{{list.generatedAt | date: "dd-MM-yyyy":"":"ar" }}</td>
                <td class="justify-content-center">
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="">
                      <a [href]="language == 'en' ? list.reportPathEn : list.reportPathAr" target="_blank">
                        <button class="" [ngbTooltip]="automaticWeatherPage?.Pdf" tooltipClass="custom-tooltip">
                          <img src="../../../assets/mannedforecast/pdfforecast-icon.png" style="scale: 0.8;">
                        </button>
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-center no-data" *ngIf="isLoadingListAPI; else noKingdomRecordsTemplate">
          <i class="fa fa-spinner spinner--icon"></i>
        </div>
       <ng-template #noKingdomRecordsTemplate>
        <ng-container *ngIf="KingdomReport?.data?.length === 0 || !KingdomReport?.data">
          <div class="d-flex justify-content-center no-data">
            {{customNotificationPage?.NoRecordsFound}}
          </div>
        </ng-container>
       </ng-template>
        <div *ngIf="KingdomReport?.paginationVariables?.pageCount > 1 && !isLoadingListAPI" class="col-12 d-flex justify-content-center pagination dir-left mb-30">
          <ngb-pagination [maxSize]="3" [rotate]="true" [pageSize]="KpageSize" [page]="Kpage" (pageChange)="onKPageChange($event)"
            [collectionSize]="KingdomReport?.paginationVariables?.totalCount"></ngb-pagination>
        </div>
      </div>
    </ng-template>
  </div>
  <div [ngbNavOutlet]="nav"></div>
</div>

<ng-template #title let-modal>
  <div class="modal-header">
    <h3 class="modalheadtext m-0" id="modal-basic-title">{{ automaticWeatherPage?.EditDetail }}
    </h3>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="d-flex flex-wrap justify-content-start">
        <div class="detail-en">
          <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="w-100" appearance="outline">
            <mat-label>{{automaticWeatherPage?.EnterReportDetail}}</mat-label>
            <textarea [(ngModel)]="reportDetail" matInput></textarea>
          </mat-form-field>
        </div>
        <div class="detail-ar">
          <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="w-100" appearance="outline">
            <mat-label>{{automaticWeatherPage?.EnterReportDetail}}</mat-label>
            <textarea [(ngModel)]="reportDetailAR" matInput></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="previewbtn" (click)="modal.close()">
      {{ automaticWeatherPage?.Save }}
    </button>
  </div>
</ng-template>
