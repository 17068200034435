import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReportTypesModelAPI, ReportCityListModelAPI, WeatherParamsListModelAPI, GenerateReportModelAPI, PublishReportModelAPI, GenerateMannedReport, PreviewAdvanceCustomReport, PublishAdvanceCustomReport } from 'src/app/models/automatic-weather-report/automatic-weather-report.model';
import { ListReportModel } from 'src/app/models/manned-forecast/manned-forecast.model';
import { environmentBaseUrl } from 'src/environments/environmentBaseUrl';

@Injectable({
  providedIn: 'root'
})
export class AutomaticWeatherReport {

  previewdata:any;
  allChannelData:any;
  emailGroups:number[] = [];
  // advanceCustomData = {
  //   reporttypekey: "",
  //   reporttypeid: 0,
  //   selectedParams: [""],
  //   selectedIds: [""],
  //   descriptionEn: "",
  //   descriptionAr: "",
  //   titleEn: "",
  //   titleAr: "",
  //   historicalDays: "",
  //   forecastedDays: "",
  //   todayDate: "",
  //   channel: "",
  //   emailGroups: [0]
  // }
  advanceCustomData:any;
  advanceCustomPreviewData:any;
  advanceCustomId:number;
  advanceCustomStartDate:string;
  advanceCustomEndDate:string;
  reportDisclaimerEn: string;
  reportDisclaimerAr: string;
  additionalNoteEn: string;
  additionalNoteAr: string;
  publishOnPdf: boolean;
  private baseUrl = `${environmentBaseUrl.url}/api/ncm/report/`;
  constructor(private http: HttpClient) { }

  getReportTypes(reportTypesModelAPI: ReportTypesModelAPI) {
    const url = `${this.baseUrl}get-report-types`;
    return this.http.post(url, reportTypesModelAPI).toPromise();
  }

  getCityList(cityListModel: ReportCityListModelAPI){
    const url = `${this.baseUrl}get-city-list`;
    return this.http.post(url, cityListModel).toPromise();
  }

  getWeatherParamsList(weatherParamsListModelAPI: WeatherParamsListModelAPI){
    const url = `${this.baseUrl}get-weather-params-list`
    return this.http.post(url, weatherParamsListModelAPI).toPromise()
  }

  generateReport(generateReportModelAPI: GenerateReportModelAPI){
    const url = `${this.baseUrl}generate-report`
    return this.http.post(url, generateReportModelAPI).toPromise()
  }

  publishReport(publishReportModelAPI: PublishReportModelAPI){
    const url = `${this.baseUrl}update-report`
    return this.http.post(url, publishReportModelAPI).toPromise()
  }
  generateMannedReport(generateMannedReport: GenerateMannedReport){
    const url = `${this.baseUrl}generate-manned-report`
    return this.http.post(url, generateMannedReport).toPromise()
  }
  previewAdvanceCustomReport(previewAdvanceCustomReport: PreviewAdvanceCustomReport){
    const url = `${this.baseUrl}preview-advance-custom-report`
    return this.http.post(url, previewAdvanceCustomReport).toPromise()
  }
  publishAdvanceCustomReport(publishAdvanceCustomReport: PublishAdvanceCustomReport){
    const url = `${this.baseUrl}publish-advance-custom-report`
    return this.http.post(url, publishAdvanceCustomReport).toPromise()
  }

//   generatePDFReport(generateReportPdfModel: GenerateReportPdfModel){
//     const url = `${this.baseUrl}generate-report-pdf`;
//     return this.http.post(url, generateReportPdfModel).toPromise();
//   }

  getListReport(listReportModel: ListReportModel){
    const url = `${this.baseUrl}list-report`;
    return this.http.post(url, listReportModel).toPromise();
  }

//   createMannedForecastReport(mannedForecastModel: MannedForecastModel){
//     const url = `${this.baseUrl}generate-report`;
//     return this.http.post(url,mannedForecastModel).toPromise();
//   }

//   publishMannedForecastReport(updateMannedForecastModel: UpdateMannedForecastModel){
//     const headers = new HttpHeaders()
//       .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
//       .set('Accept', 'image/png');
//     const options = { headers: headers};
//     const url = `${this.baseUrl}update-report`;
//     return this.http.post(url,updateMannedForecastModel, options).toPromise();
//   }
}
