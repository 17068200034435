import { NgxSpinnerService } from 'ngx-spinner';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert-service/alert-service.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { MannedForecast } from 'src/app/services/manned-forecast/manned-forecast.service';
import { ParametersMap, UpdateMannedForecastModel } from 'src/app/models/manned-forecast/manned-forecast.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PreviewModalComponent } from '../preview-modal/preview-modal.component';
import { ReportTypesModelAPI, ReportTypesDataModel, PublishAdvanceCustomReport } from 'src/app/models/automatic-weather-report/automatic-weather-report.model';
import { AutomaticWeatherReport } from 'src/app/services/automatic-weather-report/automatic-weather-report.service';
import { GlobalService } from 'src/app/shared/utilities/global';
import { HttpErrorResponse } from '@angular/common/http';
import { WeatherDescriptionPipe } from 'src/app/pipes/weather-description.pipe';
import { WeatherDescriptionPipeAR } from 'src/app/pipes/weather-descriptionAR.pipe';

export interface PreviewData {
  city: string;
  cityEn: string;
  cityAr: string;
  lat: number;
  lon: number;
  parameters: Parameter[] | any;
}

export interface Parameter {
  parameter: string
  dates: Date[]
}

export interface Date {
  date: string
  value: number;
  nameEn?:string;
  nameAr:string;
}

@Component({
  selector: 'app-preview',
  templateUrl: './mannedpreview.component.html',
  styleUrls: ['./mannedpreview.component.scss']
})
export class MannedPreviewComponent {
  // @ViewChild("locid") locid;
  // @ViewChild("conditionId") conditionId;
  // @ViewChild("dateId") dateId;
  @ViewChild("tempMaxId") tempMaxId;
  @ViewChild("tempMinId") tempMinId;
  @ViewChild("windSpeedId") windSpeedId;
  @ViewChild("windDirectionId") windDirectionId;
  @ViewChild("windPressureId") windPressureId;
  @ViewChild("precipId") precipId;
  @ViewChild("humidityId") humidityId;
  @ViewChild("probabilityId") probabilityId;
  @ViewChild("slipperyRoadId") slipperyRoadId;
  private subscriptions: Subscription = new Subscription()
  language:string;
  mannedpreviewPage: any;
  automaticWeatherReportPage: any;

  allPreviewData: PreviewData[];
  ParametersMap = ParametersMap;

  maxtemp:any;
  mintemp:any;
  windspeed:any;
  winddirection:any;
  windpressure:any;
  precipitation:any;
  humidity:any;
  probability:any;
  slipperyroad:any;
  publisheddata:any;
  isReportPublishing: boolean = false;
  reporttypedata: ReportTypesDataModel[];
  advanceCustomData:any;
  advancePreviewData:any;
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private languageService: LanguageService,
    private translate: TranslateService,
    public mannedForecastService: MannedForecast,
    private modalService: NgbModal,
    private automaticWeatherReportservice: AutomaticWeatherReport,
    private globalService: GlobalService,
    private weatherDescriptionEnPipe: WeatherDescriptionPipe,
    private weatherDescriptionArPipe: WeatherDescriptionPipeAR,
    private alertService: AlertService,

  ) {
     this.subscriptions.add(this.languageService.currentLanguage.subscribe((language: string) => {
      this.language = language;
      this.translate.use(language);
      this.translate.get("MannedForecast").subscribe((res) => {
        this.mannedpreviewPage = res;
      });
      this.translate.get("AutomaticWeatherReport").subscribe((res) => {
        this.automaticWeatherReportPage = res;
      });
    }));
  }
  ngOnInit(): void {
    this.getreporttypes();
    if(this.mannedForecastService.previewdata.length !== 0){
      const [previewData] = this.mannedForecastService.previewdata;
      previewData.forEach((city:PreviewData)=>{
        let parameterObject = {};
        city.parameters.forEach((parameter:Parameter)=>{
          const parameterName = parameter.parameter;
          parameterObject[parameterName] = parameter.dates;
        })
        city.parameters = parameterObject;
      })
      this.allPreviewData = previewData;
    }
    else if(this.automaticWeatherReportservice.advanceCustomData !== null){
      this.advanceCustomData = this.automaticWeatherReportservice.advanceCustomData;
      const [previewData] = this.automaticWeatherReportservice.advanceCustomPreviewData;
      previewData.forEach((city:PreviewData)=>{
        let parameterObject = {};
        city.parameters.forEach((parameter:Parameter)=>{
          const parameterName = parameter.parameter;
          parameterObject[parameterName] = parameter.dates;
        })
        city.parameters = parameterObject;
      })
      this.allPreviewData = previewData;
      // console.log({advanceCustomData:this.advanceCustomData,advancePreviewData:this.allPreviewData});
      // console.log(this.mannedForecastService.previewdata);
    }
    else{
      this.router.navigateByUrl('/home/automatic-weather-report')
    }
  }
  openPreviewModal(previewData:PreviewData[],firstIndex:number,secondIndex:number){
  const weatherSymbolValue = previewData[firstIndex].parameters[ParametersMap.weathersymbol][secondIndex].value;
  const modalPreviewData = JSON.parse(JSON.stringify(previewData));
  modalPreviewData[firstIndex].parameters[ParametersMap.weathersymbol][secondIndex].value = weatherSymbolValue?.toString()
  modalPreviewData[firstIndex].parameters[ParametersMap.weathersymbol][secondIndex]['nameEn'] = this.weatherDescriptionEnPipe.transform(Number(weatherSymbolValue));
  modalPreviewData[firstIndex].parameters[ParametersMap.weathersymbol][secondIndex]['nameAr'] = this.weatherDescriptionArPipe.transform(Number(weatherSymbolValue));

   const modal = this.modalService.open(PreviewModalComponent,{
      backdrop:'static',
      centered:true,
      modalDialogClass:'modal-lg'
    });
    modal.componentInstance.previewData = modalPreviewData;
    modal.componentInstance.firstIndex = firstIndex;
    modal.componentInstance.secondIndex = secondIndex;
    modal.result.then((result:PreviewData[])=>{
      if(result){
        this.allPreviewData = result;
      }
    });
  }
  getreporttypes(){
    this.spinner.show()
    const reportTypesModel: ReportTypesModelAPI = new ReportTypesModelAPI()
    reportTypesModel.automatic = 1
    this.automaticWeatherReportservice.getReportTypes(reportTypesModel)
    .then((res:{success: boolean, data: ReportTypesDataModel[]}) => {
      this.spinner.hide();
      this.reporttypedata = res.data;
    })
    .catch((error: HttpErrorResponse) => {
      this.spinner.hide();
      this.globalService.handleError(error);
    })
    .finally(() => {
      this.spinner.hide();
    })
  }
  publishReport(){
    this.spinner.show();
    this.isReportPublishing = true;
    if(this.mannedForecastService.previewdata.length !== 0){
      const publishedData = JSON.parse(JSON.stringify(this.allPreviewData));
      publishedData.forEach((data)=>{
        const parameters = [];
        Object.entries(data.parameters).forEach(([key,value])=>{
          parameters.push({parameter:key,dates:value})
        });
        data.parameters = parameters;
      });
      this.publisheddata = publishedData;
      const updateMannedForecastModel : UpdateMannedForecastModel = new UpdateMannedForecastModel();
      updateMannedForecastModel.data = this.publisheddata;
      updateMannedForecastModel.lang = localStorage.getItem("language");
      updateMannedForecastModel.locations = this.mannedForecastService.locationslist;
      updateMannedForecastModel.start_date = this.mannedForecastService.start_date;
      updateMannedForecastModel.end_date = this.mannedForecastService.end_date;
      updateMannedForecastModel.report_title = this.mannedForecastService.report_title;
      updateMannedForecastModel.report_title_ar = this.mannedForecastService.report_title_ar;
      updateMannedForecastModel.is_governate = this.mannedForecastService.is_governate;
      updateMannedForecastModel.show_public_description = this.mannedForecastService.show_public_description;
      updateMannedForecastModel.reportDisclaimerEn = this.mannedForecastService.reportDisclaimerEn;
      updateMannedForecastModel.reportDisclaimerAr = this.mannedForecastService.reportDisclaimerAr;
      updateMannedForecastModel.additionalNoteEn = this.mannedForecastService.additionalNoteEn;
      updateMannedForecastModel.additionalNoteAr = this.mannedForecastService.additionalNoteAr;
      let reportid;
      this.reporttypedata.forEach((types) => {
        if(types.key == '10-day-forecast-report'){
          reportid = types.id;
        }
      })
      updateMannedForecastModel.report_type_id = reportid;
      if(this.mannedForecastService.report_id !== null){
        updateMannedForecastModel.report_id = this.mannedForecastService.report_id
      }
      this.mannedForecastService.publishMannedForecastReport(updateMannedForecastModel).then(
        (res: any) => {
          this.spinner.hide();
          this.isReportPublishing = false;
          this.mannedForecastService.report_id = null
          if(res.success){
            this.toast.success(res.message);
            this.mannedForecastService.previewdata = null;
            this.goToMannedForecast();
          }
          else{
            this.toast.error('Report Preview Not Available. Please try later.');
          }
        }
      ).catch((err) => {
        this.spinner.hide();
        this.isReportPublishing = false;
        this.globalService.handleError(err);
      })
      .finally(()=>{
        this.spinner.hide();
        this.isReportPublishing = false;
      });
    }
    else if(this.automaticWeatherReportservice.advanceCustomData !== null){
      const publishedData = JSON.parse(JSON.stringify(this.allPreviewData));
      publishedData.forEach((data)=>{
        const parameters = [];
        Object.entries(data.parameters).forEach(([key,value])=>{
          parameters.push({parameter:key,dates:value})
        });
        data.parameters = parameters;
      });
      this.publisheddata = publishedData;
      const publishAdvanceCustomReport: PublishAdvanceCustomReport = new PublishAdvanceCustomReport();
      publishAdvanceCustomReport.titleEn = this.advanceCustomData.titleEn;
      publishAdvanceCustomReport.titleAr = this.advanceCustomData.titleAr;
      publishAdvanceCustomReport.reportDisclaimerEn = this.advanceCustomData.descriptionEn;
      publishAdvanceCustomReport.reportDisclaimerAr = this.advanceCustomData.descriptionAr;
      publishAdvanceCustomReport.additionalNoteEn = this.advanceCustomData.additionalNoteEn;
      publishAdvanceCustomReport.additionalNoteAr = this.advanceCustomData.additionalNoteAr;
      publishAdvanceCustomReport.data = this.publisheddata;
      publishAdvanceCustomReport.lang = this.language;
      publishAdvanceCustomReport.report_type_id = this.advanceCustomData.reporttypeid;
      if(this.automaticWeatherReportservice.advanceCustomId !== null){
        publishAdvanceCustomReport.report_id = this.automaticWeatherReportservice.advanceCustomId;
        publishAdvanceCustomReport.start_date = this.automaticWeatherReportservice.advanceCustomStartDate;
        publishAdvanceCustomReport.end_date = this.automaticWeatherReportservice.advanceCustomEndDate;
      }
      if(this.advanceCustomData.historicalDays){
        publishAdvanceCustomReport.start_date = this.advanceCustomData.historicalDate;
        publishAdvanceCustomReport.end_date = this.advanceCustomData.todayDate;
      }
      if(this.advanceCustomData.forecastedDays){
        publishAdvanceCustomReport.start_date = this.advanceCustomData.todayDate;
        publishAdvanceCustomReport.end_date = this.advanceCustomData.forecastedDate;
      }
      let organizationsArray: number[] = [];
      let emails: string[] = [];
      const mychanneldata = this.advanceCustomData.channel;
      const channels = [];
      if(mychanneldata != undefined){
        if(mychanneldata.addEmails){
          channels.push("email");
        }
        if(mychanneldata.ajwaaPortal){
          channels.push("ajwaa_portal");
        }
        if(mychanneldata.publicPortal){
          channels.push("public_portal");
        }
        if(mychanneldata.twitter){
          channels.push("twitter");
        }
        if(mychanneldata.selectOrganizations){
          mychanneldata.selectOrganizationsArray.forEach(element => {
            organizationsArray.push(element);
          })
        }
        else{
          organizationsArray = [0];
        }
        mychanneldata.count.forEach(element => {
          emails.push(element.email)
        });
        publishAdvanceCustomReport.emails = emails;
        publishAdvanceCustomReport.organizations = organizationsArray;
        if(this.advanceCustomData.channel?.addEmailGroup){
          publishAdvanceCustomReport.user_group_ids = this.advanceCustomData.channel.emailGroups
        } else {
          publishAdvanceCustomReport.channels = channels;
        }
        if(mychanneldata.sendEmail){
          publishAdvanceCustomReport.send_email = 1;
        }
      }
      this.automaticWeatherReportservice.publishAdvanceCustomReport(publishAdvanceCustomReport).then(
        (res: any) => {
          this.spinner.hide();
          this.isReportPublishing = false;
          if(res.success){
            this.toast.success(res.message);
            this.automaticWeatherReportservice.advanceCustomData = null;
            this.goToMannedForecast();
          }
          else{
            this.toast.error('Report Preview Not Available. Please try later.');
          }
        }
      ).catch((err) => {
        this.spinner.hide();
        this.isReportPublishing = false;
        this.globalService.handleError(err);
      })
      .finally(()=>{
        this.spinner.hide();
        this.isReportPublishing = false;
      })
    }
  }
  goToMannedForecast() {
    this.router.navigateByUrl('/home/automatic-weather-report')
  }
  Numberify(value: string | number){
    return Number(value)
  }
}
