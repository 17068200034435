import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users/users.service';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/auth/service/authentication.service';
import { UserProfileModel } from 'src/app/models/user-profile/user-profile.model';
import { ChangePasswordModel } from 'src/app/models/change-password/change-password.model';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language/language.service';
import { GlobalService, UserInfo } from 'src/app/shared/utilities/global';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

interface userNOW {
  id: number
  name: string
  isTwoFactorAuth: boolean
  email: string
  department: string
  title: string
  role: string
  organization: string
  organizationId: number
  apiGroups: any[]
  iqamaId: string
  phone: string
  password: string
}
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  language:string;
  profilePage:any;

  user: userNOW;
  password: string;
  isChangePassword: boolean = false;
  userProfileForm: FormGroup
  profile: any;

  isDivVisible: boolean = false;

  changePasswordForm: FormGroup;
  submitted: boolean;
  isConfirmNewPasswordMatched: boolean = false;
  currentPassword: string;

  isEditProfile: boolean = false;
  passwordStrengthMessage: string = '';
  passwordStrengthClass: string = '';
  isPasswordValid: boolean = false;
  constructor (
    private spinner: NgxSpinnerService,
    private globalService: GlobalService,
    private fb: FormBuilder,
    private userService: UsersService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private languageService:LanguageService,
    private toast: ToastrService,
  ){
    this.subscriptions.add(this.languageService.currentLanguage.subscribe((language: string) => {
      this.language = language;
      this.translate.use(language);
      this.translate.get("Profile").subscribe((res) => {
        this.profilePage = res;
      });
    }));
  }

  ngOnInit() {
    this.getUserDetails();
    this.getUserPassword();
    // this.userProfileForm = this.fb.group({
    //   name: [this.user.name || '', [Validators.required, Validators.maxLength(20)]],
    //   iqama: [this.user.iqamaId ? Number(this.user.iqamaId) : null, [Validators.maxLength(10),Validators.minLength(10),Validators.pattern('^[0-9]*$')]],
    //   phoneno: [this.user.phoneno ? Number(this.user.phoneno) : null, [Validators.maxLength(9),Validators.minLength(9),Validators.pattern('^[0-9]*$')]],
    //   department: [this.user.department || '', [Validators.required, Validators.maxLength(20)]],
    //   password: [this.user.password || '', [Validators.maxLength(20)]],
    //   title: [this.user.title || '', [Validators.maxLength(20)]],
    //   isTwoFactorAuth: [this.user.isTwoFactorAuth || false, Validators.required],
    // });
    this.changePasswordForm = this.fb.group({
      oldPassword: [null, Validators.required],
      newPassword: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          // Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/),
        ],
      ],
      confirmNewPassword: [null, Validators.required],
    });
  }
  checkPasswordStrength() {
    const password = this.changePasswordForm.controls['newPassword'].value;

    // Reset strength messages.
    this.passwordStrengthMessage = '';
    this.passwordStrengthClass = '';

    if (!password) return;

    // Basic strength rules.
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*]/.test(password);
    const isLongEnough = password.length >= 8;
    const hasNoSequentialChars = !/(.)\1{2,}/.test(password); // No 3+ repeated characters.
    const hasNoSequentialNumbers = !/(012|123|234|345|456|567|678|789|12345|67890|112233|98765|000000|111111|222222|123456789|555555|123123|abcdef|ghijkl|asdfgh|zxcvbn|qwertyuiop|qazwsx|poiuyt|abc123|abcabc|1q2w3e4r|1qaz2wsx|qweasd|asdfghjkl|zxcvbnm|123qwe|123asd|789qwe|987poi|qazwsx|aaaaaa|bbbbbb|cccccc|333333|444444|666666|777777|888888|999999)/.test(password); // No sequential numbers.
    if(!hasUpperCase){
      this.passwordStrengthMessage = this.language === 'en' ? 'Weak' : 'ضعيف';
      this.passwordStrengthClass = 'text-danger';
      return;
    }
    if(!hasLowerCase){
      this.passwordStrengthMessage = this.language === 'en' ? 'Weak' : 'ضعيف';
      this.passwordStrengthClass = 'text-danger';
      return;
    }
    if(!hasNumber){
      this.passwordStrengthMessage = this.language === 'en' ? 'Weak' : 'ضعيف';
      this.passwordStrengthClass = 'text-danger';
      return;
    }
    if(!hasSpecialChar){
      this.passwordStrengthMessage = this.language === 'en' ? 'Weak' : 'ضعيف';
      this.passwordStrengthClass = 'text-danger';
      return;
    }
    if(!isLongEnough){
      this.passwordStrengthMessage = this.language === 'en' ? 'Weak' : 'ضعيف';
      this.passwordStrengthClass = 'text-danger';
      return;
    }
    if (!hasNoSequentialNumbers) {
      this.passwordStrengthMessage = this.language === 'en' ? 'Weak' : 'ضعيف';
      this.passwordStrengthClass = 'text-danger';
      return;
    }
    let strengthScore = 0;

    // Calculate password strength score.
    if (hasUpperCase) strengthScore++;
    if (hasLowerCase) strengthScore++;
    if (hasNumber) strengthScore++;
    if (hasSpecialChar) strengthScore++;
    if (isLongEnough) strengthScore++;
    if (hasNoSequentialChars) strengthScore++;
    if (hasNoSequentialNumbers) strengthScore++;

    // Display password strength.
    if (strengthScore <= 2) {
      this.passwordStrengthMessage = this.language === 'en' ? 'Very Weak' : 'ضعيف جداً';
      this.passwordStrengthClass = 'text-danger';
    } else if (strengthScore <= 4) {
      this.passwordStrengthMessage = this.language === 'en' ? 'Weak' : 'ضعيف';
      this.passwordStrengthClass = 'text-danger';
    } else if (strengthScore <= 5) {
      this.passwordStrengthMessage = this.language === 'en' ? 'Moderate' : 'معتدل';
      this.passwordStrengthClass = 'text-info';
    } else if (strengthScore === 6) {
      this.passwordStrengthMessage = this.language === 'en' ? 'Strong' : 'قوي';
      this.passwordStrengthClass = 'text-success';
    } else if (strengthScore === 7) {
      this.passwordStrengthMessage = this.language === 'en' ? 'Very Strong' : 'قوي جداً';
      this.passwordStrengthClass = 'text-success';
    }
    // debugger
  }
  onKeyPress(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
    const numericKeys = Array.from({ length: 10 }, (_, i) => i.toString());
    const allowed = allowedKeys.concat(numericKeys);

    if (!allowed.includes(event.key)) {
      event.preventDefault();
    }
  }

  // onInput(event: Event) {
  //   const input = (event.target as HTMLInputElement).value;

  //   // Set the error for invalid start character
  //   if (input && !['2', '3'].includes(input[0])) {
  //     this.userProfileForm.get('iqama')?.setErrors({ invalidStart: true });
  //   } else {
  //     const errors = this.userProfileForm.get('iqama')?.errors;
  //     if (errors) {
  //       delete errors['invalidStart'];
  //       if (Object.keys(errors).length === 0) {
  //         this.userProfileForm.get('iqama')?.setErrors(null);
  //       } else {
  //         this.userProfileForm.get('iqama')?.setErrors(errors);
  //       }
  //     }
  //   }
  // }
  onInput(event: Event) {
    const input = (event.target as HTMLInputElement).value;

    // Check if the input starts with a digit from 1 to 9
    if (input && !/^[1-3]/.test(input)) {
        this.userProfileForm.get('iqama')?.setErrors({ invalidStart: true });
    } else {
        const iqamaControl = this.userProfileForm.get('iqama');
        if (iqamaControl) {
            const errors = iqamaControl.errors || {};
            delete errors['invalidStart'];

            if (Object.keys(errors).length === 0) {
                iqamaControl.setErrors(null);
            } else {
                iqamaControl.setErrors(errors);
            }
        }
    }
}

  onKeyPressPhone(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
    const numericKeys = Array.from({ length: 10 }, (_, i) => i.toString());
    const allowed = allowedKeys.concat(numericKeys);

    if (!allowed.includes(event.key)) {
      event.preventDefault();
    }
  }

  // onInputPhone(event: Event) {
  //   const input = (event.target as HTMLInputElement).value;

  //   const errors = this.userProfileForm.get('phoneno')?.errors;
  //   if (errors) {
  //     if (Object.keys(errors).length === 0) {
  //       this.userProfileForm.get('phoneno')?.setErrors(null);
  //     } else {
  //       this.userProfileForm.get('phoneno')?.setErrors(errors);
  //     }
  //   }
  // }

  onInputPhone(event: Event) {
    const input = (event.target as HTMLInputElement).value;

    // Ensure the first character is not 0
    if (input.length > 0 && input[0] === '0') {
      this.userProfileForm.get('phoneno')?.setErrors({ invalidStart: true });
    } else {
      const errors = this.userProfileForm.get('phoneno')?.errors;
      if (errors) {
        delete errors['invalidStart'];
        if (Object.keys(errors).length === 0) {
          this.userProfileForm.get('phoneno')?.setErrors(null);
        } else {
          this.userProfileForm.get('phoneno')?.setErrors(errors);
        }
      }
    }
  }

  resetform(phoneWithoutPrefix: string = ''){
    this.isEditProfile = false;
    this.userProfileForm = this.fb.group({
      name: [this.user.name || '', [Validators.required, Validators.maxLength(20)]],
      iqama: [this.user.iqamaId ? Number(this.user.iqamaId) : null, [Validators.required,Validators.maxLength(10),Validators.minLength(10),Validators.pattern('^[0-9]*$')]],
      phoneno: [phoneWithoutPrefix, [Validators.required,Validators.maxLength(9),Validators.minLength(9),Validators.pattern('^[0-9]*$')]],
      department: [this.user.department || '', [Validators.required, Validators.maxLength(20)]],
      password: [this.user.password || '', [Validators.maxLength(20)]],
      title: [this.user.title || '', [Validators.maxLength(20)]],
      isTwoFactorAuth: [this.user.isTwoFactorAuth || false, Validators.required],
    });
  }

  getUserDetails() {
    this.user = null
    this.user = JSON.parse(localStorage.getItem('user'));
    const phoneWithoutPrefix = this.user.phone?.startsWith('+966') ? this.user.phone.substring(4) : this.user.phone || '';
    this.resetform(phoneWithoutPrefix);
  }

  getUserPassword() {
    this.password = this.globalService.getUserPassword();
  }

  resetUserDetails() {
    this.getUserDetails();
    this.globalService.showLoader();
    this.userProfileForm.controls['name'].setValue(this.user.name);
    this.userProfileForm.controls['iqama'].setValue(Number(this.user.iqamaId));
    this.userProfileForm.controls['department'].setValue(this.user.department);
    this.userProfileForm.controls['title'].setValue(this.user.title);
    this.userProfileForm.controls['isTwoFactorAuth'].setValue(this.user.isTwoFactorAuth);
    this.userProfileForm.markAsPristine();
    this.globalService.hideLoader();
  }

  getUpdatedUserDetails() {
    this.globalService.showLoader();
    let data = {
      Authorization: this.globalService.getToken(),
    };
    this.authenticationService.getUserDetails().toPromise().then(
      (res: any) => {
        if (res.success) {
          localStorage.setItem("user", JSON.stringify(res.data));
          this.resetUserDetails();
        }
        this.globalService.hideLoader();
      },
      (err: HttpErrorResponse) => {
        this.globalService.hideLoader();
        this.toast.error(err.error);
      }
    );
  }

  onSave() {
    this.submitted = true;
    if (this.userProfileForm.valid) {
      const userProfileModel: UserProfileModel = new UserProfileModel();
      userProfileModel.id = this.user.id;
      userProfileModel.name = this.userProfileForm.controls['name'].value;
      userProfileModel.iqamaId = this.userProfileForm.controls['iqama'].value.toString();
      userProfileModel.phoneno = this.userProfileForm.controls['phoneno'].value.toString();
      userProfileModel.department = this.userProfileForm.controls['department'].value;
      userProfileModel.title = this.userProfileForm.controls['title'].value;
      userProfileModel.isTwoFactorAuth = this.userProfileForm.controls['isTwoFactorAuth'].value;
      this.globalService.showLoader();
      this.userService.updateUserProfile(userProfileModel).then(
      (res: {success:string; message:string}) => {
        this.globalService.hideLoader();
        if (res.success) {
          this.toast.success(res.message);
          this.resetform();
          this.getUpdatedUserDetails();
          this.getUsers()
        }
        else {
          this.toast.error(res.message);
        }
      }).catch((err: HttpErrorResponse)=>{
        this.globalService.hideLoader();
        this.globalService.handleError(err);
      })
    }
  }

  getUsers() {
    this.spinner.show();
    this.authenticationService.getUserDetails().toPromise()
    .then((res:{success:boolean; message:string;data:UserInfo}) => {
        this.spinner.hide();
        if (res.success) {
          localStorage.setItem("user", JSON.stringify(res.data));
          this.globalService.userSource.next(res?.data);
          this.getUserDetails();
          this.getUserPassword();
        }
        else{
          this.toast.error(res.message);
        }
      },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        this.toast.error(err.error.message);
      }
    );
  }

  confirmNewPasswordValidator(){
    if(!this.changePasswordForm.value.newPassword){
      this.isConfirmNewPasswordMatched = false;
      return;
    }

    if(
      this.changePasswordForm.value.newPassword === this.changePasswordForm.value.confirmNewPassword
    ){
      this.isConfirmNewPasswordMatched = true;
    }
    else{
      this.isConfirmNewPasswordMatched = false;
    }
  }
  showPassword(event:any, id){
    if(event.target.classList.contains('fa-eye')){
      event.target.classList.replace('fa-eye', 'fa-eye-slash');
      document.getElementById(id).setAttribute('type', 'password');
    }
    else{
      event.target.classList.replace('fa-eye-slash', 'fa-eye');
      document.getElementById(id).setAttribute('type', 'text');
    }
  }

  onSubmit(){
    this.submitted = true;
    if (this.passwordStrengthClass === 'text-danger') {
      this.toast.error(this.language === 'en' ? 'Password strength is too weak!' : 'كلمة المرور ضعيفة جداً!');
      return;
    }
    if(this.changePasswordForm.invalid) return;
    // if(!this.isConfirmNewPasswordMatched) return;

    this.globalService.showLoader();

    const changePasswordModel: ChangePasswordModel = new ChangePasswordModel();
    changePasswordModel.oldPassword = this.changePasswordForm.value.oldPassword;
    changePasswordModel.newPassword = this.changePasswordForm.value.newPassword;
    changePasswordModel.confirmNewPassword = this.changePasswordForm.value.confirmNewPassword;

    this.userService.changePassword(changePasswordModel).then(
      (res:any)=>{
      this.globalService.hideLoader();
        if(res.success){
          this.globalService.setUserPassword(changePasswordModel.newPassword);
          this.isDivVisible = false;
          this.isEditProfile = false;
          this.toast.success(res.message);
          this.router.navigateByUrl('auth/login');
        }
        else{
          this.toast.error(res.message);
        }

      }).catch((err)=>{
        this.globalService.hideLoader();
        this.globalService.handleError(err);
      })

  }

  show() {
    this.isDivVisible = true;
  }

  cancel() {
    this.isDivVisible = false;
  }

  showPasswords: boolean = false;
  oldPasswords: boolean = false;
  newPasswords: boolean = false;
  confirmPasswords: boolean = false;
  toggleShowPasswordsVisibility(): void {
    this.showPasswords = !this.showPasswords;
  }
  toggleOldPasswordsVisibility(): void {
    this.oldPasswords = !this.oldPasswords;
  }
  toggleNewPasswordsVisibility(): void {
    this.newPasswords = !this.newPasswords;
  }
  toggleConfirmPasswordsVisibility(): void {
    this.confirmPasswords = !this.confirmPasswords;
  }

  conformNewPasswordValidator(){
    if(!this.changePasswordForm.controls['newPassword'].value){
      this.isConfirmNewPasswordMatched = false;
      return;
    }

    if(
      this.changePasswordForm.controls['newPassword'].value === this.changePasswordForm.controls['confirmNewPassword'].value
    ){
      this.isConfirmNewPasswordMatched = true;
    }
    else{
      this.isConfirmNewPasswordMatched = false;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  openChangePasswordModal(){
    this.isDivVisible = true;
  }
}
