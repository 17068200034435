import { PaginationVariables } from "../pagination/pagination.model";

export class MannedForecastModel {
  from_date: any;
  to_date: any;
  hours: any;
  // parameters: string[] = [];
  parameters_12h: string[] = [];
  parameters_24h: string[] = [];
  model: any;
  locations: any[]=[];
  Authorization: string;
  report_type_id?: string;
  is_governate?: boolean;

  constructor(){}
}



export const ParametersMap = {
  weathersymbol:'weather_symbol_24h:idx',
  temperature:['t_max_2m_24h:C','t_min_2m_24h:C'],
  humidity:"relative_humidity_2m:p",
  windspeed:'wind_speed_10m:kmh',
  winddirection:"wind_dir_10m:d",
  windpressure:"sfc_pressure:hPa",
  precipitation:"precip_24h:mm",
  probability:"prob_precip_24h:p",
  slipperyroad:"is_slippery_road_24h:idx",
  // dust:"dust_0p03um_0p55um:ugm3",
  tmax:'t_max_2m_24h:C',
  tmin:'t_min_2m_24h:C'
}
export const ArabianParametersMap = {
  weathersymbol:'weather_symbol_12h:idx',
  temperature:['t_max_2m_24h:C','t_min_2m_24h:C'],
  waveheight: 'max_individual_wave_height:m',
  visibility: 'visibility:km',
  humidity:"relative_humidity_2m:p",
  hightide:['first_high_tide:sql','second_high_tide:sql'],
  lowtide:['first_low_tide:sql','second_low_tide:sql'],
  windspeed:'wind_speed_10m:kmh',
  winddirection:"wind_dir_10m:d",
  tmax:'t_max_2m_24h:C',
  tmin:'t_min_2m_24h:C',
  firsthightide: 'first_high_tide:sql',
  secondhightide: 'second_high_tide:sql',
  firstlowtide: 'first_low_tide:sql',
  secondlowtide: 'second_low_tide:sql'
}
export const RedSeaParametersMap = {
  weathersymbol:'weather_symbol_12h:idx',
  temperature:['t_max_2m_24h:C','t_min_2m_24h:C'],
  waveheight: 'max_individual_wave_height:m',
  visibility: 'visibility:km',
  humidity:"relative_humidity_2m:p",
  hightide:['first_high_tide:sql','second_high_tide:sql'],
  lowtide:['first_low_tide:sql','second_low_tide:sql'],
  windspeed:'wind_speed_10m:kmh',
  winddirection:"wind_dir_10m:d",
  tmax:'t_max_2m_24h:C',
  tmin:'t_min_2m_24h:C',
  firsthightide: 'first_high_tide:sql',
  secondhightide: 'second_high_tide:sql',
  firstlowtide: 'first_low_tide:sql',
  secondlowtide: 'second_low_tide:sql'
}
export const CustomReportParametersMap = {
  weathersymbol:'weather_symbol_12h:idx',
  temperature:['t_max_2m_24h:C','t_min_2m_24h:C'],
  humidity:"relative_humidity_2m:p",
  windspeed:'wind_speed_10m:kmh',
  winddirection:"wind_dir_10m:d",
  pressure:"sfc_pressure:hPa",
  precipitation:"precip_12h:mm",
  probability:"prob_precip_12h:p",
  slipperyroad:"is_slippery_road_12h:idx",
  tmax:'t_max_2m_24h:C',
  tmin:'t_min_2m_24h:C'
}
export const MashaerReportParametersMap = {
  weathersymbol:'weather_symbol_12h:idx',
  temperature:['t_max_2m_24h:C','t_min_2m_24h:C'],
  humidity:"relative_humidity_2m:p",
  windspeed:'wind_speed_10m:kmh',
  winddirection:"wind_dir_10m:d",
  pressure:"sfc_pressure:hPa",
  precipitation:"precip_12h:mm",
  probability:"prob_precip_12h:p",
  tmax:'t_max_2m_24h:C',
  tmin:'t_min_2m_24h:C'
}


export const autoParamets = {
  't_max_2m_24h:C': {name_en:'Temperature Max' ,name_ar:'bkafakjdfa Max' ,image:'T.../adasdasfkhsafhvadv' ,}
}
// export const PARAMETERS = [
//   {value:'temperature', key:['t_max_2m_24h:C','t_min_2m_24h:C', 'weather_symbol_24h:idx'] },
//   {value:'windspeed', key:'wind_speed_10m:kmh' },
//   {value:'winddirection', key:'wind_dir_10m:d' },
//   {value:'windpressure', key:'sfc_pressure:hPa' },
//   {value:'precipitation', key:'precip_24h:mm' },
//   {value:'humidity', key:'relative_humidity_2m:p' },
//   {value:'probability', key:'prob_precip_24h:p' },
//   {value:'slipperyroad', key:'is_slippery_road_24h:idx' },
//   {value:'dust', key:'dust_0p03um_0p55um:ugm3' }
// ]


// windspeed: false,
// winddirection: false,
// windpressure: false,
// precipitation: false,
// humidity: true,
// probability: false,
// slipperyroad: false,
// dust: false
export class UpdateMannedForecastModel {
  data: {
  };
  locations?:number[];
  start_date:string;
  end_date:string;
  lang:string;
  report_title: string = '';
  report_title_ar: string = '';
  report_type_id: number;
  report_id?: number;
  is_governate?: boolean;
  show_public_description?: boolean;
  reportDisclaimerEn?: string = '';
  reportDisclaimerAr?: string = '';
  additionalNoteEn?: string = '';
  additionalNoteAr?: string = '';
}

export class ListReportModelres {
  data:ListReportModeldata[];
  paginationVariables:PaginationVariables;
  success:boolean;
}

export class ListReportModeldata {
  id: number
  createdBy: string
  email: string
  createdOn: string
  report: any
  reportTypeEn: any
  reportTypeAr: any
  reportTitleEn: any
  reportTitleAr?: string
  stateOfTheSeaEn: any
  stateOfTheSeaAr?: string
  surfaceWindEn: any
  surfaceWindAr?: string
  waveHeightEn: any
  waveHeightAr?: string
}


export class ListReportModel {
  page: any;
  limit: any;
  search?:string;
  // email: any;
  // createdBy:any;
  // Authorization: string;
  report_type?:string;
  sortByCreated?:string;
}

export class GenerateReportPdfModel {
  id: any;
  Authorization: string;
  lang: string;
}
export class GenerateAutomaticWeatherReportPdf {
  id: number;
  lang: string;
}

export class CityListModel {
  Authorization: string;
  report_type_id: number;
}
export class GetAutomaticWeatherReportsListingModel {
  page:number;
  limit:number;
  lang:string;
  report_type_id:number;
  orderKey?:string;
  order?:string;
  search?:string;
  constructor(){
    this.page = 1;
    this.limit = 10;
    this.report_type_id = 9107;
  }
}
export class DeleteReportModel {
  Authorization: string;
  report_id: string;
}
