import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';

export const PASSWORD_PLACEHOLDER = "●●●●●●●●";

@Directive({
  selector: '[appInputPasswordPlaceholder]'
})
export class InputPasswordPlaceholderDirective implements OnInit  {

  /**
   * Sets default placeholder for password if the field is empty.
   */
  @Input() setPasswordPlaceholder = true;

  destroyed = new Subject();

  constructor(
    private control: NgControl
  ) { 
  }

  ngOnInit(): void {
    this.checkIfPlaceholderShouldShow();
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  @HostListener('focus') focusedIn() {
    if (this.control.value === PASSWORD_PLACEHOLDER) {
      this.control.control.patchValue("", { onlySelf: true, emitEvent: false });
    }
  }

  @HostListener('focusout') focusedOut() {
    this.checkIfPlaceholderShouldShow();
  }

  checkIfPlaceholderShouldShow() {
    // Check if we want to set a password placeholder and if the value is empty
    if (this.setPasswordPlaceholder && (this.control.value === "" || this.control.value === null)) {
      this.control.control.patchValue(PASSWORD_PLACEHOLDER, { onlySelf: true, emitEvent: false });
    }
  }
}
