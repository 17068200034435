import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ClientModel, UpdateAdminPackageModel, CreateClientExcelDataModel, CreateClientExcelModel } from 'src/app/models/client/client.model';
import { ClientService } from 'src/app/services/client/client.service';
import { LocationsService } from 'src/app/services/locations/locations.service';
import { UsersService } from 'src/app/services/users/users.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from "ngx-toastr";
import { Subject, Subscription, from, merge } from 'rxjs';
import { LanguageService } from 'src/app/services/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from 'src/app/shared/utilities/global';
import { PackageService } from 'src/app/services/package/package.service';
import { GetListPackageModel, ListPackagesResponseModel, SuspendPackageModel, ResumePackageModel, RenewPackageModel, getListWSOModel, CreateWSOUser, getListWSOModelUser } from 'src/app/models/package/package.model';
import { NCMClientModelAPI, UserExcelModelAPI, UsersModel } from 'src/app/models/users/users.model';
import { PaginationVariables } from 'src/app/models/pagination/pagination.model';
import { ResendInviteUserModel, ResendInviteWSOuserModel } from 'src/app/models/change-password/change-password.model';
import { EMPTY } from 'rxjs';
import { concatMap, debounceTime, delay, catchError } from 'rxjs/operators';
import { set } from 'date-fns';
import { ListAPIData } from '../packages/packages.component';
import * as XLSX from 'xlsx';
import { DEBOUNCE_TIME_FOR_INPUT_IN_MS } from 'src/app/shared/constants';
import { PASSWORD_PLACEHOLDER } from 'src/app/shared/directives/input-password-placeholder.directive';
export class UserResponseModel {
  data: UserList[];
  paginationVariables: PaginationVariables;
  success: boolean;
  message: string;
}
export class listOrganizationResponseModel {
  success: boolean;
  data: listOrganization[];
  paginationVariables: PaginationVariables;
  message: string;
  constructor() {
    this.data = [];
  }
}
export class listOrganization {
  id: number
  code: string
  organizationType: string
  nameEn: string
  nameAr: string
  email: string
  city: any
  state: any
  zip: any
  country: any
}
export interface UserList {
  id: number
  name: string
  title?: string
  phone?: string
  iqamaId?: string
  email: string
  department?: string
  role: string
  company_name_en: string
  company_name_ar: string
  packageData: any[]
  organizationId: number
  location: number[]
  allowCustomNotification: boolean
  allowAddLocation: boolean
  allowForecast: boolean
  allowAlertHistoryForCustomAlerts: boolean
  automotive: boolean
  aviation: boolean
  shippingAndOffshore: boolean
  insurance: boolean
  energy: boolean
  client_type: ClientTypeModel
  status: string
  token: string
  createdAt: string;
  createdBy: string;
  entity_status: string;
  entity_status_en: string;
  entity_status_ar: string;
  trialLeftDays: number;
}
interface ClientTypeModel{
  key: number
  name_en: string
  name_ar: string
}
interface LocationList {
  id: number
  name: string
  title: string
  coordinates: number[][]
  severeWeatherAlert: boolean
  customNotificationAlert: boolean
}
interface HTMLElementTagNameMap {
  tr: HTMLTableRowElement;
}

interface WSOusersData{
  id: number
  user_id: number
  user_name: string
  user_email: string
  package_id: number
  package_name: string
  package_name_ar?: string
  createdBy: string
  createdOn: string
  subscription_start_date: string
  subscription_end_date: string
  subscription_status: boolean
  subscription_type: string
}

@Component({
  selector: 'app-alert-editor',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss']
})
export class AddClientComponent implements OnInit {
  private searchInputChange = new Subject<string>();
  private searchInputChangeAPI = new Subject<string>();
  private searchInputChangeAPIFilter = new Subject<string>();
  private pckgInputChange = new Subject<string>();
  private emailInputChangeAPI = new Subject<string>();
  private emailInputChange = new Subject<string>();
  private orgInputChange = new Subject<string>();
  private subscription: Subscription = new Subscription();
  private filterorganizationnameControl = new Subject<string>();
  private filterorganizationnameControlAuto = new Subject<string>();
  private filterorganizationnameControlBulk = new Subject<string>();
  private filterorganizationnameControlExcel = new Subject<string>();
  private searchFilterOrganizationNameInputChange = new Subject<string>();
  searchFilterOrganizationName: string;
  previousSearchFilterOrganizationName = '';
  private filterPckgControl = new Subject<string>();
  isDivVisible: boolean = false;

  AddClient: boolean = false;
  AddWSO: boolean = false;
  AddBulk: boolean = false;
  AddManualUser: boolean = false;
  clientForm: FormGroup;
  userDetails: any;
  locations: LocationList[];
  language: string;
  users: UserResponseModel = new UserResponseModel();
  organizationUsers: listOrganizationResponseModel = new listOrganizationResponseModel();
  bulkOrganizationUsers: listOrganizationResponseModel = new listOrganizationResponseModel();
  isEditUser: boolean;
  selectedFile: File;
  submitted: boolean = false;
  deleteLocationData: number;
  clientsPage: any;
  usersPage: any;
  permissions: any;
  pageSize: number = 10;
  packagePageNumber: number = 1;
  packageFilterPageNumber: number = 1;
  page: number = 1;
  listPackages: ListPackagesResponseModel = new ListPackagesResponseModel();
  listPackagesByFilter: ListPackagesResponseModel = new ListPackagesResponseModel();
  filterClientType: string;
  filterEmail: string;
  filterOrg: any;
  filterPckg: any;
  filterWSOusers: number;
  type: string;
  currentPageForOrganizationUsers: number = 1;
  currentPageForBulkOrganizationUsers = 1;
  isRenewVisible: boolean = false;
  updateEntity: boolean = false;
  updateEntityId: number;
  updateEntityStatus: string;
  trialLimit: number;
  renewPackageId: number;
  renewMonthNumber: number;
  optionsArray: number[] = Array.from({ length: 100 }, (_, i) => i + 1);
  DaysArray: number[] = Array.from({ length: 60 }, (_, i) => i + 1);

  bulkForm: FormGroup;
  WSOForm: FormGroup;
  private destroy$ = new Subject<void>();
  responsedata: any;

  isResendInvite: boolean = false;
  resendInviteId: number;
  isSuspended: boolean = false;
  suspendedId: number;
  isResume: boolean = false;
  resumeId: number;

  manualClientForm: FormGroup;
  searchByOrganization: any;
  searchByPackage: any;


  searchFilterPackageName: string
  ncmClientModelAPI: NCMClientModelAPI = new NCMClientModelAPI();
  sortarray: string = '';
  sortByCreated: boolean;
  sortByStatus: boolean;
  filterSearch: string;

  isFilter: boolean = false;
  PkgSearch: string;

  getListAPIModel: getListWSOModel = new getListWSOModel();
  pageAPI: number = 1;
  listWSOPackages: { success: boolean; data: ListAPIData[]; paginationVariables: PaginationVariables; };
  filterSearchAPI: string;

  navItems: string[] = ['Clients', 'APIClients'];
  activeNav: string = this.navItems[0];
  getListWSOUser: getListWSOModelUser = new getListWSOModelUser();
  listWSOUsers: { success: boolean; data: WSOusersData[]; paginationVariables: PaginationVariables; };
  pageWSO: number = 1;
  APIFilterPageNumber: number = 1;
  listWSOPackagesByFilter: { success: boolean; data: ListAPIData[]; paginationVariables: PaginationVariables; };
  filterSearchAPIFilter: string;
  filterEmailAPI: string;
  isWSOinvite: boolean;

  pkgSearchControl: FormControl;
  isPackageEdit: boolean = true;
  idEntityPackageUpdate: boolean = false;
  entityPackageId: number;
  userIdForPackageUpdate: number;
  organizationIdForPackageUpdate: number;
  customNotificationPage:any;
  isManualBulkClient: boolean = false;
  isExcelBulkClient: boolean = true;
  excelFile: File;
  isLoading: boolean;
  progressStarted: boolean = false;
  progress: number = 0;
  organization_id = new FormControl(null, {
    validators: Validators.required
  });
  singleUser: UserList;
  bulkStatus: boolean = false;
  bulkStatusReport: boolean = false;
  bulkUploadStatusReport: boolean = false;
  bulkStatusRecord:any;
  bulkStatusValidationRecord:any;
  successfullyAdded: any;
  failedRecords: any;
  totalRecords: any;
  isLoadingListAPI: boolean;

  entityInputSearchControl = new FormControl();
  addEntityInputSearchControl = new FormControl();
  addBulkImportManually = new FormControl();
  entityInviteClientControl = new FormControl();
  addManualUserControl = new FormControl();

  packagesInputSearchControl = new FormControl();
  isLoadingOrganization = false;
  isLoadingPackages = false;
  isLoadingAPIFilterList = false;
  isEditClient: boolean = false;

  bulkImportControl = new FormControl();
  isLoadingBulkOrganization = false;
  lastBulkSearch = "";
  isUpdatingBulkList: boolean;
  hasScrolledForBulkEdit = false;

  /* whether to show input field for password as text, false means no */
  PASSWORD_VISIBILITY = {
    password: false,
    confirmPassword: false
  }

  readonly PASSWORD_PLACEHOLDER = PASSWORD_PLACEHOLDER;
  isLoadingPackagesList: boolean;
  isLoadingAPIList: boolean;
  passwordStrengthMessage: string = '';
  passwordStrengthClass: string = '';
  isPasswordValid: boolean = false;
  profilePage:any;
  constructor(
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private userService: UsersService,
    private locationService: LocationsService,
    private toast: ToastrService,
    private translate: TranslateService,
    private languageService: LanguageService,
    private globalService: GlobalService,
    private spinner: NgxSpinnerService,
    private packageService: PackageService
  ) { }

  ngOnInit() {
    this.pkgSearchControl = new FormControl('');

    this.pkgSearchControl.valueChanges.subscribe(value => {
      this.PkgSearch = value;
    });

    this.getCurrentLanguage();
    this.emailInputChange.pipe(
      debounceTime(400)
    ).subscribe((filterValue: string) => {
      this.filterEmail = filterValue;
      this.page = 1
      this.getClientsList('', this.type, this.filterEmail, this.filterOrg, this.filterPckg);
    });
    this.emailInputChangeAPI.pipe(
      debounceTime(400)
    ).subscribe((filterValue: string) => {
      this.filterEmailAPI = filterValue;
      this.pageWSO = 1
      this.getWSOUserList();
    });
    this.packagesInputSearchControl.valueChanges.pipe(
      debounceTime(DEBOUNCE_TIME_FOR_INPUT_IN_MS)
    ).subscribe((filterValue: string) => {
      this.filterSearch = filterValue;
      this.packageFilterPageNumber = 1
      this.getPackagesListByFilter()
    });
    this.pckgInputChange.subscribe((filterValue: string) => {
      this.PkgSearch = filterValue;
      this.packagePageNumber = 1
      this.getPackagesList();
    });
    this.searchInputChangeAPI.pipe(debounceTime(DEBOUNCE_TIME_FOR_INPUT_IN_MS))
    .subscribe((filterValue: string) => {
      this.filterSearchAPI = filterValue;
      this.pageAPI = 1
      this.getAPIList();
    });
    this.searchInputChangeAPIFilter.pipe(debounceTime(400))
    .subscribe((filterValue: string) => {
      this.filterSearchAPIFilter = filterValue;
      this.APIFilterPageNumber = 1
      this.getAPIListByFilter();
    });
    this.filterorganizationnameControl.pipe(debounceTime(1000))
    .subscribe((filterValue: string) => {
      this.manualClientForm?.controls['filterorganizationname'].setValue(filterValue);
      this.organizationfilter('Manual');
    });
    this.filterorganizationnameControlAuto.pipe(debounceTime(1000))
    .subscribe((filterValue: string) => {
      this.clientForm?.controls['filterorganizationname'].setValue(filterValue);
      this.organizationfilter('Auto');
    });
    this.filterorganizationnameControlBulk.pipe(debounceTime(1000))
    .subscribe((filterValue: string) => {
      this.bulkForm?.controls['filterorganizationname'].setValue(filterValue);
      this.organizationfilter('Bulk');
    });
    this.bulkImportControl.valueChanges.pipe(debounceTime(1000))
    .subscribe(() => {
      this.currentPageForBulkOrganizationUsers = 1;

      this.getOrganizationUsersForBulkDropdown(true, true);
    });
    merge(
      this.entityInputSearchControl.valueChanges,
      this.addBulkImportManually.valueChanges,
      this.entityInviteClientControl.valueChanges,
      this.addManualUserControl.valueChanges
    ).pipe(debounceTime(DEBOUNCE_TIME_FOR_INPUT_IN_MS))
    .subscribe((filterValue: string) => {
      if(this.organizationUsers.data){
        this.organizationUsers.data = [];
        this.searchFilterOrganizationName = '';

        if (!this.manualClientForm.value.client_type) {
          this.manualClientForm.reset();
          this.manualClientForm.enable();
        }

        if (!this.clientForm.value.client_type) {
          this.clientForm.reset()
          this.clientForm.enable();
        }

        if (!this.bulkForm.value.client_type) {
          this.bulkForm.reset();
          this.bulkForm.enable();
        }
      }
      if (this.currentPageForOrganizationUsers){
        this.currentPageForOrganizationUsers = 1;
      }

      if (filterValue === "" && this.searchFilterOrganizationName === "" && this.filterOrg) {
        this.page = 1;
        this.filterOrg = undefined;
        this.getClientsList('', this.type, this.filterEmail, this.filterOrg, this.filterPckg);
      }

      this.previousSearchFilterOrganizationName = filterValue;

      this.searchFilterOrganizationName = filterValue;
      this.organizationfilter('Search');
    });
    this.filterPckgControl.pipe(debounceTime(1000))
    .subscribe((filterValue: string) => {
      this.onPkgSearch(filterValue);
    });
    // this.orgInputChange.pipe(
    //   debounceTime(400)
    // ).subscribe((filterValue: string) => {
    //   this.filterOrg = filterValue;
    //   this.page = 1
    //   this.getClientsList(this.type, this.filterEmail, this.filterOrg, this.filterPckg);
    // });
    // this.pckgInputChange.pipe(
    //   debounceTime(400)
    // ).subscribe((filterValue: string) => {
    //   this.filterPckg = filterValue;
    //   this.page = 1
    //   this.getClientsList(this.type, this.filterEmail, this.filterOrg, this.filterPckg);
    // });
    this.getOrganizationUsersForDropdown();
    this.initializeForm();
    this.getLocations();
    this.getUserDetails();
    this.getPackagesList();
    this.getPackagesListByFilter();
    this.initializeBulkForm();
    this.initializeWSOForm();
    this.addcount();
    this.initializeManualForm();
    this.getAPIListByFilter()
    this.getAPIList()
    this.getWSOUserList()
    this.getClientsList();
    this.getOrganizationUsersForBulkDropdown();

    // setTimeout(() => {
    //   const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    //   if (isSafari) {
    //     // Array of table header selectors
    //     const selectors = [
    //       '.table-container table thead',
    //       '.p-datatable .p-datatable-thead',
    //       '.AddUserTable table thead',
    //       '.cssTable table thead',
    //       '.customTable thead',
    //       '.autoTable thead',
    //       '.maintable thead'
    //     ];

    //     // Loop through each selector and apply the background style if the element is found
    //     selectors.forEach(selector => {
    //       const thead = document.querySelector(selector);
    //       if (thead instanceof HTMLElement) {
    //         thead.style.background = '#0051AC';
    //       }
    //     });
    //   }
    // }, 500);
  }

  initializeForm() {
    const phonePattern = /^\+966\d{9}$/;
    this.clientForm = this.formBuilder.group({
      id: [null],
      name: ["", [Validators.required, Validators.pattern(/^[A-Za-z0-9\u0600-\u06FF\s]+$/)]],
      client_type: [null, Validators.required],
      company_name_en: [null, [Validators.required, Validators.pattern(/^[A-Za-z0-9\u0600-\u06FF\s]+$/)]],
      company_name_ar: [null, [Validators.required, Validators.pattern(/^[A-Za-z0-9\u0600-\u06FF\s]+$/)]],
      email: ["", [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[a-zA-Z]{2,}$/)]],
      phone: ['+966', [Validators.pattern(phonePattern)]],
      package_id: [null],
      organization_id: [null],
      role: [""],
      entity_status: ["", this.clientForm?.controls['client_type']?.value == 'organization' ? Validators.required : null],
      trialLimit: [null, this.clientForm?.controls['entity_status']?.value == 'trial' ? Validators.required : null],
      filterpackagename: [null],
      filterorganizationname: [null]
    });

    this.clientForm.controls['client_type'].valueChanges.subscribe((value) => {
      if (value == 'user') {
        // this.clientForm.controls['package_id'].clearValidators();
        this.clientForm.controls['organization_id'].addValidators([Validators.required]);
        this.clientForm.controls['company_name_en'].disable();
        this.clientForm.controls['company_name_ar'].disable();
        this.clientForm.controls['company_name_en'].clearValidators();
        this.clientForm.controls['company_name_ar'].clearValidators();

      }
      else {
        // this.clientForm.controls['package_id'].addValidators([Validators.required]);
        this.clientForm.controls['organization_id'].clearValidators();
        this.clientForm.controls['company_name_en'].enable();
        this.clientForm.controls['company_name_ar'].enable();
        this.clientForm.controls['company_name_en'].addValidators([Validators.required]);
        this.clientForm.controls['company_name_ar'].addValidators([Validators.required]);
      }
      // this.clientForm.controls['package_id'].updateValueAndValidity();
    });
  }

  initializeManualForm() {
    const phonePattern = /^\+966\d{9}$/;
    this.manualClientForm = this.formBuilder.group({
      id: [null],
      name: ["", [Validators.required, Validators.pattern(/^[A-Za-z0-9\u0600-\u06FF\s]+$/)]],
      client_type: [null, Validators.required],
      company_name_en: [null, [Validators.required, Validators.pattern(/^[A-Za-z0-9\u0600-\u06FF\s]+$/)]],
      company_name_ar: [null, [Validators.required, Validators.pattern(/^[A-Za-z0-9\u0600-\u06FF\s]+$/)]],
      email: ["", [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[a-zA-Z]{2,}$/)]],
      phone: ['+966', [Validators.pattern(phonePattern)]],
      package_id: [null],
      organization_id: [null],
      password: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          // Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/),
        ],
      ],
      confirmPassword: ["", Validators.required],
      role: [""],
      entity_status: ["", this.manualClientForm?.controls['client_type']?.value == 'organization' ? Validators.required : null],
      trialLimit: [null, this.manualClientForm?.controls['entity_status']?.value == 'trial' ? Validators.required : null],
      filterpackagename: [null],
      filterorganizationname: [null]
    });

    this.manualClientForm.controls['client_type'].valueChanges.subscribe((value) => {
      if (value == 'user') {
        // this.manualClientForm.controls['package_id'].clearValidators();
        this.manualClientForm.controls['organization_id'].addValidators([Validators.required]);
        this.manualClientForm.controls['company_name_en'].disable();
        this.manualClientForm.controls['company_name_ar'].disable();
        this.manualClientForm.controls['company_name_en'].clearValidators();
        this.manualClientForm.controls['company_name_ar'].clearValidators();

      }
      else {
        // this.manualClientForm.controls['package_id'].addValidators([Validators.required]);
        this.manualClientForm.controls['organization_id'].clearValidators();
        this.manualClientForm.controls['company_name_en'].enable();
        this.manualClientForm.controls['company_name_ar'].enable();
        this.manualClientForm.controls['company_name_en'].addValidators([Validators.required]);
        this.manualClientForm.controls['company_name_ar'].addValidators([Validators.required]);
      }
      // this.manualClientForm.controls['package_id'].updateValueAndValidity();
    });
  }

  initializeWSOForm() {
    this.WSOForm = this.formBuilder.group({
      id: [null],
      name: ["", [Validators.required, Validators.pattern(/^[A-Za-z0-9\u0600-\u06FF\s]+$/)]],
      email: ["", [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[a-zA-Z]{2,}$/)]],
      package_id: [null, Validators.required],
    });
  }

  initializeBulkForm() {
    this.bulkForm = this.formBuilder.group({
      id: [null],
      client_type: 'user',
      // company_name_en: [null,Validators.required],
      // company_name_ar: [null],
      // package_id: [null, Validators.required],
      organization_id: [null, Validators.required],
      count: this.formBuilder.array([]),
      filterorganizationname: [null]
    });
    // this.bulkForm.controls['client_type'].valueChanges.subscribe((value)=>{
    //   if(value == 'user'){
    //     this.bulkForm.controls['package_id'].clearValidators();
    //     this.bulkForm.controls['organization_id'].addValidators([Validators.required]);
    //     this.bulkForm.controls['company_name_en'].disable();
    //     this.bulkForm.controls['company_name_ar'].disable();
    //     this.bulkForm.controls['company_name_en'].clearValidators();
    //     this.bulkForm.controls['company_name_ar'].clearValidators();

    //   }
    //   else{
    //     this.bulkForm.controls['package_id'].addValidators([Validators.required]);
    //     this.bulkForm.controls['organization_id'].clearValidators();
    //     this.bulkForm.controls['company_name_en'].enable();
    //     this.bulkForm.controls['company_name_ar'].enable();
    //     this.bulkForm.controls['company_name_en'].addValidators([Validators.required]);
    //     this.bulkForm.controls['company_name_ar'].addValidators([Validators.required]);
    //   }
    //   this.bulkForm.controls['package_id'].updateValueAndValidity();
    // });
  }
  checkPasswordStrength() {
    const password = this.manualClientForm.controls['password'].value;

    // Reset strength messages.
    this.passwordStrengthMessage = '';
    this.passwordStrengthClass = '';

    if (!password) return;

    // Basic strength rules.
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*]/.test(password);
    const isLongEnough = password.length >= 8;
    const hasNoSequentialChars = !/(.)\1{2,}/.test(password); // No 3+ repeated characters.
    const hasNoSequentialNumbers = !/(012|123|234|345|456|567|678|789|12345|67890|112233|98765|000000|111111|222222|123456789|555555|123123|abcdef|ghijkl|asdfgh|zxcvbn|qwertyuiop|qazwsx|poiuyt|abc123|abcabc|1q2w3e4r|1qaz2wsx|qweasd|asdfghjkl|zxcvbnm|123qwe|123asd|789qwe|987poi|qazwsx|aaaaaa|bbbbbb|cccccc|333333|444444|666666|777777|888888|999999)/.test(password); // No sequential numbers.
    if(!hasUpperCase){
      this.passwordStrengthMessage = this.language === 'en' ? 'Weak' : 'ضعيف';
      this.passwordStrengthClass = 'text-danger';
      return;
    }
    if(!hasLowerCase){
      this.passwordStrengthMessage = this.language === 'en' ? 'Weak' : 'ضعيف';
      this.passwordStrengthClass = 'text-danger';
      return;
    }
    if(!hasNumber){
      this.passwordStrengthMessage = this.language === 'en' ? 'Weak' : 'ضعيف';
      this.passwordStrengthClass = 'text-danger';
      return;
    }
    if(!hasSpecialChar){
      this.passwordStrengthMessage = this.language === 'en' ? 'Weak' : 'ضعيف';
      this.passwordStrengthClass = 'text-danger';
      return;
    }
    if(!isLongEnough){
      this.passwordStrengthMessage = this.language === 'en' ? 'Weak' : 'ضعيف';
      this.passwordStrengthClass = 'text-danger';
      return;
    }
    if (!hasNoSequentialNumbers) {
      this.passwordStrengthMessage = this.language === 'en' ? 'Weak' : 'ضعيف';
      this.passwordStrengthClass = 'text-danger';
      return;
    }
    let strengthScore = 0;

    // Calculate password strength score.
    if (hasUpperCase) strengthScore++;
    if (hasLowerCase) strengthScore++;
    if (hasNumber) strengthScore++;
    if (hasSpecialChar) strengthScore++;
    if (isLongEnough) strengthScore++;
    if (hasNoSequentialChars) strengthScore++;
    if (hasNoSequentialNumbers) strengthScore++;

    // Display password strength.
    if (strengthScore <= 2) {
      this.passwordStrengthMessage = this.language === 'en' ? 'Very Weak' : 'ضعيف جداً';
      this.passwordStrengthClass = 'text-danger';
    } else if (strengthScore <= 4) {
      this.passwordStrengthMessage = this.language === 'en' ? 'Weak' : 'ضعيف';
      this.passwordStrengthClass = 'text-danger';
    } else if (strengthScore <= 5) {
      this.passwordStrengthMessage = this.language === 'en' ? 'Moderate' : 'معتدل';
      this.passwordStrengthClass = 'text-info';
    } else if (strengthScore === 6) {
      this.passwordStrengthMessage = this.language === 'en' ? 'Strong' : 'قوي';
      this.passwordStrengthClass = 'text-success';
    } else if (strengthScore === 7) {
      this.passwordStrengthMessage = this.language === 'en' ? 'Very Strong' : 'قوي جداً';
      this.passwordStrengthClass = 'text-success';
    }
    // debugger
  }

  getCurrentLanguage() {
    this.subscription.add(this.languageService.currentLanguage.subscribe((language: string) => {
      this.language = language;
      this.translate.use(language);
      this.translate.get("Clients").subscribe((res) => {
        this.clientsPage = res;
      });
      this.translate.get("Users").subscribe((res) => {
        this.usersPage = res;
      });
      this.translate.get("CustomNotifications").subscribe((res) => {
        this.customNotificationPage = res;
      });
      this.translate.get("Profile").subscribe((res) => {
        this.profilePage = res;
      });
    }));
  }
  onPhoneInput(event: any): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    // Ensure the value starts with +966 and only contains numbers after it
    if (!value.startsWith('+966')) {
      input.value = '+966';
    } else {
      input.value = value.replace(/[^0-9+]/g, '').slice(0, 13); // Limit to +966 followed by 9 digits
    }
  }

  getUserDetails() {
    this.userDetails = JSON.parse(localStorage.getItem('user'));
  }

  getLocations() {
    this.spinner.show();
    this.locationService.getLocations()
      .then(
        (res: { success: boolean, data: LocationList[] }) => {
          this.spinner.hide();
          this.locations = res.data;
        })
      .catch((err) => {
        this.spinner.hide();
        this.globalService.handleError(err);
      });
  }

  getClientsList(sortfilter?: string, clientType?: string, filterEmail?: string, filterOrg?: any, filterPckg?: any, page?: any) {
    this.isLoadingListAPI = true;
    this.users = new UserResponseModel();
    this.ncmClientModelAPI = new NCMClientModelAPI()

    if(sortfilter){
      this.sortarray = sortfilter
    }

    this.ncmClientModelAPI.page = this.page;
    if (clientType) {
      this.ncmClientModelAPI.clientType = clientType;
      // this.type = null;
    }
    if (!clientType) {
      this.ncmClientModelAPI.clientType = this.type;
    }
    if (filterEmail) {
      this.ncmClientModelAPI.search = filterEmail;
    }
    if (filterOrg) {
      this.ncmClientModelAPI.organization_id = filterOrg;
      // this.filterOrg = null;
    }
    if (!filterOrg) {
      this.ncmClientModelAPI.organization_id = this.filterOrg;
    }
    if (filterPckg) {
      this.ncmClientModelAPI.package_id = filterPckg;
      // this.filterPckg = null;
    }
    if (!filterPckg) {
      this.ncmClientModelAPI.package_id = this.filterPckg;
    }

    if (this.sortarray === 'sortByCreated') {
      if(sortfilter === 'sortByCreated'){
        this.sortByCreated = !this.sortByCreated
      }
      this.ncmClientModelAPI.sortByCreated = this.sortByCreated ? 'asc' : 'desc'
    } else if (this.sortarray === 'sortByStatus') {
      if(sortfilter === 'sortByStatus'){
        this.sortByStatus = !this.sortByStatus
      }
      this.ncmClientModelAPI.sortByStatus = this.sortByStatus ? 'asc' : 'desc'
    }

    this.userService.getClientsList(this.ncmClientModelAPI).then(
      (res: UserResponseModel) => {
        if (res.success) {
          this.users = res;
        }
        else {
          this.users = null;
          this.toast.error(res.message);
        }
        this.isLoadingListAPI = false;
      }
    ).catch((err) => {
      this.globalService.handleError(err);
      this.isLoadingListAPI = false;
    })
  }

  searchEntity(type: string, event: Event) {
    if(this.organizationUsers.data){
      this.organizationUsers.data = [];
      this.searchFilterOrganizationName = '';
      this.organization_id.reset();
      this.organization_id.enable();
      this.bulkForm.controls['filterorganizationname'].reset();
      this.bulkForm.controls['filterorganizationname'].enable();
      this.clientForm.controls['filterorganizationname'].reset();
      this.clientForm.controls['filterorganizationname'].enable();
      this.manualClientForm.controls['filterorganizationname'].reset();
      this.manualClientForm.controls['filterorganizationname'].enable();
    }
    if (this.currentPageForOrganizationUsers){
      this.currentPageForOrganizationUsers = 1;
    }
    setTimeout(() => {
      this.getOrganizationUsersForDropdown();
    }, 500);
    const input = event.target as HTMLInputElement;
    if(type === 'Manual'){
      this.filterorganizationnameControl.next(input.value);
    } else if (type === 'Auto'){
      this.filterorganizationnameControlAuto.next(input.value);
    } else if (type === 'Excel'){
      this.filterorganizationnameControlExcel.next(input.value);
    } else {
      this.filterorganizationnameControlBulk.next(input.value);
    }
  }

  searchPckg(event: Event) {
    const input = event.target as HTMLInputElement;
    this.filterPckgControl.next(input.value);
  }

  organizationfilter(type: string){
    this.getOrganizationUsersForDropdown(type, 1, true)
  }

  infiniteBulkOrganizationUsersForDropdown() {
    if (this.hasScrolledForBulkEdit) return;

    this.hasScrolledForBulkEdit = true;

    if (this.bulkOrganizationUsers?.paginationVariables?.pageCount > this.currentPageForBulkOrganizationUsers) {
      this.currentPageForBulkOrganizationUsers++;
      this.getOrganizationUsersForBulkDropdown()
    }
  }

  infiniteOrganizationUsersForDropdown(){
    if (this.organizationUsers?.paginationVariables?.pageCount > this.currentPageForOrganizationUsers) {
      this.currentPageForOrganizationUsers++;
      this.getOrganizationUsersForDropdown()
    }
  }
  onTypeOrganizationSearch(event: Event) {
    if(this.organizationUsers.data){
      this.organizationUsers.data = [];
      this.searchFilterOrganizationName = '';
      this.manualClientForm.reset();
      this.manualClientForm.enable();
      this.clientForm.reset()
      this.clientForm.enable();
      this.bulkForm.reset();
      this.bulkForm.enable();
    }
    if (this.currentPageForOrganizationUsers){
      this.currentPageForOrganizationUsers = 1;
    }
    setTimeout(() => {
      this.getOrganizationUsersForDropdown();
    }, 500);
    const input = event.target as HTMLInputElement;

    /* if (input.value === "" && this.searchFilterOrganizationName === "" && this.previousSearchFilterOrganizationName !== this.searchFilterOrganizationName) {
      this.page = 1;
      this.getClientsList('', this.type, this.filterEmail, this.filterOrg, this.filterPckg);
    } */

    // this.previousSearchFilterOrganizationName = input.value;
    this.searchFilterOrganizationNameInputChange.next(input.value);
  }

  getOrganizationUsersForBulkDropdown(searchEmpty: boolean = false, clearPrevious: boolean = false) {
    this.isLoadingBulkOrganization = true;
    const ncmClientModelAPI: NCMClientModelAPI = new NCMClientModelAPI();

    if (clearPrevious) {
      this.bulkOrganizationUsers.data = []
      this.currentPageForBulkOrganizationUsers = 1;
    }

    ncmClientModelAPI.page = this.currentPageForBulkOrganizationUsers;

    if(this.type){
      ncmClientModelAPI.clientType = this.type;
    }

    if (this.bulkImportControl.value){
      ncmClientModelAPI.search = this.bulkImportControl.value;
    }

    this.userService.listOrganization(ncmClientModelAPI).then(
      (res: listOrganizationResponseModel) => {
        if (res.success) {
          /* user scrolled */
          if (searchEmpty) {
            this.bulkOrganizationUsers['data'] = res.data;
          } else{
            if (this.bulkOrganizationUsers.data.length > 0) {
              this.bulkOrganizationUsers['data'] = [...this.bulkOrganizationUsers['data'], ...res.data];
            }
            else {
              this.bulkOrganizationUsers['data'] = res.data;
            }
          }

          this.bulkOrganizationUsers.paginationVariables = res.paginationVariables;
        }
        else {
          // this.users = null;
          this.toast.error(res.message);
        }
      }
    ).catch((err) => {
      this.globalService.handleError(err);
    })
      .finally(() => {
        this.isLoadingBulkOrganization = false;

        setTimeout(() => {
          this.isUpdatingBulkList = false;
        }, 1000);

        if (this.bulkImportControl.value) {
          this.lastBulkSearch = this.bulkImportControl.value;
        }

        this.hasScrolledForBulkEdit = false;
      })
  }

  getOrganizationUsersForDropdown(clienttype?: string, page?: any, searchEmpty: boolean = false) {
    this.isLoadingOrganization = true;
    const ncmClientModelAPI: NCMClientModelAPI = new NCMClientModelAPI();

    ncmClientModelAPI.page = this.currentPageForOrganizationUsers;

    if(this.type){
      ncmClientModelAPI.clientType = this.type;
    }

    if (this.manualClientForm?.controls['filterorganizationname']?.value){
      ncmClientModelAPI.search = this.manualClientForm?.controls['filterorganizationname']?.value;
    } else if (this.clientForm?.controls['filterorganizationname']?.value) {
      ncmClientModelAPI.search = this.clientForm?.controls['filterorganizationname']?.value;
    } else if (this.bulkForm?.controls['filterorganizationname']?.value) {
      ncmClientModelAPI.search = this.bulkForm?.controls['filterorganizationname']?.value;
    } else if (this.searchFilterOrganizationName) {
      ncmClientModelAPI.search = this.searchFilterOrganizationName;
    }

    this.userService.listOrganization(ncmClientModelAPI).then(
      (res: listOrganizationResponseModel) => {
        if (res.success) {
          if(this.manualClientForm?.controls['filterorganizationname']?.value || this.clientForm?.controls['filterorganizationname']?.value || this.bulkForm?.controls['filterorganizationname']?.value || this.searchFilterOrganizationName || this.organization_id.value){
            if (searchEmpty) {
              this.organizationUsers['data'] = res.data;
            } else{
              if (this.organizationUsers.data.length > 0) {
                this.organizationUsers['data'] = [...this.organizationUsers['data'], ...res.data];
              }
              else {
                this.organizationUsers['data'] = res.data;
              }
            }
          } else {
            if (this.organizationUsers.data.length > 0) {
              this.organizationUsers['data'] = [...this.organizationUsers['data'], ...res.data];
            }
            else {
              this.organizationUsers['data'] = res.data;
            }
          }
          this.organizationUsers.paginationVariables = res.paginationVariables;
        }
        else {
          // this.users = null;
          this.toast.error(res.message);
        }
      }
    ).catch((err) => {
      this.globalService.handleError(err);
    })
      .finally(() => {
        this.isLoadingOrganization = false;
      })
  }

  onPageChange(pageNumber: number) {
    this.page = pageNumber;
    this.getClientsList('', this.type, this.filterEmail, this.filterOrg, this.filterPckg);
  }

  onWSOPageChange(pageNumber: number) {
    this.pageWSO = pageNumber;
    this.getWSOUserList();
  }

  onTypeChange(type: any) {
    this.type = type;
    this.page = 1;
    this.getClientsList('', this.type, this.filterEmail, this.filterOrg, this.filterPckg);
  }

  onOrganizationChange(type: any) {
    this.filterOrg = type;
    this.page = 1;
    this.getClientsList('', this.type, this.filterEmail, this.filterOrg, this.filterPckg);
  }

  onPackageChange(type: any) {
    this.filterPckg = type;
    this.page = 1;
    this.getClientsList('', this.type, this.filterEmail, this.filterOrg, this.filterPckg);
  }

  onWSOPackageChange(type: any) {
    this.filterWSOusers = type;
    this.pageWSO = 1;
    this.getWSOUserList();
  }

  onTypeSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    this.searchInputChange.next(input.value);
  }

  onPkgSearch(event: string) {
    // const input = event.target as HTMLInputElement;
    this.pckgInputChange.next(event);
  }

  infinitePackagesList(isFilter:boolean = false){
    if(isFilter){
      if (this.listPackagesByFilter?.paginationVariables?.pageCount > (this.packageFilterPageNumber + 1)) {
        this.packageFilterPageNumber++;
        this.getPackagesListByFilter()
      }
    } else {
      if (this.listPackages?.paginationVariables?.pageCount > this.packagePageNumber) {
        this.packagePageNumber++;
        this.getPackagesList()
      }
    }
  }

  getPackagesList() {
    this.isLoadingPackagesList = true;

    if (this.listPackages && this.listPackages.data) {
      this.listPackages.data = [];
    }

    const getListPackage: GetListPackageModel = new GetListPackageModel();

    if (this.PkgSearch) {
      getListPackage.search = this.PkgSearch
    }
    getListPackage.page = this.packagePageNumber

    this.packageService.listPackages(getListPackage)
      .then((res: ListPackagesResponseModel) => {
        if (res.success) {
          if(this.packagePageNumber > 1){
            this.listPackages['data'] = [...this.listPackages['data'], ...res.data];
          } else {
            this.listPackages['data'] = res.data;
          }
          this.listPackages.paginationVariables = res.paginationVariables;
        } else {
          // this.users = null;
          this.toast.error(res.message)
        }
      })
      .catch((err) => {
        this.globalService.handleError(err);
      })
      .finally(() => {
        this.isLoadingPackagesList = false;
      });
  }

  getPackagesListByFilter() {
    this.listPackagesByFilter['data'] = [];
    this.isLoadingPackages = true;

    const getListPackage: GetListPackageModel = new GetListPackageModel();
    if (this.filterSearch) {
      getListPackage.search = this.filterSearch
    }
    getListPackage.page = this.packageFilterPageNumber;

    this.packageService.listPackagesByFilter(getListPackage)
      .then((res: ListPackagesResponseModel) => {
        if (res.success) {
          if(this.packageFilterPageNumber > 1){
            this.listPackagesByFilter['data'] = [...this.listPackagesByFilter['data'], ...res.data];
          } else {
            this.listPackagesByFilter['data'] = res.data;
          }
          this.listPackagesByFilter.paginationVariables = res.paginationVariables;
        } else {
          // this.users = null;
          this.toast.error(res.message)
        }
      })
      .catch((err) => {
        this.globalService.handleError(err);
      })
      .finally(() => {
        this.isLoadingPackages = false;
      });
  }

  onTypeSearchAPI(event: Event) {
    const input = event.target as HTMLInputElement;
    this.searchInputChangeAPI.next(input.value);
  }

  onTypeSearchAPIFilter(event: Event) {
    const input = event.target as HTMLInputElement;
    this.searchInputChangeAPIFilter.next(input.value);
  }

  infiniteAPIList(isFilter:boolean = false){
    if(isFilter){
      if (this.listWSOPackagesByFilter?.paginationVariables?.pageCount > (this.APIFilterPageNumber + 1)) {
        this.APIFilterPageNumber++;
        this.getAPIListByFilter()
      }
    } else {
      if (this.listWSOPackages?.paginationVariables?.pageCount > this.pageAPI) {
        this.pageAPI++;
        this.getAPIList()
      }
    }
  }

  getAPIList() {
    this.isLoadingAPIList = true;
    this.getListAPIModel = new getListWSOModel()

    if (this.listWSOPackages && this.listWSOPackages.data) {
      this.listWSOPackages.data = [];
    }

    if(this.filterSearchAPI){
      this.getListAPIModel.search = this.filterSearchAPI
    }

    this.getListAPIModel.page = this.pageAPI

    this.packageService.listWSOpackage(this.getListAPIModel)
    .then((res: {success: boolean, data: ListAPIData[], paginationVariables: PaginationVariables})=>{
      if(res.success){
        if(this.APIFilterPageNumber > 1){
          this.listWSOPackages['data'] = [...this.listWSOPackages['data'], ...res.data];
          this.listWSOPackages.paginationVariables = res.paginationVariables;
        } else {
          this.listWSOPackages = res;
        }
      }
    })
    .catch((err)=>{
      this.globalService.handleError(err);
    })
    .finally(()=>{
    this.isLoadingAPIList = false;
    })
  }

  getAPIListByFilter() {
    this.isLoadingAPIFilterList = true;

    if (this.listWSOPackagesByFilter && this.listPackagesByFilter.data) {
      this.listWSOPackagesByFilter.data = [];
    }

    this.getListAPIModel = new getListWSOModel()

    if(this.filterSearchAPIFilter){
      this.getListAPIModel.search = this.filterSearchAPIFilter
    }

    this.getListAPIModel.page = this.APIFilterPageNumber

    this.packageService.listWSOpackage(this.getListAPIModel)
    .then((res: {success: boolean, data: ListAPIData[], paginationVariables: PaginationVariables, message: string})=>{
      if(res.success){
        if(this.APIFilterPageNumber > 1){
          this.listWSOPackagesByFilter['data'] = [...this.listWSOPackagesByFilter['data'], ...res.data];
          this.listWSOPackagesByFilter.paginationVariables = res.paginationVariables;
        } else {
          this.listWSOPackagesByFilter = res;
        }
      }
      else{
        this.toast.error(res.message);
      }
    })
    .catch((err)=>{
      this.globalService.handleError(err);
    })
    .finally(()=>{
      this.isLoadingAPIFilterList = false;
    })
  }

  getWSOUserList() {
    this.isLoadingListAPI = true;
    this.getListWSOUser = new getListWSOModelUser()

    if (this.listWSOUsers) {
      this.listWSOUsers.data = [];
    }

    if(this.filterEmailAPI){
      this.getListWSOUser.search = this.filterEmailAPI
    }

    if(this.filterWSOusers){
      this.getListWSOUser.package_id = this.filterWSOusers
    }

    this.getListWSOUser.page = this.pageWSO

    this.packageService.listWSOSubscription(this.getListWSOUser)
    .then((res: {success: boolean, data: any[], paginationVariables: PaginationVariables, message: string})=>{
      if(res.success){
        this.listWSOUsers = res;
        // this.spinner.hide();
      }
      else{
        // this.spinner.hide();
        this.toast.error(res.message)
      }
      this.isLoadingListAPI = false;
    }).catch((err)=>{
      this.isLoadingListAPI = false;
      this.globalService.handleError(err);
    })
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  setEditUserForm = async (user: UserList, setPassword: boolean, isPackageEdit: boolean) => {
    this.resetPasswordVisibility();

    this.spinner.show();
    this.isEditClient = true;
    this.isPackageEdit = isPackageEdit;
    if(setPassword){
      this.openManuallyFormWindow(false);
      this.manualClientForm.controls['trialLimit'].setValue(null);
      this.manualClientForm.reset();
      this.manualClientForm.enable();
      setTimeout(() => {
        Object.entries(user).forEach(([key, value]) => {
          const control = key === 'trialLeftDays' ? this.manualClientForm.get('trialLimit') : this.manualClientForm.get(key);
          if (control) {
            if (typeof value !== 'object') {
              control.setValue(value)
              if (key === 'trialLeftDays') {
                console.log(`Setting trialLimit to: ${value}`);
                console.log(this.manualClientForm.get('trialLimit').value);
              }
            }
            else {
              if (key === 'role') {
                control.setValue(value?.key);
              }
              else if (key === 'client_type') {
                control.setValue(value?.key);
              }
            }
          }
        })
      }, 500);

      this.manualClientForm.controls['organization_id'].setValue(user?.organizationId);
      this.PkgSearch = user?.packageData[0]?.name
      this.pkgSearchControl.setValue(user?.packageData[0]?.name)
      this.listPackages['data'] = null;
      await this.getPackagesList()
      // this.manualClientForm.controls['package_id'].setValue(user?.packageData[0]?.id)
      // this.PkgSearch = ''
      // await this.getPackagesList()
      this.manualClientForm.controls['email'].disable();

      /* if(this.manualClientForm.controls['client_type'].value !== 'user'){
        this.manualClientForm.controls['role'].setValue('CLIENT_ADMIN');
        this.manualClientForm.controls['role'].disable();
      } else {
        this.manualClientForm.controls['role'].enable();
      } */

      this.manualClientForm.controls['password'].clearValidators();
      this.manualClientForm.controls['confirmPassword'].clearValidators();
      this.manualClientForm.controls['password'].setValue('●●●●●●●●');
      this.manualClientForm.controls['confirmPassword'].setValue('●●●●●●●●');

      if(this.manualClientForm.controls['client_type'].value === 'organization'){
        this.manualClientForm.controls['entity_status'].setValue(user?.entity_status?.toLowerCase());
      }
      // this.manualClientForm.controls['client_type'].disable();
      // this.manualClientForm.controls['company_name_en'].disable();
      // this.manualClientForm.controls['company_name_ar'].disable();
    } else {
      this.openFormWindow();
      Object.entries(user).forEach(([key, value]) => {
        const control = this.clientForm.get(key);
        if (control) {
          if (typeof value !== 'object') {
            control.setValue(value)
          }
          else {

            if (key === 'role') {
              control.setValue(value?.key);
            }
            else if (key === 'client_type') {
              control.setValue(value?.name_en?.toLowerCase());
            }
          }
        }
      })

      this.clientForm.controls['organization_id'].setValue(user?.organizationId);
      this.PkgSearch = user?.packageData[0]?.name
      this.pkgSearchControl.setValue(user?.packageData[0]?.name)
      this.listPackages['data'] = null;
      await this.getPackagesList()
      // this.clientForm.controls['package_id'].setValue(user?.packageData[0]?.id)
      // this.PkgSearch = ''
      // await this.getPackagesList()
      this.clientForm.controls['email'].disable();

      if(this.clientForm.controls['client_type'].value !== 'user'){
        this.clientForm.controls['role'].setValue('CLIENT_ADMIN');
        this.clientForm.controls['role'].disable();
      } else {
        this.clientForm.controls['role'].enable();
      }

      if(this.clientForm.controls['client_type'].value === 'organization'){
        this.clientForm.controls['entity_status'].setValue(user?.entity_status?.toLowerCase());
      }
      // this.clientForm.controls['client_type'].disable();
      // this.clientForm.controls['company_name_en'].disable();
      // this.clientForm.controls['company_name_ar'].disable();
    }

    while (this.listPackages === null) {
      await this.delay(100); // Wait for 100 milliseconds
    }

    this.spinner.hide();
  }

  async setWSOeditUserForm(user: WSOusersData){
    this.openTempWindow();
    this.WSOForm.controls['id'].setValue(user?.id);
    this.WSOForm.controls['name'].setValue(user?.user_name);
    this.WSOForm.controls['email'].setValue(user?.user_email);
    this.filterSearchAPI = user?.package_name;
    await this.getAPIList()
    this.WSOForm.controls['package_id'].setValue(user?.package_id);
    this.filterSearchAPI = ''
    await this.getAPIList()
    this.WSOForm.controls['email'].disable();
  }

  openTempWindow(){
    this.WSOForm.reset();
    this.WSOForm.enable();

    this.AddBulk = false;
    this.AddClient = false;
    this.AddManualUser = false;
    this.AddWSO = true;
  }

  openFormWindow() {
    if(this.organizationUsers.data){
      this.organizationUsers.data = [];
      this.searchFilterOrganizationName = '';
      this.manualClientForm.reset();
      this.manualClientForm.enable();
      this.clientForm.reset()
      this.clientForm.enable();
      this.bulkForm.reset();
      this.bulkForm.enable();
      this.organization_id.reset();
      this.organization_id.enable();
      this.organization_id.setValue(null);
    }
    if (this.currentPageForOrganizationUsers){
      this.currentPageForOrganizationUsers = 1;
    }
    setTimeout(() => {
      this.getOrganizationUsersForDropdown();
    }, 500);

    this.clientForm.reset()
    this.clientForm.enable();

    this.AddClient = true;
    this.AddBulk = false;
    this.AddManualUser = false;
    this.AddWSO = false;
  }

  openBulkFormWindow() {
    // return;
    if(this.organizationUsers.data){
      this.organizationUsers.data = [];
      this.searchFilterOrganizationName = '';
      this.manualClientForm.reset();
      this.manualClientForm.enable();
      this.clientForm.reset()
      this.clientForm.enable();
      this.bulkForm.reset();
      this.bulkForm.enable();
      this.organization_id.reset();
      this.organization_id.enable();
      this.organization_id.setValue(null);
    }
    if (this.currentPageForOrganizationUsers){
      this.currentPageForOrganizationUsers = 1;
    }
    setTimeout(() => {
      this.getOrganizationUsersForDropdown();
    }, 500);
    this.bulkForm.reset();
    this.bulkForm.enable();

    this.AddBulk = true;
    this.AddClient = false;
    this.AddManualUser = false;
    this.AddWSO = false;
  }

  closeBulkFormWindow() {
    this.isEditClient = false;
    this.bulkForm.reset();
    this.bulkForm.enable();
    this.count().controls.length = 1;
    this.AddBulk = false;
  }
  closeExcelFormWindow(){
    this.AddBulk = false;
    this.organization_id.setValue(null);
    this.isLoading = false;
    this.progressStarted = false;
    this.progress = 0;
    this.excelFile = null;
    this.closeBulkFormWindow();
  }

  closeFormWindow() {
    this.isEditClient = false;
    this.isEditUser = false;
    this.clientForm.reset()
    this.clientForm.enable();
    this.AddClient = false;
  }

  closeWSOFormWindow() {
    this.isEditClient = false;
    this.isEditUser = false;
    this.WSOForm.reset()
    this.WSOForm.enable();
    this.AddWSO = false;
  }

  openManuallyFormWindow(erasedata: boolean = true) {
    this.resetPasswordVisibility();
    if(this.organizationUsers.data){
      this.organizationUsers.data = [];
      this.searchFilterOrganizationName = '';
      this.manualClientForm.reset();
      this.manualClientForm.enable();
      this.clientForm.reset()
      this.clientForm.enable();
      this.bulkForm.reset();
      this.bulkForm.enable();
      this.organization_id.reset();
      this.organization_id.enable();
      this.organization_id.setValue(null);
    }
    if (this.currentPageForOrganizationUsers){
      this.currentPageForOrganizationUsers = 1;
    }
    setTimeout(() => {
      this.getOrganizationUsersForDropdown();
    }, 500);

    this.manualClientForm.reset()
    this.manualClientForm.enable();

    this.AddClient = false;
    this.AddBulk = false;
    this.AddManualUser = true;
    this.AddWSO = false;

    setTimeout(() => {
      if(erasedata){
        this.manualClientForm.reset()
      }
    }, 1000);
    if(!this.isEditClient){
      this.manualClientForm.get('entity_status').valueChanges.subscribe(status => {
        if (status === 'trial') {
          this.manualClientForm.get('trialLimit').setValue(this.DaysArray[9]);
        }
      });
    }
  }

  closeManuallyFormWindow() {
    this.isEditClient = false;
    // this.isEditUser = false;
    this.manualClientForm.reset()
    this.manualClientForm.enable();
    this.AddManualUser = false;
  }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0]
  }

  onLocationCheck(checked: boolean) {
    if (!checked) {
      this.clientForm.controls?.['location'].setValue(null);
      this.clientForm.controls?.['location'].enable();
    }
    else {
      this.clientForm.controls?.['location'].setValue(null);
      this.clientForm.controls?.['location'].disable();
    }
  }

  resendInviteUser(id: number) {
    this.spinner.show();

    if(this.isWSOinvite){
      const resendInviteWSOuserModel: ResendInviteWSOuserModel = new ResendInviteWSOuserModel()
      resendInviteWSOuserModel.user_id = id

      this.userService.resendInviteWSOUser(resendInviteWSOuserModel).then(
        (res: any) => {
          this.spinner.hide();
          if (res.success) {
            this.toast.success(res.message)
            this.getWSOUserList();
          }
          else {
            this.toast.error(res.message)
          }
        })
        .catch((err) => {
          this.spinner.hide();
          this.globalService.handleError(err);
        })
    } else {
      const resendInviteUserModel: ResendInviteUserModel = new ResendInviteUserModel()
      resendInviteUserModel.id = id

      this.userService.resendInviteUser(resendInviteUserModel).then(
        (res: any) => {
          this.spinner.hide();
          if (res.success) {
            this.toast.success(res.message)
            this.getClientsList();
          }
          else {
            this.toast.error(res.message)
          }
        })
        .catch((err) => {
          this.spinner.hide();
          this.globalService.handleError(err);
        })
    }
  }

  OnSave() {
    if (this.clientForm.invalid) {
      if (!this.clientForm.controls['client_type'].value) {
        this.toast.error(this.clientsPage?.clientTypeRequired);
        return;
      }

      const isFormValid = this.checkAndShowRequiredMessages();

      if (!isFormValid) {
        return;
      }

      if (this.clientForm.controls['email']?.value && this.clientForm.controls['email']?.errors?.['pattern']) {
        this.toast.error(this.clientsPage?.emailFormatIncorrect);
        return;
      }
      if (this.clientForm.controls['phone']?.value.length > 12) {
        this.toast.error(this.language == 'en' ? 'Phone number can only be 12 digits' : 'رقم الهاتف يمكن أن يكون 12 رقمًا فقط');
        return;
      }

      if (this.clientForm.controls['name']?.value && this.clientForm.controls['name']?.errors?.['pattern']) {
        this.toast.error(this.clientsPage?.nameContainsNumberSpecialChars);
        return;
      }
      return;
    }

    const clientModel: ClientModel = this.clientForm.getRawValue();
    const filteredClientModel: ClientModel = Object.fromEntries(
      Object.entries(clientModel).filter(([key, value]) => value !== null)
    ) as ClientModel;

    filteredClientModel.location = [];
    // filteredClientModel.role = 'CLIENT_ADMIN';

    if (this.clientForm.controls?.['id']?.value && this.clientForm.controls?.['organization_id']?.value) {
      filteredClientModel.id = this.clientForm.controls?.['id'].value;
      filteredClientModel.org_id = this.clientForm.controls?.['organization_id'].value;
      this.editClient(filteredClientModel);
    }
    else {
      this.createClient(filteredClientModel);
    }
  }

  checkAndShowRequiredMessages(): boolean {
    const clientType = this.clientForm.controls['client_type'].value;
    const requiredFieldsMessage = clientType === 'user'
      ? this.clientsPage
      : this.clientsPage;

    for (const [controlName, value] of Object.entries(this.clientForm.controls)) {
      const formControl = this.clientForm.get(controlName);

      if (formControl?.errors?.required) {
        this.toast.error(requiredFieldsMessage[controlName]);
        return false;
      }
    }
    return true;
  }

  createClient(clientModel: ClientModel) {
    this.spinner.show();
    this.clientService.createClient(clientModel)
      .then((res: { success: boolean; message: string }) => {
        this.spinner.hide();
        if (res.success) {
          this.toast.success(res.message);
          this.closeBulkFormWindow();
          this.closeManuallyFormWindow();
          this.closeFormWindow();
          this.closeWSOFormWindow();
          this.getClientsList();
        } else {
          this.toast.error(res.message);
        }
      }).catch((err) => {
        this.spinner.hide();
        this.globalService.handleError(err);
      });
  }

  editClient(clientModel: ClientModel) {
    this.clientService.editClient(clientModel)
      .then((res: { success: boolean; message: string }) => {
        this.spinner.hide();
        if (res.success) {
          this.toast.success(res.message);
          this.closeBulkFormWindow();
          this.closeManuallyFormWindow();
          this.closeFormWindow();
          this.closeWSOFormWindow();
          this.getClientsList();
        } else {
          this.toast.error(res.message);
        }
      }).catch((err) => {
        this.spinner.hide();
        this.globalService.handleError(err);
      });
  }

  editWSOClient(WSOuserModel: CreateWSOUser) {
    this.userService.editWSOuser(WSOuserModel)
      .then((res: { success: boolean; message: string }) => {
        this.spinner.hide();
        if (res.success) {
          this.toast.success(res.message);
          this.closeBulkFormWindow();
          this.closeManuallyFormWindow();
          this.closeFormWindow();
          this.closeWSOFormWindow();
          this.getWSOUserList();
        } else {
          this.toast.error(res.message);
        }
      }).catch((err) => {
        this.spinner.hide();
        this.globalService.handleError(err);
      });
  }

  deleteUser(id: number) {
    // this.spinner.show();
    this.isLoadingListAPI = true;

    if(this.isWSOinvite){
      this.userService.deleteWSOUser(id).then((res: { success: boolean; message: string }) => {
        if (res?.success) {
          this.toast.success(res?.message);
          this.getWSOUserList();
          this.closeBulkFormWindow();
          this.closeManuallyFormWindow();
          this.closeFormWindow();
          this.closeWSOFormWindow();
        }
        else {
          this.isLoadingListAPI = false;
          this.toast.error(res?.message);
        }
      }).catch((err) => {
        this.isLoadingListAPI = false;
        this.globalService.handleError(err);
      })
    } else {
      this.userService.deleteUser(id).then((res: { success: boolean; message: string }) => {
        if (res?.success) {
          this.toast.success(res?.message);
          this.getClientsList();
          this.closeBulkFormWindow();
          this.closeManuallyFormWindow();
          this.closeFormWindow();
          this.closeWSOFormWindow();
        }
        else {
          this.isLoadingListAPI = false;
          this.toast.error(res?.message);
        }
      }).catch((err) => {
        this.isLoadingListAPI = false;
        this.globalService.handleError(err);
      })
    }
  }

  show(id: number, isWSO: boolean) {
    this.isDivVisible = true;
    this.isWSOinvite = isWSO;
    this.deleteLocationData = id;
  }

  cancel() {
    this.isDivVisible = false;
  }

  showresend(id: number, isWSO: boolean) {
    this.isResendInvite = true;
    this.isWSOinvite = isWSO;
    this.resendInviteId = id;
  }

  cancelresend() {
    this.isResendInvite = false;
  }

  showsuspended(id: number) {
    this.isSuspended = true;
    this.suspendedId = id;
  }

  cancelsuspended() {
    this.isSuspended = false;
  }

  showresume(id: number) {
    this.isResume = true;
    this.resumeId = id;
  }

  cancelresume() {
    this.isResume = false;
  }

  onTypeEmail(event: Event) {
    const input = event.target as HTMLInputElement;
    this.emailInputChange.next(input.value);
  }

  onTypeEmailAPI(event: Event) {
    const input = event.target as HTMLInputElement;
    this.emailInputChangeAPI.next(input.value);
  }

  onTypeOrg(event: Event) {
    const input = event.target as HTMLInputElement;
    this.orgInputChange.next(input.value);
  }

  onTypePckg(event: Event) {
    const input = event.target as HTMLInputElement;
    this.pckgInputChange.next(input.value);
  }

  onSuspendPackage(id: number) {
    this.spinner.show();
    const suspendPackageModel: SuspendPackageModel = new SuspendPackageModel();
    suspendPackageModel.id = id;
    this.packageService.suspendPackage(suspendPackageModel).then((res: { success: boolean; message: string }) => {
      this.spinner.hide();
      if (res.success) {
        this.toast.success(res.message);
        this.getClientsList();
      } else {
        this.toast.error(res.message);
      }
    }).catch((err) => {
      this.spinner.hide();
      this.globalService.handleError(err);
    });
  }

  onResumePackage(id: number) {
    this.spinner.show();
    const resumePackageModel: ResumePackageModel = new ResumePackageModel();
    resumePackageModel.id = id;
    this.packageService.resumePackage(resumePackageModel).then((res: { success: boolean; message: string }) => {
      this.spinner.hide();
      if (res.success) {
        this.toast.success(res.message);
        this.getClientsList();
      } else {
        this.toast.error(res.message);
      }
    }).catch((err) => {
      this.spinner.hide();
      this.globalService.handleError(err);
    });
  }

  onRenewPackage(id: number, month: number) {
    this.spinner.show();
    const renewPackageModel: RenewPackageModel = new RenewPackageModel();
    renewPackageModel.id = id;
    renewPackageModel.days = month;
    this.packageService.renewPackage(renewPackageModel).then((res: { success: boolean; message: string }) => {
      this.spinner.hide();
      if (res.success) {
        this.toast.success(res.message);
        this.getClientsList();
      } else {
        this.toast.error(res.message);
      }
    }).catch((err) => {
      this.spinner.hide();
      this.globalService.handleError(err);
    });
  }

  showRenewModal(id: number) {
    this.isRenewVisible = true;
    this.renewPackageId = id;
  }

  closeRenewModal() {
    this.isRenewVisible = false;
  }

  showUpdateEntityModal(user: UserList) {
    this.updateEntity = true;
    this.updateEntityId = user?.organizationId;
    this.updateEntityStatus = user?.entity_status;
    this.trialLimit = user?.entity_status == 'trial' ? user?.trialLeftDays : null;
  }

  onUpdateEntity(id: number, entity_status: string, trialLimit?: number) {
    this.spinner.show();
    this.packageService.updateEntityStatus(id, entity_status, entity_status == 'trial' ? trialLimit : undefined)
    .then((res: { success: boolean; message: string }) => {
      this.spinner.hide();
      if (res.success) {
        this.toast.success(res.message);
        this.getClientsList();
      } else {
        this.toast.error(res.message);
      }
    }).catch((err) => {
      this.globalService.handleError(err);
    }).finally(() => {
      this.closeUpdateEntityModal();
      this.updateEntityId = null;
      this.updateEntityStatus = null;
      this.trialLimit = null;
      this.spinner.hide();
    });
  }

  closeUpdateEntityModal(){
    this.updateEntity = false;
  }

  count(): FormArray {
    return this.bulkForm.get("count") as FormArray;
  }

  newCount(): FormGroup {
    return this.formBuilder.group({
      name: ["", [Validators.required, Validators.pattern(/^[A-Za-z0-9\u0600-\u06FF\s]+$/)]],
      email: ["", [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[a-zA-Z]{2,}$/)]],
      role: [null, Validators.required]
    });
  }

  addcount() {
    if (this.count().controls.length > 9) {
      return;
    }
    this.count().push(this.newCount());
  }

  removeCount(i: number) {
    this.count().removeAt(i);
  }

  onSubmit() {
    if (this.bulkForm.invalid) {
      if (!this.bulkForm.controls['organization_id'].value) {
        this.toast.error(this.clientsPage?.organization_id);
        return;
      }
      for (let i = 0; i < this.bulkForm.controls['count']?.['controls'].length; i++) {

        if (!this.bulkForm.controls['count']?.['controls'][i]?.controls?.['name']?.value) {
          this.toast.error(this.clientsPage?.nameRequired);
          return;
        }
        if (!this.bulkForm.controls['count']?.['controls'][i]?.controls?.['email']?.value) {
          this.toast.error(this.clientsPage?.emailRequired);
          return;
        }
        if (!this.bulkForm.controls['count']?.['controls'][i]?.controls?.['role']?.value) {
          this.toast.error(this.clientsPage?.permissionTypeRequired);
          return;
        }
        if (this.bulkForm.controls['count']?.['controls'][i]?.controls?.['name'] && this.bulkForm.controls['count']?.['controls'][i]?.controls?.['name']?.errors?.['pattern']) {
          this.toast.error(this.clientsPage?.nameContainsNumberSpecialChars);
          return;
        }
        if (this.bulkForm.controls['count']?.['controls'][i]?.controls?.['email'] && this.bulkForm.controls['count']?.['controls'][i]?.controls?.['email']?.errors?.['pattern']) {
          this.toast.error(this.clientsPage?.emailFormatIncorrect);
          return;
        }
      }
      return;
    }

    const clientModel = this.bulkForm.getRawValue();
    const filteredClientModel = Object.fromEntries(
      Object.entries(clientModel).filter(([key, value]) => value !== null)
    );

    filteredClientModel.location = [];
    this.createBulkClient(filteredClientModel);
  }

  createBulkClient(filteredClientModel) {
    let clientData;
    const clientsData = [];

    filteredClientModel.count.forEach(item => {
      clientData = {
        client_type: 'user',
        organization_id: filteredClientModel.organization_id,
        name: item.name,
        email: item.email,
        role: item.role,
        location: filteredClientModel.location
      };

      clientsData.push(clientData);
    });

    let completedRequests = 0;

    this.spinner.show();

    // Create an observable from clientsData using concatMap
    from(clientsData).pipe(
      concatMap(user => this.clientService.createBulkClient(user).pipe(
        catchError(() => EMPTY), // Catch any errors during the request
        delay(1000) // Add a delay between requests if needed
      ))
    ).subscribe((res: any) => {
      completedRequests++;

      if (res.success) {
        this.toast.success(res.message);
      } else {
        this.toast.error(res.message);
      }

      if (completedRequests === clientsData.length) {
        this.spinner.hide();
        this.closeBulkFormWindow();
        this.closeManuallyFormWindow();
        this.closeFormWindow();
        this.closeWSOFormWindow();
        // this.toast.success('All invitations sent successfully');
        this.getClientsList();
      }
    });
  }

  editBulkClient(clientModel: ClientModel) {
    this.clientService.editClient(clientModel)
      .then((res: { success: boolean; message: string }) => {
        this.spinner.hide();
        if (res.success) {
          this.toast.success(res.message);
          this.closeBulkFormWindow();
          this.closeManuallyFormWindow();
          this.closeFormWindow();
          this.closeWSOFormWindow();
          this.getClientsList();
        } else {
          this.toast.error(res.message);
        }
      }).catch((err) => {
        this.spinner.hide();
        this.globalService.handleError(err);
      });
  }

  checkAndShowRequiredMessagesForBulk(): boolean {
    const clientType = this.bulkForm.controls['client_type'].value;
    const requiredFieldsMessage = clientType === 'user'
      ? this.clientsPage?.requiredFieldsMessageUser
      : this.clientsPage?.requiredFieldsMessageOther;
    for (const [controlName, value] of Object.entries(this.bulkForm.controls)) {
      const formControl = this.bulkForm.get(controlName);
      if (formControl?.errors?.required) {
        this.toast.error(requiredFieldsMessage[controlName]);
        return false;
      }
    }
    return true;
  }

  onCreateManualUser() {

    if (this.manualClientForm.invalid) {
      if (!this.manualClientForm.controls['client_type'].value) {
        this.toast.error(this.clientsPage?.clientTypeRequired);
        return;
      }

      const isFormValid = this.checkAndShowRequiredMessagesForManual();

      if (!isFormValid) {
        return;
      }

      if (this.manualClientForm.controls['email']?.value && this.manualClientForm.controls['email']?.errors?.['pattern']) {
        this.toast.error(this.clientsPage?.emailFormatIncorrect);
        return;
      }
      if (this.manualClientForm.controls['phone']?.value.length > 12) {
        this.toast.error(this.language == 'en' ? 'Phone number can only be 12 digits' : 'يجب أن يكون رقم الهاتف 12 رقمًا فقط');
        return;
      }

      if (this.manualClientForm.controls['name']?.value && this.manualClientForm.controls['name']?.errors?.['pattern']) {
        this.toast.error(this.clientsPage?.nameContainsNumberSpecialChars);
        return;
      }
      return;
    }
    if (this.passwordStrengthClass === 'text-danger') {
      this.toast.error(this.language === 'en' ? 'Password strength is too weak!' : 'كلمة المرور ضعيفة جداً!');
      return;
    }
    if(this.manualClientForm.controls['password']?.value !== null || this.manualClientForm.controls['password']?.value !== ''){
      if (this.manualClientForm.controls['password'].value !== this.manualClientForm.controls['confirmPassword'].value) {
        this.toast.error(this.clientsPage?.PasswordandConfirmPasswordmustbesame);
        return;
      }
    }
    if(this.manualClientForm.controls['password']?.value === '●●●●●●●●'){
      this.manualClientForm.controls['password'].clearValidators();
      this.manualClientForm.controls['confirmPassword'].clearValidators();
      this.manualClientForm.controls['password'].setValue(null);
      this.manualClientForm.controls['confirmPassword'].setValue(null);
    }

    const clientModel: ClientModel = this.manualClientForm.getRawValue();
    const filteredClientModel: ClientModel = Object.fromEntries(
      Object.entries(clientModel).filter(([key, value]) => value !== null)
    ) as ClientModel;

    filteredClientModel.location = [];
    // filteredClientModel.role = 'CLIENT_ADMIN';
    this.spinner.show();
    if (this.manualClientForm.controls?.['id']?.value && this.manualClientForm.controls?.['organization_id']?.value) {
      filteredClientModel.id = this.manualClientForm.controls?.['id'].value;
      filteredClientModel.org_id = this.manualClientForm.controls?.['organization_id'].value;
      this.editClient(filteredClientModel);
    }
    else {
      this.clientService.createManualClient(filteredClientModel)
        .then((res: { success: boolean; message: string }) => {
          this.spinner.hide();
          if (res.success) {
            this.toast.success(res.message);
            this.closeBulkFormWindow();
            this.closeManuallyFormWindow();
            this.closeFormWindow();
            this.closeWSOFormWindow();
            this.getClientsList();
          } else {
            this.toast.error(res.message);
          }
        }).catch((err) => {
          this.spinner.hide();
          this.globalService.handleError(err);
        });
    }
  }

  checkAndShowRequiredMessagesForManual(): boolean {
    const clientType = this.manualClientForm.controls['client_type'].value;
    const requiredFieldsMessage = clientType === 'user' ? this.clientsPage : this.clientsPage;
    for (const [controlName, value] of Object.entries(this.manualClientForm.controls)) {
      const formControl = this.manualClientForm.get(controlName);
      if (formControl?.errors?.required) {
        this.toast.error(requiredFieldsMessage[controlName]);
        return false;
      }
    }
    return true;
  }

  onSubmitWSOUser() {
    if (this.WSOForm.invalid) {
      const isFormValid = this.checkAndShowRequiredMessagesForWSO();

      if (!isFormValid) {
        return;
      }

      if (this.WSOForm.controls['email']?.value && this.WSOForm.controls['email']?.errors?.['pattern']) {
        this.toast.error(this.clientsPage?.emailFormatIncorrect);
        return;
      }

      if (this.WSOForm.controls['name']?.value && this.WSOForm.controls['name']?.errors?.['pattern']) {
        this.toast.error(this.clientsPage?.nameContainsNumberSpecialChars);
        return;
      }
      return;
    }

    const filteredClientModel: CreateWSOUser = new CreateWSOUser();
    filteredClientModel.name = this.WSOForm.controls?.['name'].value;
    filteredClientModel.email = this.WSOForm.controls?.['email'].value;
    filteredClientModel.package_id = this.WSOForm.controls?.['package_id'].value;

    this.spinner.show();
    if (this.WSOForm.controls?.['id']?.value) {
      filteredClientModel.subscription_id = this.WSOForm.controls?.['id'].value;
      this.editWSOClient(filteredClientModel);
    }
    else {
      this.packageService.CreateWSOUser(filteredClientModel)
        .then((res: { success: boolean; message: string }) => {
          this.spinner.hide();
          if (res.success) {
            this.toast.success(res.message);
            this.closeBulkFormWindow();
            this.closeManuallyFormWindow();
            this.closeFormWindow();
            this.closeWSOFormWindow();
            this.getWSOUserList();
          } else {
            this.toast.error(res.message);
          }
        }).catch((err) => {
          this.spinner.hide();
          this.globalService.handleError(err);
        });
    }
  }

  checkAndShowRequiredMessagesForWSO(): boolean {
    const requiredFieldsMessage = this.clientsPage;
    for (const [controlName, value] of Object.entries(this.WSOForm.controls)) {
      const formControl = this.WSOForm.get(controlName);
      if (formControl?.errors?.required) {
        this.toast.error(requiredFieldsMessage[controlName]);
        return false;
      }
    }
    return true;
  }

  // onclear(){
  //   this.getOrganizationUsersForDropdown();
  // }
  // pageReset(){
  //   const clienttype = [];
  //   const page = 1;
  //   this.organizationUsers.data = [];
  //   this.getOrganizationUsersForDropdown(clienttype,page);
  // }
  // onclearPackage(){
  //   this.getPackagesList();
  // }
  // pageResetPackage(){
  //   this.packagePageNumber = 1;
  //   this.listPackages.data = [];
  // }
  // onclearManual(){
  //   this.getOrganizationUsersForDropdown();
  // }
  // pageResetManual(){
  //   this.currentPageForOrganizationUsers = 1;
  //   this.organizationUsers.data = [];
  // }
  // onclearPackageManual(){
  //   this.getPackagesList();
  // }
  // pageResetPackageManual(){
  //   this.packagePageNumber = 1;
  //   this.listPackages.data = [];
  // }
  // onclearBulk(){
  //   this.getOrganizationUsersForDropdown();
  // }
  // pageResetBulk(){
  //   this.currentPageForOrganizationUsers = 1;
  //   this.organizationUsers.data = [];
  // }

  roleSelect(formName: string, value: string) {
    if(value !== 'user'){
      this[formName].controls['role'].setValue('CLIENT_ADMIN');
      this[formName].controls['role'].disable();
      this[formName].controls['organization_id'].clearValidators();
      this[formName].controls['organization_id'].updateValueAndValidity();
    } else {
      this[formName].controls['role'].setValue(null);
      this[formName].controls['role'].enable();
      this[formName].controls['organization_id'].addValidators([Validators.required]);
      this[formName].controls['organization_id'].updateValueAndValidity();
    }
  }

  generateExcel(){
    this.spinner.show()
    const userExcelModelAPI: UserExcelModelAPI = new UserExcelModelAPI()
    userExcelModelAPI.type = 'client'
    this.userService.getUserExcel(userExcelModelAPI)
    .then((res:{data:{id:number, path:string}, success:boolean, message: string})=>{
      if(res?.success){
        const a = document.createElement('a');
        a.href = res?.data?.path;
        a.target = '_blank';
        a.click();
        this.toast.success(res?.message)
      } else {
        this.toast.success(res?.message)
      }
    })
    .catch((err) => {
      this.spinner.hide()
      this.globalService.handleError(err)
    })
    .finally(()=>{
      this.spinner.hide()
    })
  }
  showUpdatePackageModal(user: any){
    this.idEntityPackageUpdate = true;
    this.userIdForPackageUpdate = user.id;
    this.organizationIdForPackageUpdate = user.organizationId;
  }
  updatePackageForEntity(userId: number, organizationId: number){
    this.spinner.show();
    const updateAdminPackageModel: UpdateAdminPackageModel = new UpdateAdminPackageModel();
    updateAdminPackageModel.user_id = userId;
    updateAdminPackageModel.organization_id = organizationId;
    updateAdminPackageModel.package_id = this.entityPackageId;
    updateAdminPackageModel.Authorization = localStorage.getItem('token');
    this.clientService.updateAdminPackage(updateAdminPackageModel).then((res: { success: boolean; message: string }) => {
      this.spinner.hide();
      if (res.success) {
        this.toast.success(res.message);
        this.getClientsList();
      } else {
        this.toast.error(res.message);
      }
    }).catch((err) => {
      this.spinner.hide();
      this.globalService.handleError(err);
    });
  }
  closeUpdatePackageModal() {
    this.idEntityPackageUpdate = false;
  }

  onNavChange(nav:string) {
    this.activeNav = nav;
  }
  onExcelBulkClick(event: any){
    if(event.target.checked){
      this.isExcelBulkClient = true;
      this.isManualBulkClient = false;
    }
    else{
      this.isExcelBulkClient = false;
    }
  }
  onManualBulkClick(event: any){
    if(event.target.checked){
      this.isManualBulkClient = true;
      this.isExcelBulkClient = false;
    }
    else{
      this.isManualBulkClient = false;
    }
  }
  calculateProgress() {
    return `${this.progress}%`
  }
  stopProgress() {
    this.progressStarted = false;
    this.isLoading = false;
  }
  readFile(file: File) {
    // if (file.size === 0) {
    //   if(this.language == 'en'){
    //     this.toast.error('The selected file is empty. Please upload a valid file.');
    //   }
    //   else{
    //     this.toast.error('الملف المحدد فارغ. يرجى تحميل ملف صالح.');
    //   }
    //   this.isLoading = false;
    //   return;
    // }
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const fileContent = fileReader.result as ArrayBuffer;
      // if (fileContent.byteLength === 0) {
      //   // Show an error toaster if the file content is empty
      //   if(this.language == 'en'){
      //   this.toast.error('The selected file is empty. Please upload a valid file.');
      //   }
      //   else{
      //     this.toast.error('الملف المحدد فارغ. يرجى تحميل ملف صالح.');
      //   }
      //   this.isLoading = false;
      //   return;
      // }
      this.parseExcel(fileContent);
    }
    fileReader.readAsArrayBuffer(file);
    // this.isLoading = false;
  }
//   async parseExcel(data: ArrayBuffer): Promise<void> {
//     this.progressStarted = true;

//     const workbook = XLSX.read(data, { type: 'array' });
//     const firstSheetName = workbook.SheetNames[0];
//     const worksheet = workbook.Sheets[firstSheetName];

//     const clientData: CreateClientExcelDataModel[] = [];
//     const invalidData: { name: string; email: string; role: string; status: boolean; error_message: string }[] = [];
//     const excelData: string[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1, range: 4 })
//         .filter(row => Object.values(row).some(cell => cell !== '')) as string[][];
//     if (!excelData || excelData.length === 0) {
//       if (this.language == 'en') {
//           this.toast.error('The uploaded Excel file is empty or contains no valid data.');
//       } else {
//           this.toast.error('الملف المحمل فارغ أو لا يحتوي على بيانات صالحة.');
//       }
//       this.stopProgress();
//       return;
//     }

//     if (excelData?.length > 200) {
//         this.toast.error(this.clientsPage?.ClientLimitExceeded);
//         return;
//     }

//     const totalItems = excelData?.length;
//     let processedItems = 0;

//     const allowedRoles = ['user', 'admin']; // Define valid roles
//     const invalidRows: string[] = []; // Collect invalid rows

//     for (const [index, data] of excelData.entries()) {
//         const name = data[0]?.trim();
//         const email = data[1]?.trim();
//         let role = data[2]?.trim()?.toLowerCase();

//         // Validate missing fields
//         if (!name || !email || !role) {
//           if(this.language == 'en'){
//             invalidRows.push(`Row ${index + 1}: Missing fields`);
//           }
//           else{
//             invalidRows.push(`الصف ${index + 1}: الحقول مفقودة`);
//           }
//           continue;
//         }

//         // Validate email format
//         // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//         const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[a-zA-Z]{2,}$/;
//         if (!emailRegex.test(email)) {
//           if(this.language == 'en'){
//             invalidRows.push(`Row ${index + 1}: Invalid email format (${email})`);
//           }
//           else{
//             invalidRows.push(`الصف ${index + 1}: تنسيق البريد الإلكتروني غير صالح (${email})`);
//           }
//           continue;
//         }

//         // Validate role spelling
//         if (!allowedRoles.includes(role)) {
//             if(this.language == 'en'){
//               invalidRows.push(`Row ${index + 1}: Invalid role (${role})`);
//             }
//             else{
//               invalidRows.push(`الصف ${index + 1}: الدور غير صالح (${role})`);
//             }
//             continue;
//         }

//         // Normalize roles
//         role = role === 'user' ? 'CLIENT_USER' : 'CLIENT_ADMIN';

//         clientData.push({ name, email, role });
//         processedItems++;
//         this.progress = (processedItems / totalItems) * 100;
//     }

//     // Show error messages for invalid rows
//     if (invalidRows.length > 0) {
//       this.bulkUploadStatusReport = true;
//       console.log('hello',invalidRows);
//       console.log('hello hello',clientData);
//       // this.bulkStatusValidationRecord = invalidRows
//       // if(this.language == 'en'){
//       //   this.toast.error(`Errors found:\n${invalidRows.join('\n')}`);
//       //   this.isLoading = false;
//       // }
//       // else{
//       //   this.toast.error(`تم العثور على أخطاء:\n${invalidRows.join('\n')}`);
//       //   this.isLoading = false;
//       // }
//     }

//     // Proceed with valid data
//     if(invalidRows.length === 0){
//       if (clientData?.length > 0) {
//         this.createBulkClientsExcel(clientData);
//       }
//     }

//     this.progressStarted = false;
// }
async parseExcel(data: ArrayBuffer): Promise<void> {
  this.progressStarted = true;

  const workbook = XLSX.read(data, { type: 'array' });
  const firstSheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[firstSheetName];

  const clientData: CreateClientExcelDataModel[] = [];
  const invalidData: { name: string; email: string; role: string; status: boolean; error_message: string }[] = [];
  const excelData: string[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1, range: 4 })
      .filter(row => Object.values(row).some(cell => cell !== '')) as string[][];

  if (!excelData || excelData.length === 0) {
      if (this.language == 'en') {
          this.toast.error('The uploaded Excel file is empty or contains no valid data.');
      } else {
          this.toast.error('الملف المحمل فارغ أو لا يحتوي على بيانات صالحة.');
      }
      this.stopProgress();
      return;
  }

  if (excelData?.length > 200) {
      this.toast.error(this.clientsPage?.ClientLimitExceeded);
      return;
  }

  const totalItems = excelData?.length;
  let processedItems = 0;

  const allowedRoles = ['user', 'admin']; // Define valid roles

  for (const [index, data] of excelData.entries()) {
      const name = data[0]?.trim() || '';
      const email = data[1]?.trim() || '';
      let role = data[2]?.trim()?.toLowerCase() || '';

      // Validate missing fields
      if (!name || !email || !role) {
          invalidData.push({
              name,
              email,
              role,
              status: false,
              error_message: this.language == 'en'
                  ? `Row ${index + 1}: Missing required fields`
                  : `الصف ${index + 1}: الحقول مفقودة`,
          });
          continue;
      }

      // Validate email format
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[a-zA-Z]{2,}$/;
      if (!emailRegex.test(email)) {
          invalidData.push({
              name,
              email,
              role,
              status: false,
              error_message: this.language == 'en'
                  ? `Row ${index + 1}: Invalid email format (${email})`
                  : `الصف ${index + 1}: تنسيق البريد الإلكتروني غير صالح (${email})`,
          });
          continue;
      }

      // Validate role spelling
      if (!allowedRoles.includes(role)) {
          invalidData.push({
              name,
              email,
              role,
              status: false,
              error_message: this.language == 'en'
                  ? `Row ${index + 1}: Invalid role (${role})`
                  : `الصف ${index + 1}: الدور غير صالح (${role})`,
          });
          continue;
      }

      // Normalize roles
      role = role === 'user' ? 'CLIENT_USER' : 'CLIENT_ADMIN';

      clientData.push({ name, email, role });
      processedItems++;
      this.progress = (processedItems / totalItems) * 100;
  }

  // Log or handle invalid data
  if (invalidData.length > 0) {
      this.bulkUploadStatusReport = true;
      this.bulkStatusValidationRecord = invalidData;
      console.log('Invalid Data:', invalidData);
  }

  // Proceed with valid data
  if (invalidData.length === 0 && clientData?.length > 0) {
      this.createBulkClientsExcel(clientData);
  }

  this.progressStarted = false;
}


  handleFile(event: any, fileInput: HTMLInputElement) {
    // this.excelFile = null;
    // const file = event.target.files[0];
    // this.excelFile = file;
    // Get the selected file
    const file = event.target.files[0];

    // If a file is selected, set it to `this.excelFile`
    if (file) {
      this.excelFile = file;
    }

    // Reset the file input value to allow re-uploading the same file
    fileInput.value = '';
  }

  onExcelSubmit() {
    if (this.isLoading) {
      return;
    }
    if (this.excelFile.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      this.toast.error(this.clientsPage?.uploadExcelFile);
      return;
    }
    this.isLoading = true;
    this.readFile(this.excelFile);
  }
  createBulkClientsExcel(clientData: CreateClientExcelDataModel[]){
    this.spinner.show();
    const clientExcelData: CreateClientExcelModel = new CreateClientExcelModel();
    clientExcelData.organization_id = this.organization_id.value;
    clientExcelData.data = clientData;
    clientExcelData.Authorization = localStorage.getItem('token');
    clientExcelData.lang = this.language;
    this.clientService.createBulkClientExcel(clientExcelData)
    .then((res: { success: boolean; message: string; results: any[]; }) => {
      if (res.success) {
        this.toast.success(res.message);
        this.bulkStatus = true;
        this.bulkStatusRecord = res?.results;
        console.log(this.bulkStatusRecord);
        this.successfullyAdded = this.bulkStatusRecord.filter(record => record.success === true).length;
        this.failedRecords = this.bulkStatusRecord.filter(record => record.success === false).length;
        this.totalRecords = this.bulkStatusRecord.length;
        this.closeBulkFormWindow();
        this.closeManuallyFormWindow();
        this.closeFormWindow();
        this.closeWSOFormWindow();
        this.getClientsList();
        this.isLoading = false;
        this.progressStarted = false;
        this.progress = 0;
        this.excelFile = null;
      } else {
        this.toast.error(res.message);
        this.stopProgress();
      }
    }).catch((err) => {
      this.globalService.handleError(err);
      this.stopProgress();
    }).finally(() => {
      this.spinner.hide();
    });
  }
  onViewDetail(data: UserList){
    this.singleUser = data;
  }
  closeBulkExcelStatus(){
    this.bulkStatus = false;
    this.bulkStatusRecord = [];
  }
  closeBulkExcelStatusModal(){
    this.bulkStatus = false;
    this.bulkStatusReport = true;
    // this.bulkStatusRecord = [];
  }
  closeBulkStatusReport(){
    this.bulkStatusReport = false;
    this.bulkStatusRecord = [];
  }
  closeBulkStatusValidationReport(){
    this.bulkUploadStatusReport = false;
    this.bulkStatusValidationRecord = [];
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  resetPasswordVisibility() {
    this.PASSWORD_VISIBILITY = {
      password: false,
      confirmPassword: false
    }
  }
}
